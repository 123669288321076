import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Components */
import SubSubCategory from '../../SubSubCategory';
import Constant from './Constant';

/* Store */

/* Styles */
import './ConstantList.css';

class ConstantList extends React.PureComponent {
  state = {
    activeConstant: -1,
  };

  componentDidUpdate() {
    if (this.props.isOpen === false) {
      this.setState({ activeConstant: -1 });
    }
  }

  render() {
    const { activeConstant } = this.state;
    const { i18n } = this.props;
    return (
      <div className="constant_list_wrapper">
        {this.props.constants.map((constant, i) => (
          <SubSubCategory
            theme={this.props.theme}
            name={i18n.t('constant:' + constant.name)}
            key={i}
            isOpen={activeConstant === i}
            searchString={this.props.filterString}
            onClick={() => {
              this.setState({
                activeConstant: i === activeConstant ? -1 : i,
              });
            }}
          >
            <Constant constantData={constant} key={i} />
          </SubSubCategory>
        ))}
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return { theme: settings.editorTheme };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ConstantList));
