/* Actions */
const TOGGLE_DOCK_CONTENT = 'TOGGLE_DOCK_CONTENT';
const TOGGLE_MENU = 'TOGGLE_MENU';

/* Reducer */
const initialState = {
  showMenu: false,
  showTerminal: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_DOCK_CONTENT:
      return { ...state, showTerminal: !state.showTerminal };
    case TOGGLE_MENU:
      return { ...state, showMenu: action.payload };
    default:
      return state;
  }
}

/* Action Creators */
export function toggleDockContent() {
  return {
    type: TOGGLE_DOCK_CONTENT,
  };
}

export function toggleMenu(show) {
  return {
    type: TOGGLE_MENU,
    payload: show,
  };
}
