import React from 'react';

/* Styles */
import './PopupContainer.css';

function PopupContainer() {
  /* eslint-disable jsx-a11y/heading-has-content */
  return (
    <>
      <div className="popup_background" />
      <div id="template_popup_wrapper" className="popup_wrapper">
        <header>
          <h1 className="popup_title" />
          <div className="popup_mascot_negative" />
          <div className="popup_mascot_neutral" />
          <div className="popup_mascot_positive" />
        </header>
        <div className="popup_content" style={{ fontSize: '1.4rem' }} />
        <footer />
      </div>
    </>
  );
}

export default PopupContainer;
