import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Components */
import TopBar from '../TopBar';
import InfoBar from '../InfoBar';
import Editor from '../Editor';
import Terminal from '../Terminal';
import Menu from '../Menu';

/* Store */

/* Styles */
import './AppContainer.css';

class AppContainer extends React.Component {
  render() {
    return (
      <div className={`app ${this.props.theme}`}>
        <div className="main_area">
          <TopBar />
          <InfoBar />
          <Editor show={true} />
          <Terminal />
        </div>

        <div className="menu_area">
          <Menu />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return {
    theme: settings.editorTheme,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AppContainer));
