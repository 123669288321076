import React from 'react';

/* Components */

/* Store */

/* Styles */
import './Button.css';

const types = ['success', 'neutral', 'warning', 'error'];
const defaultType = 'neutral';

function Button({ onClick, text, type, disabled, className, title }) {
  if (!types.includes(type)) {
    type = defaultType;
  }

  return (
    <button
      aria-label={`${title} button`}
      className={`owm-btn owm-btn-${type} ${className}`}
      onClick={onClick}
      disabled={disabled}
      title={title}
    >
      {text}
    </button>
  );
}

Button.defaultProps = {
  type: defaultType,
  disabled: false,
  className: '',
};

export default Button;
