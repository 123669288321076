/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* ---------- Category Utility ---------- */
  category_utility: 'Général',

  /* --- size --- */
  size_description: "Retourne la taille d'une liste",
  size_param_array: 'Liste à partir de laquelle la taille doit être déterminée.',

  /* --- remove --- */
  remove_description: 'Supprime un élément de la liste à la position définie',
  remove_param_array: 'Liste dont un élément doit être retiré.',
  remove_param_pos: "Position de l'élément à supprimer.",

  /* --- findValue --- */
  find_value_description:
    'Recherche un élément dans la liste et renvoie sa position si elle est trouvée. Sinon, -1',
  find_value_param_array: 'Liste dans laquelle vous pouvez effectuer des recherches.',
  find_value_param_value: 'Valeur à rechercher dans la liste.',

  /* --- findRange --- */
  find_range_description:
    'Recherche une zone dans la liste et renvoie sa position de départ si elle est trouvée. Sinon, -1',
  find_range_param_array: 'Liste à envoyer dans laquelle on peut effectuer des recherches.',
  find_range_param_search_range: 'Domaine (liste) à rechercher dans la liste',

  /* --- min --- */
  min_description: "Retourne la plus petite valeur d'une liste",
  min_param_array: 'Liste dans laquelle la plus petite valeur doit être déterminée.',

  /* --- max --- */
  max_description: "Retourne la plus grande valeur d'une liste.",
  max_param_array: 'Liste dans laquelle la valeur la plus élevée doit être déterminée.',

  /* --- avg --- */
  avg_description: "Retourne la valeur moyenne d'une liste",
  avg_param_array: 'Liste dans laquelle la valeur moyenne doit être déterminée.',

  /* --- sin --- */
  sin_description:
    "Retourne le sinus d'un angle défini multiplié par un facteur (de sorte qu'aucune décimale n'est nécessaire).",
  sin_param_degree: 'Angle en degrés',
  sin_param_factor: 'Facteur du résultat',

  /* --- cos --- */
  cos_description:
    "Renvoie le cosinus d'un angle défini multiplié par un facteur (de sorte qu'aucun nombre décimal n'est nécessaire).",
  cos_param_degree: 'Angle en degrés',
  cos_param_factor: 'Facteur du résultat',

  /* --- pow --- */
  pow_description: "Retourne la valeur de puissance de la base et de l'exposant définis.",
  pow_param_base: 'Base avec laquelle la valeur de la puissance doit être calculée.',
  pow_param_exponent: 'Exposant avec lequel la valeur de la puissance doit être calculée.',

  /* --- map --- */
  map_description: "Assigne un numéro d'une série à une autre série",
  map_param_value: 'La valeur à muter',
  map_param_a1: 'Domaine 1 début',
  map_param_a2: 'Domaine 1 fin',
  map_param_b1: 'Domaine 2 début',
  map_param_b2: 'Domaine 2 fin',

  /* --- abs --- */
  abs_description: 'Retourne la valeur absolue (valeur non signée) de la valeur définie',
  abs_param_value: 'Valeur à partir de laquelle la valeur absolue doit être déterminée',

  /* ---------- Category Logic ---------- */
  category_logic: 'Logique',

  /* --- isButtonPressed --- */
  is_button_pressed_description:
    "Retourne vrai lorsque le bouton défini est pressé. Sinon, c'est faux.\nLes boutons possibles sont:\n\tBTN_L1\n\tBTN_L2\n\tBTN_L3\n\tBTN_R1\n\tBTN_R2\n\tBTN_R3",
  is_button_pressed_param_button: 'Bouton à vérifier',

  /* --- waitForButtonPressed --- */
  wait_for_button_pressed_description:
    'Attendez que le bouton défini soit enfoncé.\nLes boutons possibles sont :\n\tBTN_L1\n\tBTN_L2\n\tBTN_L3\n\tBTN_R1\n\tBTN_R2\n\tBTN_R3',
  wait_for_button_pressed_param_button: 'Bouton à vérifier',

  /* ---------- Category Variables ---------- */
  category_variables: 'Variables',

  /* --- random --- */
  random_description: 'Retourne une valeur aléatoire entre la valeur de début et de fin définie',
  random_param_start: 'Valeur de départ',
  random_param_end: 'Valeur finale',

  /* --- hasPersistentVariable --- */
  has_pers_var_description:
    'Retourne vrai si une valeur avec la clé définie a été stockée dans la mémoire non-volatile',
  has_pers_var_param_key: 'Clé de recherche',

  /* --- savePersistentVariable --- */
  save_pers_var_description: 'Stocke une valeur en mémoire non volatile sous la clé définie',
  save_pers_var_param_key: 'Clé pour le stockage de la valeur',
  save_pers_var_param_value: 'La valeur à sauvegarder',

  /* --- loadPersistentVariable --- */
  load_pers_var_description:
    'Retourne la valeur stockée dans la mémoire non volatile sous la clé définie.',
  load_pers_var_param_key: 'Clé de lecture de la valeur',

  /* --- deletePersistentVariable --- */
  delete_pers_var_description: 'Efface une valeur avec la clé définie de la mémoire non volatile.',
  delete_pers_var_param_key: 'Clé pour supprimer la valeur',

  /* ---------- Category Oxocard ---------- */
  category_oxocard: 'Oxocard',

  /* --- reboot --- */
  reboot_description: 'Oxocard redémarrer',

  /* --- turnOff --- */
  turn_off_description: 'Oxocard éteindre',

  /* --- turnOffWakeUpButton --- */
  turn_off_wake_up_button_description:
    "Éteint l'Oxocard et active les boutons définis comme motif valable de réveil.",
  turn_off_wake_up_button_param_l1: 'Vrai si le bouton L1 est un motif valable de réveil',
  turn_off_wake_up_button_param_l2: 'Vrai si le bouton L2 est un motif valable de réveil',
  turn_off_wake_up_button_param_l3: 'Vrai si le bouton L3 est un motif valable de réveil',
  turn_off_wake_up_button_param_r1: 'Vrai si le bouton R1 est un motif valable de réveil',
  turn_off_wake_up_button_param_r2: 'Vrai si le bouton R2 est un motif valable de réveil',
  turn_off_wake_up_button_param_r3: 'Vrai si le bouton R3 est un motif valable de réveil',

  /* --- configureAutoTurnOff --- */
  configure_auto_turn_off_description:
    "Définit le temps en secondes après lequel l'Oxocard doit s'éteindre automatiquement si aucune entrée n'est effectuée par l'utilisateur.",
  configure_auto_turn_off_param_seconds: 'Eteindre après tant de secondes',

  /* --- enableAutoTurnOff --- */
  enable_auto_turn_off_description:
    "Active la fonction d'arrêt automatique (l'heure standard est de 120s)",

  /* --- disableAutoTurnOff --- */
  disable_auto_turn_off_description: "Désactive la fonction d'arrêt automatique",

  /* --- sleep --- */
  sleep_description: "Met l'Oxocard en mode veille pour la durée définie en secondes",
  sleep_param_seconds: 'Nombre de secondes pour dormir',

  /* --- getWakeUpCause --- */
  get_wake_up_cause_description:
    'Retourne le motif de réveil\n\tBTN_L1\n\tBTN_L2\n\tBTN_L3\n\tBTN_R1\n\tBTN_R2\n\tBTN_R3\n\tRESET\n\tTIMER',

  /* --- getBatteryVoltage --- */
  get_battery_voltage_description: 'Retourne la tension de la batterie en millivolts',

  /* --- isFullyCharged --- */
  is_fully_charged_description: 'Retourne vrai lorsque la batterie est complètement chargée',

  /* --- isUsbPluggedIn --- */
  is_usb_plugged_in_description: 'Retourne vrai lorsque le câble USB est branché',

  /* --- getTemperature --- */
  get_temperature_description: 'Retourne la température en degrés Celsius',

  /* ---------- Category Matrix ---------- */
  category_matrix: 'Matrice',

  /* --- setColor --- */
  set_color_description: 'Définit la couleur pour toutes les fonctions de dessin suivantes',
  set_color_param_r: 'Rouge 0 - 255',
  set_color_param_g: 'Vert 0 - 255',
  set_color_param_b: 'Bleu 0 - 255',

  /* --- setRandomColor --- */
  set_random_color_description:
    'Définit une couleur aléatoire pour toutes les fonctions de dessin suivantes',

  /* --- setHueColor --- */
  set_hue_color_description: 'Définit la couleur pour toutes les fonctions de dessin suivantes',
  set_hue_color_param_hue: 'Hue 0 - 360°',

  /* --- enableAutoUpdate --- */
  enable_auto_update_description:
    "Active la fonction de mise à jour automatique. Toutes les fonctions de dessin dessinent leur résultat directement sur l'écran (Matrice)",

  /* --- disableAutoUpdate --- */
  disable_auto_update_description:
    "Désactive la fonction de mise à jour automatique. Seul l'appel de la fonction update() dessine sur l'écran.",

  /* --- isAutoUpdate --- */
  is_auto_update_description:
    'Retourne vrai si la fonction de mise à jour automatique est activée. Sinon, faux',

  /* --- update --- */
  update_description: "Mise à jour de l'affichage",

  /* --- fillScreen --- */
  fill_screen_description: "Remplit tout l'écran avec la couleur actuellement sélectionnée",

  /* --- clearScreen --- */
  clear_screen_description: "Efface tout l'affichage",

  /* --- setPixel --- */
  set_pixel_description:
    "Définit un pixel dans la mémoire d'image sans le sortir à l'écran (même si la fonction de mise à jour automatique est activée). Seul l'appel à la fonction update() l'attire ensuite vers l'affichage",
  set_pixel_param_x: 'X Position du pixel [0 - 7]',
  set_pixel_param_y: 'Y Position du pixel [0 - 7]',
  set_pixel_pixel_param_r: 'Rouge [0 - 255]',
  set_pixel_pixel_param_g: 'Vert [0 - 255]',
  set_pixel_pixel_param_b: 'Bleu [0 - 255]',

  /* --- drawPixel --- */
  draw_pixel_description:
    "Dessine un pixel avec la couleur actuellement réglée et la position définie sur l'écran",
  pixel_param_x: 'X Position du pixel [0 - 7]',
  pixel_param_y: 'Y Position du pixel [0 - 7]',

  /* --- clearPixel --- */
  clear_pixel_description: 'Supprime un pixel à la position définie',

  /* --- readPixel --- */
  read_pixel_description: "Retourne la couleur d'un pixel à la position définie",
  read_pixel_param_color: 'Couleur à lire (RED, GREEN ou BLUE)',

  /* --- isPixelSet --- */
  is_pixel_set_description: 'Retourne vrai si un pixel est placé à la position définie.',

  /* --- fill --- */
  fill_description: 'Remplit toutes les fonctions de dessin géométrique suivantes',

  /* --- noFill --- */
  no_fill_description: 'Ne remplit pas toutes les fonctions de dessin géométrique suivantes',

  /* --- drawLine --- */
  draw_line_description: 'Trace une ligne de x1:y1 à x2:y2',
  draw_line_param_x1: 'X Position des coordonnées de départ',
  draw_line_param_y1: 'Y Position des coordonnées de départ',
  draw_line_param_x2: 'X Position des coordonnées finales',
  draw_line_param_y2: 'Y Position des coordonnées finales',

  /* --- drawRectangle --- */
  draw_rectangle_description:
    'Dessine un rectangle de largeur et de hauteur définies avec le coin supérieur gauche à la position x:y',
  draw_rectangle_param_x: 'X Position du coin supérieur gauche',
  draw_rectangle_param_y: 'Y Position du coin supérieur gauche',
  draw_rectangle_param_w: 'Largeur du rectangle',
  draw_rectangle_param_h: 'Hauteur du rectangle',

  /* --- drawTriangle --- */
  draw_triangle_description: 'Dessine un triangle aux points x1:y1, x2:y2 et x3:y3',
  draw_triangle_param_x1: 'X Position du premier point',
  draw_triangle_param_y1: 'X Position du premier point',
  draw_triangle_param_x2: 'X Position du deuxième point',
  draw_triangle_param_y2: 'X Position du deuxième point',
  draw_triangle_param_x3: 'X Position du troisième point',
  draw_triangle_param_y3: 'X Position du troisième point',

  /* --- drawCircle --- */
  draw_circle_description: 'Dessine un cercle avec le centre défini x:y et le rayon',
  draw_circle_param_x: 'X Position du centre',
  draw_circle_param_y: 'Y Position du centre',
  draw_circle_param_r: 'Rayon du cercle',

  /* --- drawNumber --- */
  draw_number_description: "Dessine un numéro sur l'écran",
  draw_number_param_num: 'Numéro à tirer',

  /* --- drawChar --- */
  draw_char_description: "Dessine un seul caractère à la position définie sur l'écran",
  draw_char_param_c: 'Le caractère à dessiner',
  draw_char_param_x: 'X Position du coin supérieur gauche',
  draw_char_param_y: 'Y Position du coin supérieur gauche',
  draw_char_param_is_big_font: "Si c'est vrai, la grande fonte est utilisée. Sinon, la petite.",

  /* --- drawText --- */
  draw_text_description: "Dessine un texte défilant sur l'écran",
  draw_text_param_text: 'Buffer (String) avec le texte à dessiner.',
  draw_text_param_is_big_font: "Si c'est vrai, la grande fonte est utilisée. Sinon, la petite.",

  /* --- drawImage --- */
  draw_image_description:
    "Dessine une image avec la couleur actuellement réglée sur l'écran. L'image est une liste de 8 bytes, où chaque bit correspond à une LED (pixel)",
  draw_image_param_image: 'Image à dessiner',
  draw_image_param_x: "Y Position du coin supérieur gauche de l'image",
  draw_image_param_y: "Y Position du coin supérieur gauche de l'image",

  /* --- drawImage --- */
  draw_image32_description:
    "Dessine une image sur l'écran. L'image est une liste de valeurs 32 x 16 bits, où chaque valeur correspond à deux LED (pixels)",
  draw_image32_param_image: 'Image à dessiner',
  draw_image32_param_x: "Y Position du coin supérieur gauche de l'image",
  draw_image32_param_y: "Y Position du coin supérieur gauche de l'image",

  /* --- setActiveFrameBuffer --- */
  set_active_frame_buffer_description: "Définit la mémoire d'image actuellement active (1, 2 ou 3)",
  set_active_frame_buffer_param_num: "Numéro de mémoire d'image",

  /* --- copyFrameBufferContent --- */
  copy_frame_buffer_content_description: "Copie le contenu d'une mémoire d'image vers une autre",
  copy_frame_buffer_content_param_from_buffer: "Numéro de mémoire d'image à copier",
  copy_frame_buffer_content_param_to_buffer: "Numéro de mémoire d'image à copier",

  /* ---------- Category Time ---------- */
  category_time: 'Temps',

  /* --- delay --- */
  delay_description: 'Attend le temps défini en millisecondes',
  delay_param_ms: "Des millisecondes d'attente",

  /* --- msSinceStart --- */
  ms_since_start_description: 'Retourne le nombre de millisecondes depuis le début du programme',

  /* --- useTimeZone --- */
  use_time_zone_description: 'Fixe le fuseau horaire',
  use_time_zone_param_id: 'ID du fuseau horaire',

  /* --- setTime --- */
  set_time_description: "Fixe l'heure de l'Oxocard",
  set_time_param_h: 'Heures',
  set_time_param_m: 'Minutes',
  set_time_param_s: 'Secondes',

  /* --- getTime --- */
  get_time_description:
    "Retourne la valeur de l'unité de temps définie :\n\tSECONDS\n\tMINUTES\n\tHOURS",
  get_time_param_unit: 'Unité de temps (SECONDES, MINUTES or HEURES)',

  /* --- setDate --- */
  set_date_description: "Fixe la date de l'Oxocard",
  set_date_param_d: 'Journée',
  set_date_param_m: 'Moins',
  set_date_param_y: 'Année',

  /* --- getDate --- */
  get_date_description:
    "Retourne la valeur de l'unité de temps définie:\n\tWEEKDAY\n\tDAY\n\tMONTH\n\tYEAR",
  get_date_param_unit: 'Unité de temps (WEEKDAY, DAY, MONTH ou YEAR)',

  /* ---------- Category Weather ---------- */
  category_weather: 'Météo',

  /* --- getWeather --- */
  get_weather_description: "Récupère les données météorologiques du lieu défini sur l'Internet",
  get_weather_param_place_id: 'ID de la ville',

  /* --- readWeatherParam --- */
  read_weather_param_description:
    'Retourne la valeur du paramètre météorologique défini:\n\tTEMPERATURE\n\tDEW_POINT\n\tHUMIDITY\n\tWIND\n\tPRECIPITATION',
  read_weather_param_param_param_id:
    'Paramètres météorologiques (TEMPERATURE, DEW_POINT, HUMIDITY, WIND ou PRECIPITATION)',

  /* --- drawCityName --- */
  draw_city_name_description: "Dessine le nom de lieu défini sur l'écran",
  draw_city_name_param_is_big_font:
    "Si c'est vrai, la grande fonte est utilisée. Sinon, la petite.",

  /* --- drawForecastIcon --- */
  draw_forecast_icon_description: "Dessine le symbole de la prévision sur l'écran",

  /* ---------- Category Accelerometer ---------- */
  category_accelerometer: 'Accéléromètre',

  /* --- getAcceleration --- */
  get_acceleration_description: "Retourne la valeur d'accélération de l'axe défini",
  get_acceleration_param_axis: 'AXIS_X, AXIS_Y oder AXIS_Z',

  /* --- getNormalizedValue --- */
  get_normalized_value_description: "Retourne la valeur d'accélération normalisée",

  /* --- setAccelerometerCursor --- */
  set_accelerometer_cursor_description: "Règle le curseur d'accélération",
  set_accelerometer_cursor_param_x: "X Position du curseur d'accélération",
  set_accelerometer_cursor_param_y: "Y Position du curseur d'accélération",

  /* --- getAccelerometerCursor --- */
  get_accelerometer_cursor_description: "Lit la position actuelle (axe) du curseur d'accélération",
  get_accelerometer_cursor_param_axis: "Axe d'accélération (AXIS_X ou AXIS_Y) du curseur à lire",

  /* --- getOrientation --- */
  get_orientation_description: "Retourne l'orientation actuelle de la carte (ORIENTATION_XY)",

  /* --- isOrientation --- */
  is_orientation_description: "Retourne vrai si l'Oxocard est dans l'orientation définie.",
  is_orientation_param_direction: 'Orientation à vérifier (ORIENTATION_XY)',

  /* --- setReferenceVector --- */
  set_reference_vector_description:
    'Fixe le vecteur de référence à la position actuelle sur la carte',

  /* --- getAngle --- */
  get_angle_description:
    "Renvoie l'angle actuel au vecteur de référence. Le vecteur de référence par défaut correspond à la ligne droite du tableau.",

  /* --- configureAccelerometer --- */
  config_acc_description: "Configure le débit et la plage d'accélération des données",
  config_acc_param_data_rate: 'Débit de données: POWER_DOWN ou NORMAL_xy_HZ',
  config_acc_param_range: 'Gamme: MAX_xyG',

  /* ---------- Category Audio ---------- */
  category_audio: 'Audio',
  /* --- playTone --- */
  play_tone_description: 'Joue un son à la fréquence définie',
  play_tone_param_hz: 'Fréquence en Hz',

  /* --- playToneFor --- */
  play_tone_for_description: 'Joue un son avec la fréquence et la durée définies',
  play_tone_for_param_hz: 'Fréquence en Hz',
  play_tone_for_param_ms: 'Durée en millisecondes',

  /* --- noteToMs --- */
  note_to_ms_description: 'Convertit la durée des notes en millisecondes',
  note_to_ms_param_note_duration: 'Durée des notes (NOTE_DUR_XY)',

  /* --- playNote --- */
  play_note_description: 'Joue une note',
  play_note_param_note: 'Note (NOTE_XY)',

  /* --- playNoteFor --- */
  play_note_for_description: 'Joue une note pour la durée définie',
  play_note_for_param_note: 'Note (NOTE_XY)',
  play_note_for_param_ms: 'Durée en millisecondes',

  /* --- noTone --- */
  no_tone_description: 'Arrêter le son actuel',

  /* --- setWaveForm --- */
  set_wave_form_description:
    "Définit la forme d'onde du signal audio:\n\tWAVEFORM_SINE\n\tWAVEFORM_SQUARE\n\tWAVEFORM_SAWTOOTH\n\tWAVEFORM_TRIANGLE",
  set_wave_form_param_wave_form: "Forme d'onde (WAVEFORM_XY)",

  /* --- setOctave --- */
  set_octave_description: "Fixe l'octave des notes suivantes",
  set_octave_param_octave: 'Oktave (OCTAVE_XY)',

  /* --- setBPM --- */
  set_bpm_description: 'Règle le BPM (battements par minute) des notes suivantes',
  set_bpm_param_bpm: 'BPM',

  /* --- setToneBlocking --- */
  set_tone_blocking_description: 'Attendre après chaque tonalité suivante',
  set_tone_blocking_param_is_blocking: 'true -> attendre; false -> pas attendre',

  /* --- setVolume --- */
  set_volume_description: 'Règle le volume de la sortie audio',
  set_volume_param_volume: 'Volume 0 - 255',

  /* --- getVolume --- */
  get_volume_description: 'Renvoie le volume actuel',

  /* --- volumeUp --- */
  volume_up_description: 'Augmente le volume',

  /* --- volumeDown --- */
  volume_down_description: 'Diminue le volume',

  /* --- playSoundEffect --- */
  play_sound_effect_description: 'Joue un effet sonore',
  play_sound_effect_param_effect_num: "L'un des SOUND_EFFECT_ Effets",
  play_sound_effect_param_loop: "C'est vrai, si l'effet doit être répété",

  /* ---------- Category Microphone ---------- */
  category_microphone: 'Microphone',

  /* --- record --- */
  record_description: 'Enregistrements pour la durée définie',
  record_param_ms: "Temps d'enregistrement en millisecondes",

  /* --- playRecorded --- */
  play_recorded_description: 'Jouer le matériel enregistré',
  play_recorded_param_loop: 'Si la production doit être répétée -> vraie (true)',

  /* --- waitForLoudNoise --- */
  wait_for_loud_noise_description: 'Attends le grand bruit',

  /* --- startMicrophone --- */
  start_microphone_description: 'Active le microphone (le son est arrêté)',

  /* --- readDecibel --- */
  read_decibel_description: 'Retourne le niveau de bruit actuel mesuré par le microphone',

  /* --- readFrequency --- */
  read_frequency_description:
    'Retourne la fréquence du niveau de bruit le plus élevé actuellement mesuré par le microphone',

  /* --- stopMicrophone --- */
  stop_microphone_description: "Désactive le microphone (l'audio est démarré)",

  /* ---------- Category Internet ---------- */
  category_internet: 'Internet',

  /* --- connect --- */
  connect_description:
    "Connecte l'Oxocard à l'interface de connexion définie (actuellement seulement WIFI)",
  connect_param_interface: 'Interface de connexion (par exemple, WIFI)',

  /* --- disconnect --- */
  disconnect_description: "Déconnecte l'Oxocard de l'interface de connexion actuelle",

  /* --- startAccessPointMode --- */
  start_access_point_mode_description: "Met l'Oxocard en mode point d'accès",

  /* --- startPairingMode --- */
  start_pairing_mode_description: "Met l'Oxocard en mode de couplage",

  /* --- hasNewScript --- */
  has_new_script_description:
    "Retourne vrai lorsqu'un nouveau script est disponible au téléchargement",

  /* --- downloadScript --- */
  download_script_description: "Met l'Oxocard en mode de mise à jour",

  /* ---------- Category Extras ---------- */
  category_extras: 'Autres',

  /* --- setPinMode --- */
  set_pin_mode_description: "Définit le mode de fonctionnement d'une épingle (GPIOs)",
  set_pin_mode_param_pin: 'Épingle (IOXY)',
  set_pin_mode_param_mode: 'Mode (INPUT, INPUT_PULLUP, INPUT_PULLDOWN oder OUTPUT)',

  /* --- setPin --- */
  set_pin_description: "Définit l'état d'une épingle",
  set_pin_param_pin: 'Épingle (IOXY)',
  set_pin_param_state: 'Statut (HIGH ou LOW)',

  /* --- readPin --- */
  read_pin_description: "Retourne l'état actuel du pin défini.",
  read_pin_param_pin: 'Épingle (IOXY)',

  /* --- print --- */
  print_description:
    "Donne un message au terminal.\n\nNote: connect(WIFI) doit être appelé avant d'envoyer des messages au navigateur",
  print_param_pin: 'Variable, liste ou texte à envoyer',

  /* --- pushValue --- */
  push_value_description: 'Envoie une valeur unique avec une clé définie au navigateur',
  push_value_param_key: 'Clé de la valeur à envoyer',
  push_value_param_value: 'La valeur à envoyer',

  /* --- pushValues --- */
  push_values_description: 'Envoie une liste avec une clé définie au navigateur',
  push_values_param_key: 'Clé de la liste à envoyer',
  push_values_param_values: 'La liste à envoyer',

  /* --- getValues --- */
  get_values_description: 'Décrit la liste définie avec les valeurs reçues de la clé définie',
  get_values_param_key: 'Clé des valeurs à recevoir',
  get_values_param_values: 'Liste pour décrire les valeurs reçues',

  /* --- clearValues --- */
  clear_values_description: 'Supprime toutes les valeurs de la clé définie',
  clear_values_param_key: 'Clé des valeurs à supprimer',

  /* --- removeValues --- */
  remove_values_description: 'Supprime toutes les valeurs de la clé définie et supprime la clé',
  remove_values_param_key: 'Clé des valeurs à supprimer',

  /* --- i2cWrite --- */
  i2c_write_description: 'Envoie des données à un dispositif I2C',
  i2c_write_param_slaveAdr: "Adresse de l'appareil",
  i2c_write_param_dataAdr: 'Adresse des données (registre)',
  i2c_write_param_data: 'Liste des données à envoyer',

  /* --- i2cWrite --- */
  i2c_read_description: "Reçoit des données d'un dispositif I2C",
  i2c_read_param_slaveAdr: "Adresse de l'appareil",
  i2c_read_param_dataAdr: 'Adresse des données (registre)',
  i2c_read_param_data: 'Liste des données à recevoir',
  i2c_read_param_dataLength: 'Nombre de bytes à recevoir',
};
