/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* ---------- Boolean ---------- */
  boolean_title: 'Boolean',
  boolean_description:
    'Les booléens sont utilisés pour mettre quelque chose sur le vrai ou le faux.',
  boolean_const_true: 'Si quelque chose doit être vrai',
  boolean_const_false: 'Si quelque chose ne va pas',

  /* ---------- Buttons ---------- */
  button_title: 'Boutons',
  button_description: "Constantes pour tous les boutons de l'Oxocard",
  button_const_btn_l1: 'Bouton L1 (en haut à gauche)',
  button_const_btn_l2: 'Bouton L2 (gauche en bas à gauche)',
  button_const_btn_l3: 'Bouton L3 (gauche en bas à droite)',
  button_const_btn_r1: 'Bouton R1 (en haut à droite)',
  button_const_btn_r2: 'Bouton R2 (droite en bas à gauche)',
  button_const_btn_r3: 'Bouton R3 (côté droit en bas à droite)',
  button_const_btn_any: "N'importe quel bouton",

  /* ---------- Wake up reason ---------- */
  wake_up_reasons_title: 'Raisons de se réveiller',
  wake_up_reasons_description:
    "Constantes pour toutes les raisons avec lesquelles l'Oxocard peut se réveiller.",
  wake_up_reasons_const_reset: 'Si la carte a été réveillée par un redémarrage.',
  wake_up_reasons_const_timer: 'Si la carte a été réveillée par la minuterie de sommeil',

  /* ---------- Color ---------- */
  color_title: 'Couleurs',
  color_description: "Constantes pour les canaux de couleur d'un pixel de la matrice",
  color_const_red: 'Canal rouge',
  color_const_green: 'Canal vert',
  color_const_blue: 'Canal bleu',

  /* ---------- Time ---------- */
  time_title: 'Temps',
  time_description: 'Constantes pour les paramètre de temps ',
  time_const_seconds: 'Paramètre des secondes',
  time_const_minutes: 'Paramètre du minutes',
  time_const_hours: 'Paramètres des heures',

  /* ---------- Date ---------- */
  date_title: 'Date',
  date_description: 'Constantes pour les paramètres de date',
  date_const_weekday: 'Paramètre du jour de la semaine',
  date_const_day: 'Paramètre du jour',
  date_const_month: 'Paramètre du mois',
  date_const_year: "Paramètre de l'année",

  /* ---------- Weather parameter ---------- */
  weather_param_title: 'Météo',
  weather_param_description: 'Constantes pour les paramètres météorologiques',
  weather_param_const_temp: 'Paramètres de température',
  weather_param_const_dew_point: 'Paramètres du point de rosée',
  weather_param_const_humidity: "Paramètre d'humidité",
  weather_param_const_wind: 'Paramètres de vent',
  weather_param_const_precipitation: 'Paramètre de précipitation',

  /* ---------- Communication ---------- */
  communication_title: 'Communication',
  communication_description:
    'Constantes pour le type de communication.\nLOCAL: Connexion directe de carte à carte\nGLOBAL: Connexion via le serveur',
  communication_const_local: 'Communication locale de carte à carte',
  communication_const_global: 'Communication globale via un serveur',

  /* ---------- Axis ---------- */
  axis_title: 'Axes',
  axis_description: "Constantes pour les axes de l'accéléromètre",
  axis_const_x: 'X Axes',
  axis_const_y: 'Y Axes',
  axis_const_z: 'Z Axes',

  /* ---------- Orientations ---------- */
  orientations_title: 'Orientation',
  orientations_description: "Constantes pour l'orientation de l'accéléromètre",
  orientations_const_unknown: 'Orientation inconnue',
  orientations_const_flat: 'Carte à plat sur la table',
  orientations_const_flipped: "Allongée à l'envers sur la table",
  orientations_const_up: 'Carte debout avec les boutons ci-dessous',
  orientations_const_half_up: 'A moitié debout avec les boutons baissés',
  orientations_const_down: 'Carte debout avec les boutons sur le dessus',
  orientations_const_half_down: 'A moitié debout avec les boutons en haut',
  orientations_const_left: 'Carte debout sur le côté gauche',
  orientations_const_half_left: 'A moitié debout sur le côté gauche',
  orientations_const_right: 'Carte sur le côté droit',
  orientations_const_half_right: 'A moitié debout sur le côté droit',

  /* ---------- Data Rates ---------- */
  data_rate_title: 'Débits de données',
  data_rate_description: "Constantes pour les différents débits de données de l'accéléromètre",
  data_rate_const_data_rate_const_power_down: 'Power down Mode',
  data_rate_const_data_rate_const_1hz: 'Débit de données de 1 Hz',
  data_rate_const_data_rate_const_10hz: 'Débit de données de 10 Hz',
  data_rate_const_data_rate_const_25hz: 'Débit de données de 25 Hz',
  data_rate_const_data_rate_const_50hz: 'Débit de données de 50 Hz',
  data_rate_const_data_rate_const_100hz: 'Débit de données de 100 Hz',
  data_rate_const_data_rate_const_200hz: 'Débit de données de 200 Hz',
  data_rate_const_data_rate_const_400hz: 'Débit de données de 400 Hz',
  data_rate_const_data_rate_const_1334hz: 'Débit de données de 1334 Hz',

  /* ---------- Accelerometer ranges ---------- */
  accelerometer_range_title: "Plages d'accélération",
  accelerometer_range_description: "Constantes pour les différentes résolutions de l'accéléromètre",
  accelerometer_range_const_2g: '+- 2G',
  accelerometer_range_const_4g: '+- 4G',
  accelerometer_range_const_8g: '+- 8G',
  accelerometer_range_const_16g: '+- 16G',

  /* ---------- Notes ---------- */
  notes_title: 'Partitions',
  notes_description: 'Constantes pour les notes',
  notes_const_note_rest: 'Pause du son',
  notes_const_note_c: 'Son C',
  notes_const_note_cs: 'Son CS',
  notes_const_note_d: 'Son D',
  notes_const_note_ds: 'Son DS',
  notes_const_note_e: 'Son E',
  notes_const_note_f: 'Son F',
  notes_const_note_fs: 'Son FS',
  notes_const_note_g: 'Son G',
  notes_const_note_gs: 'Son GS',
  notes_const_note_a: 'Son A',
  notes_const_note_as: 'Son AS',
  notes_const_note_b: 'Son B',

  /* ---------- Note durations ---------- */
  note_durations_title: 'Longueur des notes',
  note_durations_description: 'Constantes pour la longueur des billets',
  note_durations_const_whole: '1/1 Horloge',
  note_durations_const_half: '1/2 Horloge',
  note_durations_const_quarter: '1/4 Horloge',
  note_durations_const_eight: '1/8 Horloge',
  note_durations_const_sixteenth: '1/16 Horloge',
  note_durations_const_thirty_second: '1/32 Horloge',

  /* ---------- Octaves ---------- */
  octaves_title: 'Octaves',
  octaves_description: 'Constantes pour les octaves de notes',
  octaves_const_c3: 'Octave C3',
  octaves_const_c4: 'Octave C4',
  octaves_const_c5: 'Octave C5',
  octaves_const_c6: 'Octave C6',
  octaves_const_c7: 'Octave C7',
  octaves_const_c8: 'Octave C8',

  /* ---------- Waveforms ---------- */
  waveforms_title: "Formes d'onde",
  waveforms_description: "Constantes pour la forme d'onde des tonalités",
  waveforms_const_sine: "Forme d'onde sinusoïdale",
  waveforms_const_square: "Forme d'onde rectangulaire",
  waveforms_const_sawtooth: "Forme d'onde en dents de scie",
  waveforms_const_triangle: "Forme d'onde triangulaire",

  /* ---------- Sound effects ---------- */
  sound_effects_title: 'Sound Effects',
  sound_effects_description: 'Constantes pour les effets sonores jouables',
  sound_effects_const_description: 'Joues-moi avec playSoundEffect()',

  /* ---------- Connection interfaces ---------- */
  connection_interface_title: 'Interfaces de connexion',
  connection_interface_description:
    "Constantes pour les interfaces de connexion de l'Oxocard (seulement WIFI pour le moment)",
  connection_interface_const_wifi: 'WIFI Interface',

  /* ---------- GPIO ---------- */
  gpio_title: 'GPIO',
  gpio_description: 'Constantes pour les pins GPIO de la carte',
  gpio_const_io5: 'GPIO 5',
  gpio_const_io16: 'GPIO 16',
  gpio_const_io17: 'GPIO 17',
  gpio_const_io18: 'GPIO 18',
  gpio_const_io19: 'GPIO 19',
  gpio_const_io23: 'GPIO 23',

  /* ---------- GPIO modes ---------- */
  gpio_mode_title: 'GPIO Modes',
  gpio_mode_description: 'Constantes pour les modes GPIO',
  gpio_mode_const_input: "GPIO en tant qu'entrée",
  gpio_mode_const_input_pullup: 'GPIO en entrée avec une résistance de pull-up',
  gpio_mode_const_input_pulldown: 'GPIO en entrée avec une résistance pull-down',
  gpio_mode_const_output: 'GPIO en tant que sortie',

  /* ---------- GPIO states ---------- */
  gpio_state_title: 'GPIO États',
  gpio_state_description: 'Constantes pour les états du GPIO',
  gpio_state_const_high: 'GPIO high - 3.3V',
  gpio_state_const_low: 'GPIO low - 0V',
};
