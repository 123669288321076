/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    name: 'boolean_title',
    description: 'boolean_description',
    constants: [
      { name: 'true', description: 'boolean_const_true' },
      { name: 'false', description: 'boolean_const_false' },
      { name: 'True', description: 'boolean_const_true' },
      { name: 'False', description: 'boolean_const_false' },
      { name: 'TRUE', description: 'boolean_const_true' },
      { name: 'FALSE', description: 'boolean_const_false' },
    ],
    examples: ['turnOffWakeUpButton(true, false, false, true, false, false)\n'],
  },
  {
    name: 'button_title',
    description: 'button_description',
    constants: [
      { name: 'BTN_L1', description: 'button_const_btn_l1' },
      { name: 'BTN_L2', description: 'button_const_btn_l2' },
      { name: 'BTN_L3', description: 'button_const_btn_l3' },
      { name: 'BTN_R1', description: 'button_const_btn_r1' },
      { name: 'BTN_R2', description: 'button_const_btn_r2' },
      { name: 'BTN_R3', description: 'button_const_btn_r3' },
      { name: 'BTN_ANY', description: 'button_const_btn_any' },
    ],
    examples: ['isButtonPressed(BTN_R1)\n'],
  },
  {
    name: 'wake_up_reasons_title',
    description: 'wake_up_reasons_description',
    constants: [
      { name: 'RESET', description: 'wake_up_reasons_const_reset' },
      { name: 'TIMER', description: 'wake_up_reasons_const_timer' },
      { name: 'BTN_L1', description: 'button_const_btn_l1' },
      { name: 'BTN_L2', description: 'button_const_btn_l2' },
      { name: 'BTN_L3', description: 'button_const_btn_l3' },
      { name: 'BTN_R1', description: 'button_const_btn_r1' },
      { name: 'BTN_R2', description: 'button_const_btn_r2' },
      { name: 'BTN_R3', description: 'button_const_btn_r3' },
    ],
    examples: ['if getWakeUpCause() == RESET:\n    drawText("Reset", true)\n'],
  },
  {
    name: 'color_title',
    description: 'color_description',
    constants: [
      { name: 'RED', description: 'color_const_red' },
      { name: 'GREEN', description: 'color_const_green' },
      { name: 'BLUE', description: 'color_const_blue' },
    ],
    examples: ['readPixel(RED, 0, 0)\n'],
  },
  {
    name: 'time_title',
    description: 'time_description',
    constants: [
      { name: 'SECONDS', description: 'time_const_seconds' },
      { name: 'MINUTES', description: 'time_const_minutes' },
      { name: 'HOURS', description: 'time_const_hours' },
    ],
    examples: ['s = getTime(SECONDS)\nm = getTime(MINUTES)\nh = getTime(HOURS)\n'],
  },
  {
    name: 'date_title',
    description: 'date_description',
    constants: [
      { name: 'WEEKDAY', description: 'date_const_weekday' },
      { name: 'DAY', description: 'date_const_day' },
      { name: 'MONTH', description: 'date_const_month' },
      { name: 'YEAR', description: 'date_const_year' },
    ],
    examples: ['d = getDate(DAY)\nm = getDate(MONTH)\ny = getDate(YEAR)\n'],
  },
  {
    name: 'weather_param_title',
    description: 'weather_param_description',
    constants: [
      { name: 'TEMPERATURE', description: 'weather_param_const_temp' },
      { name: 'DEW_POINT', description: 'weather_param_const_dew_point' },
      { name: 'HUMIDITY', description: 'weather_param_const_humidity' },
      { name: 'WIND', description: 'weather_param_const_wind' },
      { name: 'PRECIPITATION', description: 'weather_param_const_precipitation' },
    ],
    examples: ['readWeatherParam(TEMPERATURE)\n'],
  },
  {
    name: 'axis_title',
    description: 'axis_description',
    constants: [
      { name: 'AXIS_X', description: 'axis_const_x' },
      { name: 'AXIS_Y', description: 'axis_const_y' },
      { name: 'AXIS_Z', description: 'axis_const_z' },
    ],
    examples: ['x = getAcceleration(AXIS_X)\n'],
  },
  {
    name: 'orientations_title',
    description: 'orientations_description',
    constants: [
      { name: 'ORIENTATION_UNKNOWN', description: 'orientations_const_unknown' },
      { name: 'ORIENTATION_FLAT', description: 'orientations_const_flat' },
      { name: 'ORIENTATION_FLAT_FLIPPED', description: 'orientations_const_flipped' },
      { name: 'ORIENTATION_UP', description: 'orientations_const_up' },
      { name: 'ORIENTATION_HALF_UP', description: 'orientations_const_half_up' },
      { name: 'ORIENTATION_DOWN', description: 'orientations_const_down' },
      { name: 'ORIENTATION_HALF_DOWN', description: 'orientations_const_half_down' },
      { name: 'ORIENTATION_LEFT', description: 'orientations_const_left' },
      { name: 'ORIENTATION_HALF_LEFT', description: 'orientations_const_half_left' },
      { name: 'ORIENTATION_RIGHT', description: 'orientations_const_right' },
      { name: 'ORIENTATION_HALF_RIGHT', description: 'orientations_const_half_right' },
    ],
    examples: ['isOrientation(ORIENTATION_UP)\n'],
  },
  {
    name: 'data_rate_title',
    description: 'data_rate_description',
    constants: [
      { name: 'POWER_DOWN', description: 'data_rate_const_power_down' },
      { name: 'NORMAL_1_HZ', description: 'data_rate_const_1hz' },
      { name: 'NORMAL_10_HZ', description: 'data_rate_const_10hz' },
      { name: 'NORMAL_25_HZ', description: 'data_rate_const_25hz' },
      { name: 'NORMAL_50_HZ', description: 'data_rate_const_50hz' },
      { name: 'NORMAL_100_HZ', description: 'data_rate_const_100hz' },
      { name: 'NORMAL_200_HZ', description: 'data_rate_const_200hz' },
      { name: 'NORMAL_400_HZ', description: 'data_rate_const_400hz' },
      { name: 'NORMAL_1344_HZ', description: 'data_rate_const_1334hz' },
    ],
    examples: ['configureAccelerometer(NORMAL_100_HZ, MAX_4G)\n'],
  },
  {
    name: 'accelerometer_range_title',
    description: 'accelerometer_range_description',
    constants: [
      { name: 'MAX_2G', description: 'accelerometer_range_const_2g' },
      { name: 'MAX_4G', description: 'accelerometer_range_const_4g' },
      { name: 'MAX_8G', description: 'accelerometer_range_const_8g' },
      { name: 'MAX_16G', description: 'accelerometer_range_const_16g' },
    ],
    examples: ['configureAccelerometer(NORMAL_100_HZ, MAX_4G)\n'],
  },
  {
    name: 'notes_title',
    description: 'notes_description',
    constants: [
      { name: 'NOTE_REST', description: 'notes_const_note_rest' },
      { name: 'NOTE_C', description: 'notes_const_note_c' },
      { name: 'NOTE_CS', description: 'notes_const_note_cs' },
      { name: 'NOTE_D', description: 'notes_const_note_d' },
      { name: 'NOTE_DS', description: 'notes_const_note_ds' },
      { name: 'NOTE_E', description: 'notes_const_note_e' },
      { name: 'NOTE_F', description: 'notes_const_note_f' },
      { name: 'NOTE_FS', description: 'notes_const_note_fs' },
      { name: 'NOTE_G', description: 'notes_const_note_g' },
      { name: 'NOTE_GS', description: 'notes_const_note_gs' },
      { name: 'NOTE_A', description: 'notes_const_note_a' },
      { name: 'NOTE_AS', description: 'notes_const_note_as' },
      { name: 'NOTE_B', description: 'notes_const_note_b' },
    ],
    examples: ['playNote(NOTE_C)\n'],
  },
  {
    name: 'note_durations_title',
    description: 'note_durations_description',
    constants: [
      { name: 'NOTE_DUR_WHOLE', description: 'note_durations_const_whole' },
      { name: 'NOTE_DUR_HALF', description: 'note_durations_const_half' },
      { name: 'NOTE_DUR_QUARTER', description: 'note_durations_const_quarter' },
      { name: 'NOTE_DUR_EIGHTH', description: 'note_durations_const_eight' },
      { name: 'NOTE_DUR_SIXTEENTH', description: 'note_durations_const_sixteenth' },
      { name: 'NOTE_DUR_THIRTY_SECOND', description: 'note_durations_const_thirty_second' },
    ],
    examples: ['playNoteFor(NOTE_A, noteToMs(NOTE_DUR_EIGHTH))\n'],
  },
  {
    name: 'octaves_title',
    description: 'octaves_description',
    constants: [
      { name: 'OCTAVE_C3', description: 'octaves_const_c3' },
      { name: 'OCTAVE_C4', description: 'octaves_const_c4' },
      { name: 'OCTAVE_C5', description: 'octaves_const_c5' },
      { name: 'OCTAVE_C6', description: 'octaves_const_c6' },
      { name: 'OCTAVE_C7', description: 'octaves_const_c7' },
      { name: 'OCTAVE_C8', description: 'octaves_const_c8' },
    ],
    examples: ['setOctave(OCTAVE_C3)\n'],
  },
  {
    name: 'waveforms_title',
    description: 'waveforms_description',
    constants: [
      { name: 'WAVEFORM_SINE', description: 'waveforms_const_sine' },
      { name: 'WAVEFORM_SQUARE', description: 'waveforms_const_square' },
      { name: 'WAVEFORM_SAWTOOTH', description: 'waveforms_const_sawtooth' },
      { name: 'WAVEFORM_TRIANGLE', description: 'waveforms_const_triangle' },
    ],
    examples: ['setWaveForm(WAVEFORM_SQUARE)\n'],
  },
  {
    name: 'sound_effects_title',
    description: 'sound_effects_description',
    constants: [
      { name: 'SOUND_EFFECT_ALARM', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_BABY_SNEEZE', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_CLING', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_OPEN_CAN', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_PEW', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_PLING', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_POOL_SHOT', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_SLAP', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_SPIN_JUMP', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_SPURS', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_TURKEY', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_WATER', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_BEAT1', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_BEAT2', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_BEAT3', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_BEAT4', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_BEAT5', description: 'sound_effects_const_description' },
      { name: 'SOUND_EFFECT_BEAT6', description: 'sound_effects_const_description' },
    ],
    examples: ['playSoundEffect(SOUND_EFFECT_CANNON, FALSE);\n'],
  },
  {
    name: 'connection_interface_title',
    description: 'connection_interface_description',
    constants: [{ name: 'WIFI', description: 'connection_interface_const_wifi' }],
    examples: ['connect(WIFI)\n'],
  },
  {
    name: 'gpio_title',
    description: 'gpio_description',
    constants: [
      { name: 'IO5', description: 'gpio_const_io5' },
      { name: 'IO16', description: 'gpio_const_io16' },
      { name: 'IO17', description: 'gpio_const_io17' },
      { name: 'IO18', description: 'gpio_const_io18' },
      { name: 'IO19', description: 'gpio_const_io19' },
      { name: 'IO23', description: 'gpio_const_io23' },
    ],
    examples: ['pin5 = readPin(IO5)\n'],
  },
  {
    name: 'gpio_mode_title',
    description: 'gpio_mode_description',
    constants: [
      { name: 'INPUT', description: 'gpio_mode_const_input' },
      { name: 'INPUT_PULLUP', description: 'gpio_mode_const_input_pullup' },
      { name: 'INPUT_PULLDOWN', description: 'gpio_mode_const_input_pulldown' },
      { name: 'OUTPUT', description: 'gpio_mode_const_output' },
    ],
    examples: ['setPinMode(IO16, OUTPUT)\n'],
  },
  {
    name: 'gpio_state_title',
    description: 'gpio_state_description',
    constants: [
      { name: 'HIGH', description: 'gpio_state_const_high' },
      { name: 'LOW', description: 'gpio_state_const_low' },
    ],
    examples: ['setPin(IO23, HIGH)\n'],
  },
];
