import React from 'react';
import { connect } from 'react-redux';

import constantData from './constantData';

/* Components */
import ConstantList from './ConstantList';

/* Store */

/* Styles */
import './Constants.css';

const filter = (data, filterString) => {
  if (!filterString.length) {
    return data;
  }

  const filterStringLow = filterString.toLowerCase();

  let allFilteredConstants = [];
  data.forEach(category => {
    const filteredConstants = category.constants.filter(func => {
      return func.name.toLowerCase().includes(filterStringLow);
    });

    if (filteredConstants.length) {
      const constants = filteredConstants.map(c => {
        c.parentCategory = category.name;
        c.parentCategoryDescription = category.description;
        c.examples = category.examples;
        return c;
      });
      allFilteredConstants = [...allFilteredConstants, ...constants];
    }
    return false;
  });

  return allFilteredConstants;
};

class Constants extends React.PureComponent {
  state = {
    filterString: '',
    constantData,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.searchString !== state.filterString) {
      return {
        filterString: props.searchString,
        constantData: filter(constantData, props.searchString),
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.props.isOpen === false) {
      this.setState({ activeFunctionCategory: -1 });
    }
  }

  render() {
    return (
      <div className="constants_wrapper">
        <ConstantList
          constants={this.state.constantData}
          isOpen={this.props.isOpen}
          filterString={this.state.filterString}
        />
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return { theme: settings.editorTheme };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Constants);
