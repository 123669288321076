/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* ---------- def main() ---------- */
  main_description:
    "La fonction Main est le point d'entrée de votre programme. Il se compose souvent du code de configuration, qui n'est exécuté qu'une fois, et d'une boucle qui se répète constamment.",
  /* ---------- + / - / * / % / / ---------- */
  math_description:
    "Tous les opérateurs mathématiques courants peuvent être utilisés. L'opérateur % (modulo) renvoie le reste d'une division.",
  /* ---------- [lists] ---------- */
  list_description:
    'Les listes sont un bon moyen de combiner plusieurs valeurs et de les appliquer.',
  list_example_name_empty: '[]',
  list_example_description_empty: 'Tu peux initialiser une liste vide.',
  list_example_name_values: '[1, 2]',
  list_example_description_values: 'Tu peux initialiser une liste de valeurs.',
  list_example_name_append: '[1, 2] + 3',
  list_example_description_append:
    "Tu peux ajouter des valeurs individuelles ou d'autres listes à une liste.",
  list_example_name_read: 'maListe[2]',
  list_example_description_read:
    "Tu peux lire le contenu d'un poste spécifique dans une liste (commençant par 0).",
  list_example_name_size: 'size / min / max / avg',
  list_example_description_size:
    "Tu peux lire la taille ainsi que la valeur minimale, maximale ou moyenne d'une liste.",
  /* ---------- "strings" ---------- */
  string_description: 'Strings représentent le texte dans Oxoscript',
  string_example_name_empty: '""',
  string_example_description_empty: 'Tu peux initialiser une String vide.',
  string_example_name_values: '"Hello World"',
  string_example_description_values: 'Tu peux initialiser une String avec une valeur.',
  string_example_name_append: '"Hello" + "World"',
  string_example_description_append: 'Tu peux attacher une String à une autre String.',
  /* ---------- if / elif / else ---------- */
  if_description:
    'Les conditions sont utilisées pour prendre des décisions dans le code. Ils peuvent être composés d\'une déclaration "if", "elif" et/ou "else".',
  if_example_name_if: 'if',
  if_example_description_if:
    "Le code à l'intérieur du si n'est exécuté que si la condition est vraie.",
  if_example_name_if_elif_else: 'elif',
  if_example_description_if_elif_else:
    'Le contenu de elif n\'est exécuté que si la condition "if" définie était fausse et que la condition "elif" est vraie.',
  if_example_name_if_else: 'else',
  if_example_description_if_else:
    'Le contenu de la déclaration "else" n\'est exécuté que si la condition "if" et "elif" était fausse.',
  /* ---------- while ---------- */
  while_description:
    "Le contenu d'une boucle de temps est exécuté tant que l'état de la boucle est vrai.",
  /* ---------- repeat ---------- */
  repeat_description: 'Le contenu de la boucle "repeat" est exécuté pour le nombre de fois défini.',
  /* ---------- for ---------- */
  for_description:
    "La boucle \"for\" peut être utilisée pour itérer sur une liste ou une série de numéros. Il se compose d'une variable et d'une expression. La variable est mise à jour après chaque boucle avec la valeur suivante de l'expression. Une expression peut être une liste, une série de nombres ou une variable.",
  /* ---------- break / continue ---------- */
  break_continue_description:
    "Break et continue ne peuvent être utilisées qu'à l'intérieur d'une boucle. Si l'on appelle break, la boucle est immédiatement quittée. Si l'on appelle continue, le reste du code de la boucle est sauté et la boucle commence avec le passage suivant.",
  /* ---------- ++/-- ---------- */
  add_sub_description:
    "L'opérateur ++ ajoute un à une valeur et l'opérateur -- soustrait un de la valeur.",
  /* ---------- Comparison operators ---------- */
  comp_op_description:
    'Les opérateurs de comparaison sont utilisés dans les déclarations if, while et for.',
  comp_op_example_name_equ: '==',
  comp_op_example_description_equ: "C'est vrai si le côté droit est égal au côté gauche.",
  comp_op_example_name_unequ: '!=',
  comp_op_example_description_unequ: "C'est vrai si le côté droit n'est pas égal au côté gauche.",
  comp_op_example_name_smaller: '< / <=',
  comp_op_example_description_smaller:
    'Est vrai si le côté gauche est inférieur (<) ou égal (<=) au côté droit.',
  comp_op_example_name_bigger: '> / >=',
  comp_op_example_description_bigger:
    'Est vrai si le côté gauche est supérieur (>) ou supérieur ou égal (>=) au côté droit.',
  comp_op_example_name_and: '&& / and',
  comp_op_example_description_and: "Ce n'est vrai (true) que si les deux parties le sont.",
  comp_op_example_name_or: '|| / or',
  comp_op_example_description_or: "Est vrai (true) si l'une des pages est vraie",
  /* ---------- not ---------- */
  not_description: 'Négocie une valeur logique. vrai devient faux et vice versa.',
  /* ---------- def functions() ---------- */
  function_description:
    'Créé une fonction personnalisée si tu souhaites exécuter le code plusieurs fois ou simplement améliorer la structure.',
  function_example_name_no_params: 'def maFonction():',
  function_example_description_no_params: 'Créer une nouvelle fonction ',
  function_example_name_params: 'def maFonction(p1, p2):',
  function_example_description_params:
    'Tu peux passer des paramètres à une fonction et aussi retourner quelque chose.',
  function_example_name_by_ref: 'def maFonction(list*):',
  function_example_description_by_ref:
    "Les listes et les chaînes de caractères (Strings) doivent toujours être passées par référence (avec un *). Tu ne peux pas renvoyer des listes à partir d'une fonction. Mais si quelque chose est passé par référence, il peut être modifié directement.",
  /* ---------- 0b / 0x ---------- */
  bit_hex_description:
    'Une valeur décimale peut également être définie comme une valeur binaire (0b) ou une valeur hexadécimale (0x).',
  /* ---------- Bit operators ---------- */
  bit_op_description:
    'Les opérateurs "bitwise" peuvent être utilisés pour traiter efficacement les données en octets.',
  bit_op_example_name_shift_l: '<<',
  bit_op_example_description_shift_l:
    "Déplace le contenu d'une variable petit à petit vers la gauche. Pour un nombre entier non négatif, un décalage est équivalent à une multiplication par 2n.",
  bit_op_example_name_shift_r: '>>',
  bit_op_example_description_shift_r:
    "Déplace le contenu d'une variable petit à petit vers la droite. Pour un nombre entier non négatif, le décalage correspond à la division par 2n et à la troncature des décimales (le cas échéant).",
  bit_op_example_name_and: '&',
  bit_op_example_description_and:
    "L'opérateur ET est utilisé pour combiner deux opérandes ET par bit.\n\n0 ET 0 = 0\n0 ET 1 = 0\n1 ET 0 = 0\n1 ET 1 = 1",
  bit_op_example_name_or: '|',
  bit_op_example_description_or:
    "L'opérateur OU est utilisé pour combiner deux opérandes OU par bit.\n\n0 OU 0 = 0\n0 OU 1 = 1\n1 OU 0 = 1\n1 OU 1 = 1",
  bit_op_example_name_xor: '^',
  bit_op_example_description_xor:
    "L'opérateur XOR est utilisé pour combiner deux opérandes XOR par bit.\n\n0 XOR 0 = 0\n0 XOR 1 = 1\n1 XOR 0 = 1\n1 XOR 1 = 0",
};
