import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';
import configureApi from './api';
import withClearCache from './ClearCache';

import { i18n as corei18n } from 'OxocardCore';

/* Components */
import AppContainer from './components/AppContainer';
import { ReactComponent as Logo } from './assets/logos/logo_oxoscript_intro.svg';
import PopupContainer from './components/common/PopupContainer';

/* Store */
import configureStore from './store/configureStore';
import { load as loadSettings } from './store/ducks/settings';
import { loadContent as loadEditorContent, loadFromHash } from './store/ducks/editor';
import { loadClient, registerClient } from './store/ducks/client';
import { loadCardInfo, pairFromBlockly } from './store/ducks/card';
import { loadVariableHeaders } from './store/ducks/variables';
import { wasmReady } from './store/ducks/wasm';

/* Styles */
import './index.css';
import TopInfoBar from './components/TopInfoBar';

const store = configureStore();

/* Wait for the WASM module to be loaded */
window.Module['onRuntimeInitialized'] = () => {
  store.dispatch(wasmReady());
};

configureApi(store);
store.dispatch(loadSettings());

const url = new URL(window.location.href);
const loadHash = url.searchParams.get('load-hash');
const hmacIdClient = url.searchParams.get('hmacId');
let removeParams = false;
if (loadHash) {
  store.dispatch(loadFromHash(loadHash));
  removeParams = true;
} else {
  store.dispatch(loadEditorContent());
}

const langKey = store.getState().settings.lang;
i18n.changeLanguage(langKey);
corei18n.translator.setLanguage(langKey);
corei18n.translator.updateUI();

/* get the client from the local storage */
const client = store.dispatch(loadClient());

if (client) {
  if (hmacIdClient) {
    store
      .dispatch(pairFromBlockly(hmacIdClient))
      .then(() => store.dispatch(loadVariableHeaders()))
      .catch((error) => {
        /* May have an error here with kasperski internet security or 404 if card is not found*/
      });
    removeParams = true;
  } else {
    /* Request the card infos */
    store
      .dispatch(loadCardInfo())
      .then(() => store.dispatch(loadVariableHeaders()))
      .catch((error) => {
        /* Check if it was an 'Gone' error -> new register needed */
        if (error.message === 'Gone') {
          /* Register the client */
          store.dispatch(registerClient()).catch((error) => {
            //TODO Handle error case here
            console.log('Register client error. Need to handle this case');
          });
        }
      });
  }
} else {
  /* If no client is storen in the local storage
     We need to register the client */
  store.dispatch(registerClient()).catch((error) => {
    //TODO Handle error case here
    console.log('Register client error. Need to handle this case');
  });
}

if (removeParams) {
  window.history.replaceState({}, 'Oxoscript Editor', url.origin);
}

const calculateVh = () => {
  /* Fix for the iOS devices vh property */
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

calculateVh();
window.addEventListener('resize', calculateVh);

const App = () => {
  const [animationRunning, setAnimationRunning] = useState(true);

  setTimeout(() => {
    setAnimationRunning(false);
  }, 3250);

  return (
    <Provider store={store}>
      {animationRunning && (
        <div className="logo-container">
          <div className="logo">
            <Logo />
          </div>
        </div>
      )}
      <TopInfoBar />
      <AppContainer />
      <PopupContainer />
    </Provider>
  );
};

const ClearCacheComponent = withClearCache(App);

ReactDOM.render(<ClearCacheComponent />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
