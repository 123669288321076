/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  hello: 'Hallo',

  /* Top Banner */
  top_banner_text:
    'Diese Oxoscript-Version ist veraltet und wird nicht mehr weiterentwickelt. Die neue Version 2.0 benötigt eine Oxocard Science, Galaxy oder Artwork und ist auf der Oxocard Blockly nicht mehr lauffähig. Mehr Informationen zu neuen Oxoscript-Version gibt es hier: ',

  /* Top Bar */
  top_bar_button_new: 'Neu',
  top_bar_button_open: 'Öffnen',
  top_bar_button_save: 'Speichern',
  top_bar_button_share: 'Teilen',
  top_bar_new_script_name: 'Mein neues Script',

  /* Info Bar */
  info_bar_connect: 'Verknüpfe jetzt deine Oxocard und lade das erste Programm auf die Karte',
  info_bar_compiling: 'Das Programm wird geprüft und kompiliert. Du kannst dazu weiterarbeiten',
  info_bar_error: 'Fehler auf Zeile',
  info_bar_ready: 'Das Programm wurde kompiliert und kann nun auf die Karte geladen werden',

  /* Terminal */
  terminal_header_output: 'Ausgabe',
  terminal_header_clear: 'Löschen',

  /* Menu - First Steps */
  menu_first_steps_title: 'Jetzt Starten',
  menu_first_steps_text: 'Hier findest du eine Starthilfe',
  menu_first_steps_installation: 'Installationsanleitung',
  menu_first_steps_key_commands: 'Oxocard Tastenkombination',
  menu_first_steps_book: 'Oxoscript Handbuch',

  /* Menu - Simulator */
  menu_simulator_title: 'Simulator',
  menu_simulator_unknown_functions: 'wird nicht vom Simulator unterstützt',

  /* Menu - My Oxocard */
  menu_my_oxocard_title: 'Meine Oxocard',
  menu_my_oxocard_no_card_text: 'Du hast noch keine Karte verbunden.',
  menu_my_oxocard_no_card_button: 'Verknüpfe eine Karte',
  menu_my_oxocard_link_other_card_button: 'Verknüpfe eine andere Karte',
  menu_my_oxocard_reset_card_button: 'Setze die Karte zurück',
  menu_my_oxocard_switch_to_blockly: 'Wechsle zu Blockly',

  /* Menu - Documentation */
  menu_documentation_title: 'Dokumentation',
  menu_documentation_search: 'Dokumentation durchsuchen',
  menu_documentation_functions: 'Funktionen',
  menu_documentation_constants: 'Konstanten',

  /* Menu - Examples */
  menu_examples_title: 'Beispiele',

  /* Menu - Worksheets */
  menu_worksheets_title: 'Arbeitsblätter',
  menu_worksheets_text:
    'Die Arbeitsblätter sind noch in Bearbeitung und werden, sobald verfügbar, hier aufgelistet',
  menu_worksheets_a01: 'A01 Die smarte Leuchte',

  /* Menu - Data */
  menu_data_title: 'Daten',
  menu_data_text:
    'Hier findest du die Variablen, die du mit deiner Oxocard an den Browser geschickt hast.',
  menu_data_note_header: 'Hinweis:',
  menu_data_note_text: 'Es werden maximal 100 Werte pro Variable gespeichert und angezeigt.',
  menu_data_public_link: 'Teilen-Link',
  menu_data_latest: 'Aktuell',

  /* Menu - Settings */
  menu_Settings_title: 'Einstellungen',
  menu_Settings_Language_title: 'Bevorzugte Sprache:',
  menu_Settings_Language_option_en: 'English',
  menu_Settings_Language_option_de: 'Deutsch',
  menu_Settings_Language_option_fr: 'Français',
  menu_Settings_theme_title: 'Editor Design wechseln:',
  menu_Settings_theme_option_light: 'Hell',
  menu_Settings_theme_option_dark: 'Dunkel',
  menu_Settings_editor_font_size: 'Schriftgrösse des Editors:',

  /* Menu - Feedback */
  menu_feedback_title: 'Rückmeldungen',
  menu_feedback_text: 'Deine Rückmeldungen sind uns wichtig!',
  menu_feedback_link: 'Sende uns ein Mail',
  menu_feedback_imprintLink: 'Impressum',

  /* Popup - Unsaved changed */
  popup_unsaved_changes_title: 'Du hast ein ungespeichertes Script!',
  popup_unsaved_changes_body:
    'Falls du fortfährst ohne zu speichern verlierst du deine Änderungen. Willst du fortfahren?',
  popup_unsaved_changes_cancel: 'Nein!',
  popup_unsaved_changes_continue: 'Weiter',

  /* Popup - Shared script */
  popup_shared_script_title: 'Dein Link wurde erstellt!',
  popup_shared_script_body:
    'Teile diesen Link mit deinen Freunden oder speichere ihn unter deinen Favoriten.',
  popup_shared_script_cancel: 'Schliessen',
  popup_shared_script_email: 'Verschicke Email',
  popup_shared_script_email_subject: 'Schau dir mein Script an',
  popup_shared_script_copy: 'Kopiere Link',
  popup_shared_script_copied: 'Link wurde kopiert!',

  /* Popup - Switch to Blockly */
  popup_switch_to_blockly_title: 'Möchtest du lieber mit Blockly programmieren?',
  popup_switch_to_blockly_body:
    'Wenn du zu Blockly wechselst, wird die Verknüpfung aufgelöst. Du kannst jederzeit wieder zurück wechseln',
  popup_switch_to_blockly_cancel: 'Im Editor bleiben',
  popup_switch_to_blockly_continue: 'Zu Blockly wechseln',

  /* Popup - Really reset */
  popup_really_reset_title: 'Willst du die Karte wirklich zurücksetzen?',
  popup_really_reset_body:
    'Wenn Du auf reset drückst, werden alle Daten und das Skript auf der Karte gelöscht',
  popup_really_reset_cancel: 'Nein!',
  popup_really_reset_continue: 'Reset',

  /* Popup - Really clear data */
  popup_really_clear_data_title: 'Willst du diese Daten wirklich löschen?',
  popup_really_clear_data_body:
    'Wenn Du auf Ja drückst, werden alle Daten dieser Variable unwiderruflich gelöscht.',
  popup_really_clear_data_cancel: 'Nein',
  popup_really_clear_data_continue: 'Ja',

  /* Popup - Really delete variable */
  popup_really_delete_variable_title: 'Keine Daten vorhanden. Willst du die Variable löschen?',
  popup_really_delete_variable_body:
    'Wenn Du auf Ja drückst, wird die Variable unwiderruflich gelöscht.',
  popup_really_delete_variable_cancel: 'Nein',
  popup_really_delete_variable_continue: 'Ja',
};
