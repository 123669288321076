/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    name: 'main()',
    description: 'main_description',
    examples: [
      {
        name: null,
        description: null,
        code: '<span class="keyword">def main</span>():<br>&#09;<span class="comment"># my setup code</span><br>&#09;<span class="keyword">while</span> <span class="constant">true</span>:<br>&#09;&#09;<span class="comment"># my loop code</span><br>',
      },
    ],
  },
  {
    name: '+ / - / * / % / /',
    description: 'math_description',
    examples: [
      {
        name: null,
        description: null,
        code: 'r = (<span class="number">7</span> + <span class="number">2</span> - <span class="number">1</span> * <span class="number">3</span>) / <span class="number">2</span> <span class="comment"># r = 3</span><br><br>r = <span class="number">10</span> % <span class="number">3</span>  <span class="comment"># r = 1</span>',
      },
    ],
  },
  {
    name: '[lists]',
    description: 'list_description',
    examples: [
      {
        name: 'list_example_name_empty',
        description: 'list_example_description_empty',
        code: 'a = []',
      },
      {
        name: 'list_example_name_values',
        description: 'list_example_description_values',
        code: 'a = [<span class="number">1</span>, <span class="number">2</span>]',
      },
      {
        name: 'list_example_name_append',
        description: 'list_example_description_append',
        code: 'a = [<span class="number">1</span>, <span class="number">2</span>] + <span class="number">3</span><br>a = [<span class="number">1</span>, <span class="number">2</span>] + [<span class="number">3</span>, <span class="number">4</span>]',
      },
      {
        name: 'list_example_name_read',
        description: 'list_example_description_read',
        code: 'a = [<span class="number">1</span>, <span class="number">2</span>, <span class="number">3</span>]<br>r = a[<span class="number">2</span>]  <span class="comment"># r = 3</span>',
      },
      {
        name: 'list_example_name_size',
        description: 'list_example_description_size',
        code: 'a = [<span class="number">1</span>, <span class="number">2</span>, <span class="number">3</span>]<br>r = <span class="function">size</span>(a) <span class="comment"># r = 3</span><br>r = <span class="function">min</span>(a)  <span class="comment"># r = 1</span><br>r = <span class="function">max</span>(a)  <span class="comment"># r = 3</span><br>r = <span class="function">avg</span>(a)  <span class="comment"># r = 2</span>',
      },
    ],
  },
  {
    name: '"strings"',
    description: 'string_description',
    examples: [
      {
        name: 'string_example_name_empty',
        description: 'string_example_description_empty',
        code: 'a = <span class="string">""</span>',
      },
      {
        name: 'string_example_name_values',
        description: 'string_example_description_values',
        code: 'a = <span class="string">"some text"</span>',
      },
      {
        name: 'string_example_name_append',
        description: 'string_example_description_append',
        code: 'a = <span class="string">"some"</span> + <span class="string">" text"</span><br><br><span class="function">print</span> <span class="string">"t = "</span> + <span class="function">getTemperature</span>()',
      },
    ],
  },
  {
    name: 'if / elif / else',
    description: 'if_description',
    examples: [
      {
        name: 'if_example_name_if',
        description: 'if_example_description_if',
        code: '<span class="keyword">if</span> x > <span class="number">10</span>:<br>&#09;<span class="comment"># code to execute when the condition is true</span>',
      },
      {
        name: 'if_example_name_if_elif_else',
        description: 'if_example_description_if_elif_else',
        code: '<span class="keyword">if</span> x > <span class="number">10</span>:<br>&#09;<span class="comment"># code to execute when the condition is true</span><br><span class="keyword">elif</span> x > <span class="number">5</span>:<br>&#09;<span class="comment"># code to execute when the condition is true</span>',
      },
      {
        name: 'if_example_name_if_else',
        description: 'if_example_description_if_else',
        code: '<span class="keyword">if</span> x > <span class="number">10</span>:<br>&#09;<span class="comment"># code to execute when the condition is true</span><br><span class="keyword">else</span>:<br>&#09;<span class="comment"># code to execute if condition is false</span>',
      },
    ],
  },
  {
    name: 'while',
    description: 'while_description',
    examples: [
      {
        name: null,
        description: null,
        code: '<span class="keyword">while</span> a < <span class="number">3</span>:<br>&#09;<span class="comment"># code to execute in every iteration</span>',
      },
    ],
  },
  {
    name: 'repeat',
    description: 'repeat_description',
    examples: [
      {
        name: null,
        description: null,
        code: '<span class="keyword">repeat</span> <span class="number">10</span>:<br> &#09;<span class="comment"># code that will be executed 10 times</span>',
      },
    ],
  },
  {
    name: 'for',
    description: 'for_description',
    examples: [
      {
        name: null,
        description: null,
        code: '<span class="keyword">for</span> i <span class="keyword">in</span> [<span class="number">1</span>, <span class="number">2</span>, <span class="number">3</span>]:<br>&#09;<span class="comment"># code with i = 1, i = 2 and i = 3</span>',
      },
    ],
  },
  {
    name: 'break / continue',
    description: 'break_continue_description',
    examples: [
      {
        name: null,
        description: null,
        code: '<span class="keyword">for</span> i <span class="keyword">in</span> <span class="number">5</span>:<br>&#09;<span class="keyword">if</span> i == <span class="number">2</span>:<br>&#09;&#09;continue<br>&#09;<span class="keyword">elif</span> i >= <span class="number">3</span>:<br>&#09;&#09;break',
      },
    ],
  },
  {
    name: '++ / --',
    description: 'add_sub_description',
    examples: [
      {
        name: null,
        description: null,
        code: 'i++ <span class="comment"># i = i + 1</span><br>i-- <span class="comment"># i = i - 1</span>',
      },
    ],
  },
  {
    name: '== / != / < / > / && / ||',
    description: 'comp_op_description',
    examples: [
      {
        name: 'comp_op_example_name_equ',
        description: 'comp_op_example_description_equ',
        code: '<span class="keyword">if</span> left == right:',
      },
      {
        name: 'comp_op_example_name_unequ',
        description: 'comp_op_example_description_unequ',
        code: '<span class="keyword">if</span> left != right:',
      },
      {
        name: 'comp_op_example_name_smaller',
        description: 'comp_op_example_description_smaller',
        code: '<span class="keyword">if</span> left < right:',
      },
      {
        name: 'comp_op_example_name_bigger',
        description: 'comp_op_example_description_bigger',
        code: '<span class="keyword">if</span> left > right:',
      },
      {
        name: 'comp_op_example_name_and',
        description: 'comp_op_example_description_and',
        code: '<span class="keyword">if</span> left and right:',
      },
      {
        name: 'comp_op_example_name_or',
        description: 'comp_op_example_description_or',
        code: '<span class="keyword">if</span> left or right:',
      },
    ],
  },
  {
    name: 'not',
    description: 'not_description',
    examples: [
      { name: null, description: null, code: '<span class="keyword">if</span> a == not b:' },
    ],
  },
  {
    name: 'functions()',
    description: 'function_description',
    examples: [
      {
        name: 'function_example_name_no_params',
        description: 'function_example_description_no_params',
        code: '<span class="keyword">def</span> <span class="function">myFunction</span>():<br>&#09;<span class="comment"># my function body</span>',
      },
      {
        name: 'function_example_name_params',
        description: 'function_example_description_params',
        code: '<span class="keyword">def</span> <span class="function">addition</span>(p1, p2):<br>&#09;<span class="keyword">return</span> p1 + p2<br><br>r = <span class="function">addition</span>(47, 77)',
      },
      {
        name: 'function_example_name_by_ref',
        description: 'function_example_description_by_ref',
        code: '<span class="keyword">def</span> <span class="function">sum</span>(list*):<br>&#09;r = <span class="number">0</span><br>&#09;<span class="keyword">for</span> x <span class="keyword">in</span> list:<br>&#09;&#09;r = r + x<br>&#09;<span class="keyword">return</span> r',
      },
    ],
  },
  {
    name: '0b / 0x',
    description: 'bit_hex_description',
    examples: [
      {
        name: null,
        description: null,
        code: 'a = <span class="number">0b0101</span>  <span class="comment"># bin(5)</span><br>b = <span class="number">0x3F</span>    <span class="comment"># hex(63)</span>',
      },
    ],
  },
  {
    name: '<< / >> / & / | / ^',
    description: 'bit_op_description',
    examples: [
      {
        name: 'bit_op_example_name_shift_l',
        description: 'bit_op_example_description_shift_l',
        code: 'a = <span class="number">0b0101</span><br>r = <span class="number">0b1010</span>  <span class="comment"># r = a << 1</span>',
      },
      {
        name: 'bit_op_example_name_shift_r',
        description: 'bit_op_example_description_shift_r',
        code: 'a = <span class="number">0b0101</span><br>r = <span class="number">0b0010</span>  <span class="comment"># r = a >> 1</span><br><br>b = <span class="number">0b1010</span><br>r = <span class="number">0b1101</span>  <span class="comment"># r = b >> 1</span>',
      },
      {
        name: 'bit_op_example_name_and',
        description: 'bit_op_example_description_and',
        code: 'a = <span class="number">0b0101</span><br>b = <span class="number">0b0011</span><br>r = <span class="number">0b0001</span>  <span class="comment"># r = a & b</span>',
      },
      {
        name: 'bit_op_example_name_or',
        description: 'bit_op_example_description_or',
        code: 'a = <span class="number">0b0101</span><br>b = <span class="number">0b0011</span><br>r = <span class="number">0b0111</span>  <span class="comment"># r = a | b</span>',
      },
      {
        name: 'bit_op_example_name_xor',
        description: 'bit_op_example_description_xor',
        code: 'a = <span class="number">0b0101</span><br>b = <span class="number">0b0011</span><br>r = <span class="number">0b1001</span>  <span class="comment"># r = a ^ b</span>',
      },
    ],
  },
];
