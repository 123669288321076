import React from 'react';
import { useTranslation } from 'react-i18next';

/* Components */

/* Store */

/* Styles */
import './Constant.css';

function Constant({ constantData }) {
  const { t } = useTranslation();

  if (constantData.parentCategory) {
    return (
      <div className="constant_wrapper">
        <div className="constant_description">
          {t('constant:' + constantData.parentCategoryDescription)}
        </div>

        <div className="constant_wrapper">
          <div className="constant">
            <p className="name" title={t('constant:' + constantData.description)}>
              {constantData.name}
            </p>
          </div>
        </div>

        {constantData.examples && (
          <div className="constant_example_wrapper">
            <div className="constant_example_title">
              {constantData.examples.length > 1 ? 'Examples:' : 'Example:'}
            </div>
            {constantData.examples.map((example, i) => (
              <div className="constant_example" key={i}>
                <p>{example}</p>
                <button
                  aria-label="Copy example"
                  className="copy_example"
                  onClick={() => {
                    var dummy = document.createElement('textarea');
                    document.body.appendChild(dummy);
                    dummy.value = example;
                    dummy.select();
                    document.execCommand('copy');
                    document.body.removeChild(dummy);
                  }}
                >
                  Copy
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="constant_wrapper">
        <div className="constant_description">{t('constant:' + constantData.description)}</div>

        <div className="constant_wrapper">
          {constantData.constants.map((item, i) => (
            <div className="constant" key={i}>
              <p className="name" title={t('constant:' + item.description)}>
                {item.name}
              </p>
            </div>
          ))}
        </div>

        {constantData.examples && (
          <div className="constant_example_wrapper">
            <div className="constant_example_title">
              {constantData.examples.length > 1 ? 'Examples:' : 'Example:'}
            </div>
            {constantData.examples.map((example, i) => (
              <div className="constant_example" key={i}>
                <p>{example}</p>
                <button
                  aria-label="Copy example"
                  className="copy_example"
                  onClick={() => {
                    var dummy = document.createElement('textarea');
                    document.body.appendChild(dummy);
                    dummy.value = example;
                    dummy.select();
                    document.execCommand('copy');
                    document.body.removeChild(dummy);
                  }}
                >
                  Copy
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default React.memo(Constant);
