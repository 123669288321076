/**
 * @returns {number} 0 if v1 = v2; -1 if v1 < v2; 1 if v1 > v2
 */
export function compareVersions(v1, v2) {
  const isPositiveInteger = x => /^\d+$/.test(parseInt(x));
  v1 = v1.trim();
  const v1parts = v1.split('.');
  const v2parts = v2.split('.');

  function validateParts(parts) {
    for (let i = 0; i < parts.length; ++i) {
      if (!isPositiveInteger(parts[i])) {
        return false;
      }
    }
    return true;
  }

  if (!validateParts(v1parts) || !validateParts(v2parts)) {
    return NaN;
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }
    if (v1parts[i] === v2parts[i]) {
      continue;
    }
    if (parseInt(v1parts[i]) > parseInt(v2parts[i])) {
      return 1;
    }
    return -1;
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

export function getBrowserInfo() {
  /*eslint-disable no-useless-escape*/
  var N = navigator.appName,
    ua = navigator.userAgent,
    tem;
  var re = ua.match(/(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i);
  if (re && (tem = ua.match(/version\/([\.\d]+)/i)) != null) {
    re[2] = tem[1];
  }
  re = re ? [re[1], re[2]] : [N, navigator.appVersion, '-?'];
  return { browser: re[0], version: parseFloat(re[1]) };
}

export function compareArrays(arr1, arr2) {
  /* Check if the arrays are the same length */
  if (arr1.length !== arr2.length) return false;

  /* Check if all items match */
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}
