import React from 'react';
import { useTranslation } from 'react-i18next';

/* Components */

/* Store */

/* Styles */
import './Function.css';

function Function({ functionData }) {
  const { t } = useTranslation();
  return (
    <div className="function_wrapper">
      <div className="function_description">{t('function:' + functionData.description)}</div>
      {(functionData.returnValue || functionData.parameter) && (
        <div className="function_composition">
          {functionData.returnValue && (
            <div className="return_value">{functionData.returnValue}</div>
          )}
          <div className="name">{functionData.name}</div>
          <div className="parenthesis">{'('}</div>

          {functionData.parameter && (
            <div className="parameter_wrapper">
              {functionData.parameter.map((item, i) => (
                <div className="parameter" key={i}>
                  <div className="type">{item.type}</div>
                  <div className="name" title={t('function:' + item.description)}>
                    {item.name}
                  </div>
                  {i + 1 < functionData.parameter.length && <div className="colon">{','}</div>}
                </div>
              ))}

              <div className="parenthesis">{')'}</div>
            </div>
          )}
          {!functionData.parameter && <div className="parenthesis">{')'}</div>}
        </div>
      )}
      {functionData.examples && (
        <div className="function_example_wrapper">
          <div className="function_example_title">
            {functionData.examples.length > 1 ? 'Examples:' : 'Example:'}
          </div>
          {functionData.examples.map((example, i) => (
            <div className="function_example" key={i}>
              <p>{example}</p>
              <button
                aria-label="Copy example"
                className="copy_example"
                onClick={() => {
                  var dummy = document.createElement('textarea');
                  document.body.appendChild(dummy);
                  dummy.value = example;
                  dummy.select();
                  document.execCommand('copy');
                  document.body.removeChild(dummy);
                }}
              >
                Copy
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(Function);
