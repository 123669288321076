/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable max-len */
export default {
  hello: 'Hello',

  /* Top Banner */
  top_banner_text:
    'This Oxoscript version is obsolete and will not be developed further. The new version 2.0 requires an Oxocard Science, Galaxy or Artwork and is no longer executable on the Oxocard Blockly. More information about the new Oxoscript version can be found here:',

  /* Top Bar */
  top_bar_button_new: 'New',
  top_bar_button_open: 'Open',
  top_bar_button_save: 'Save',
  top_bar_button_share: 'Share',
  top_bar_new_script_name: 'My new script',

  /* Info Bar */
  info_bar_connect: 'Connect your Oxocard now and download your first program on the card',
  info_bar_compiling: 'The program will be checked and compiled. You can continue to work',
  info_bar_error: 'Error on line',
  info_bar_ready: 'The program was compiled and is now ready to be loaded on the card',

  /* Terminal */
  terminal_header_output: 'Output',
  terminal_header_clear: 'Clear',

  /* Menu - First Steps */
  menu_first_steps_title: 'First Steps',
  menu_first_steps_text: 'Here you can find a Getting Started Guide',
  menu_first_steps_installation: 'Installation Guide',
  menu_first_steps_key_commands: '',
  menu_first_steps_book: 'Oxoscript Manual',

  /* Menu - Simulator */
  menu_simulator_title: 'Simulator',
  menu_simulator_unknown_functions: 'is not supported by the simulator',

  /* Menu - My Oxocard */
  menu_my_oxocard_title: 'My Oxocard',
  menu_my_oxocard_no_card_text: "You haven't connected a card",
  menu_my_oxocard_no_card_button: 'Link Oxocard',
  menu_my_oxocard_link_other_card_button: 'Link another Oxocard',
  menu_my_oxocard_reset_card_button: 'Reset to inital State',
  menu_my_oxocard_switch_to_blockly: 'Switch to Blockly',

  /* Menu - Documentation */
  menu_documentation_title: 'Documentation',
  menu_documentation_search: 'Search Documentation',
  menu_documentation_functions: 'Functions',
  menu_documentation_constants: 'Constants',

  /* Menu - Examples */
  menu_examples_title: 'Examples',

  /* Menu - Worksheets */
  menu_worksheets_title: 'Worksheets',
  menu_worksheets_text: 'The worksheets are still being developed and will be listed here.',
  menu_worksheets_a01: 'W01 The smart light',

  /* Menu - Data */
  menu_data_title: 'Data',
  menu_data_text: 'Here are the variables you have sent to the browser with your oxocard.',
  menu_data_note_header: 'Note:',
  menu_data_note_text: 'Only a maximum of 100 values per variable can be stored and displayed',
  menu_data_public_link: 'Share link',
  menu_data_latest: 'Latest',

  /* Menu - Settings */
  menu_Settings_title: 'Settings',
  menu_Settings_Language_title: 'Preferred Language:',
  menu_Settings_Language_option_en: 'English',
  menu_Settings_Language_option_de: 'Deutsch',
  menu_Settings_Language_option_fr: 'Français',
  menu_Settings_theme_title: 'Change editor theme:',
  menu_Settings_theme_option_light: 'Light',
  menu_Settings_theme_option_dark: 'Dark',
  menu_Settings_editor_font_size: 'Font size of the editor:',

  /* Menu - Feedback */
  menu_feedback_title: 'Feedback',
  menu_feedback_text: 'Your feedback is important to us!',
  menu_feedback_link: 'Send us an email',
  menu_feedback_imprintLink: 'Imprint',

  /* Popup - Unsaved changed */
  popup_unsaved_changes_title: 'You have unsaved changes!',
  popup_unsaved_changes_body:
    'You are going to lose your changes if you continue. Do you really want to continue?',
  popup_unsaved_changes_cancel: 'No!',
  popup_unsaved_changes_continue: 'Continue',

  /* Popup - Shared script */
  popup_shared_script_title: 'Your link has been created!',
  popup_shared_script_body: 'Share it with your friends or save it as a bookmark.',
  popup_shared_script_cancel: 'Close',
  popup_shared_script_email: 'Share via email',
  popup_shared_script_email_subject: 'Have a look at my script',
  popup_shared_script_copy: 'Copy link',
  popup_shared_script_copied: 'Link was copied!',

  /* Popup - Switch to Blockly */
  popup_switch_to_blockly_title: 'Do you prefer programming with Blockly?',
  popup_switch_to_blockly_body:
    'The link with the editor will be disbanded, if you switch to Blockly. You can always switch back.',
  popup_switch_to_blockly_cancel: 'Stay in the Editor',
  popup_switch_to_blockly_continue: 'Go to Blockly',

  /* Popup - Really reset */
  popup_really_reset_title: 'Do you really want to reset your Oxocard?',
  popup_really_reset_body:
    'If you press reset, all data and the script on the Oxocard will be deleted',
  popup_really_reset_cancel: 'No!',
  popup_really_reset_continue: 'Reset',

  /* Popup - Really clear data */
  popup_really_clear_data_title: 'Do you really want to delete this data?',
  popup_really_clear_data_body:
    'If you click on Yes, all data of this variable are irrevocably deleted.',
  popup_really_clear_data_cancel: 'No',
  popup_really_clear_data_continue: 'Yes',

  /* Popup - Really delete variable */
  popup_really_delete_variable_title: 'No data available. Do you want to delete this variable?',
  popup_really_delete_variable_body: 'If you click on Yes, this variable is irrevocably deleted.',
  popup_really_delete_variable_cancel: 'No',
  popup_really_delete_variable_continue: 'Yes',
};
