import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { i18n as corei18n } from 'OxocardCore';

/* Components */

/* Store */
import { saveSettings } from '../../../store/ducks/settings';

/* Styles */
import styles from './Settings.module.css';

const languages = [
  { value: 'de', title: 'menu_Settings_Language_option_de' },
  { value: 'en', title: 'menu_Settings_Language_option_en' },
  { value: 'fr', title: 'menu_Settings_Language_option_fr' },
];
const themes = [
  { value: 'dark', title: 'menu_Settings_theme_option_dark' },
  { value: 'light', title: 'menu_Settings_theme_option_light' },
];

class Settings extends React.PureComponent {
  setSettings = ({ lang, showReleaseSteps, editorTheme, editorFontSize, showHelp }) => {
    if (lang) {
      this.props.i18n.changeLanguage(lang);
      corei18n.translator.setLanguage(lang);
      corei18n.translator.updateUI();
    }

    if (editorFontSize) {
      editorFontSize = parseInt(editorFontSize);
    }

    this.props.saveSettings({
      lang,
      showReleaseSteps,
      editorTheme,
      editorFontSize,
      showHelp,
    });
  };

  set = (caller, e) => {
    this.setSettings({ [caller]: e.target.value });
  };

  render() {
    const { i18n, lang, editorTheme, editorFontSize } = this.props;
    return (
      <div className={styles.settings}>
        <label>{i18n.t('menu_Settings_Language_title')}</label>
        <select aria-label="Select language" onChange={e => this.set('lang', e)} value={lang}>
          {languages.map(lng => (
            <option key={lng.value} value={lng.value}>
              {i18n.t(lng.title)}
            </option>
          ))}
        </select>

        <label>{i18n.t('menu_Settings_theme_title')}</label>
        <select
          aria-label="Select theme"
          onChange={e => this.set('editorTheme', e)}
          value={editorTheme}
        >
          {themes.map(theme => (
            <option key={theme.value} value={theme.value}>
              {i18n.t(theme.title)}
            </option>
          ))}
        </select>

        <label>{i18n.t('menu_Settings_editor_font_size')}</label>
        <input
          aria-label="Editor Font size input"
          className={styles.fontSizeSelector}
          type="number"
          name="fontSize"
          min="8"
          max="48"
          value={editorFontSize}
          onChange={e => this.set('editorFontSize', e)}
        ></input>
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return {
    lang: settings.lang,
    editorTheme: settings.editorTheme,
    editorFontSize: settings.editorFontSize,
    showHelp: settings.showHelp,
  };
}

const mapDispatchToProps = { saveSettings };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Settings));
