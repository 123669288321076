/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* ---------- Category Utility ---------- */
  category_utility: 'Utility',

  /* --- size --- */
  size_description: 'Gets the size of a list',
  size_param_array: 'List to get the length of',

  /* --- remove --- */
  remove_description: 'Removes an item from a list at the given position',
  remove_param_array: 'List to remove an item from',
  remove_param_pos: 'Position of the item to remove',

  /* --- findValue --- */
  find_value_description:
    'Searches for a value in the list. Gets the index of the value if found, -1 otherwise',
  find_value_param_array: 'List to search in',
  find_value_param_value: 'Value to search for in the list',

  /* --- findRange --- */
  find_range_description:
    'Searches for the content of a list in another list. Gets the start index of the value if found, -1 otherwise',
  find_range_param_array: 'List to search in',
  find_range_param_search_range: 'List to search for in the list',

  /* --- min --- */
  min_description: 'Gets the minimal value of a list',
  min_param_array: 'List to get the min value from',

  /* --- max --- */
  max_description: 'Gets the maximal value of a list',
  max_param_array: 'List to get the max value from',

  /* --- avg --- */
  avg_description: 'Gets the average value of a list',
  avg_param_array: 'List to get the average value from',

  /* --- sin --- */
  sin_description:
    'Gets the sine of a given angle multiplied with a factor (no floating points are needed)',
  sin_param_degree: 'Angle in degree',
  sin_param_factor: 'Factor of the result',

  /* --- cos --- */
  cos_description:
    'Gets the cosine of a given angle multiplied with a factor (no floating points are needed)',
  cos_param_degree: 'Angle in degree',
  cos_param_factor: 'Factor of the result',

  /* --- pow --- */
  pow_description: 'Gets the power of a given base by the given exponent',
  pow_param_base: 'Base to calculate the power',
  pow_param_exponent: 'The exponent',

  /* --- map --- */
  map_description: 'Re-maps a number from one range to another range',
  map_param_value: 'The value to mutate',
  map_param_a1: 'Range 1 start',
  map_param_a2: 'Range 1 end',
  map_param_b1: 'Range 2 start',
  map_param_b2: 'Range 2 end',

  /* --- abs --- */
  abs_description: 'Gets the absolute value of a given value',
  abs_param_value: 'Value to get the absolute value from',

  /* ---------- Category Logic ---------- */
  category_logic: 'Logic',

  /* --- isButtonPressed --- */
  is_button_pressed_description:
    'Returns true if a button is pressed, false otherwise.\nPossible buttons:\n\tBTN_L1\n\tBTN_L2\n\tBTN_L3\n\tBTN_R1\n\tBTN_R2\n\tBTN_R3',
  is_button_pressed_param_button: 'Button to check',

  /* --- waitForButtonPressed --- */
  wait_for_button_pressed_description:
    'Waits until the given button is pressed.\nPossible buttons:\n\tBTN_L1\n\tBTN_L2\n\tBTN_L3\n\tBTN_R1\n\tBTN_R2\n\tBTN_R3',
  wait_for_button_pressed_param_button: 'Button to check',

  /* ---------- Category Variables ---------- */
  category_variables: 'Variables',

  /* --- random --- */
  random_description: 'Gets a random value between the given start and end values',
  random_param_start: 'Minimal value',
  random_param_end: 'Maximal value',

  /* --- hasPersistentVariable --- */
  has_pers_var_description:
    'Returns true if a value with the given key is stored in the persistent memory',
  has_pers_var_param_key: 'Key to search for',

  /* --- savePersistentVariable --- */
  save_pers_var_description: 'Saves a value in the persistent memory under the given key',
  save_pers_var_param_key: 'Key to store the value in',
  save_pers_var_param_value: 'The value to store',

  /* --- loadPersistentVariable --- */
  load_pers_var_description: 'Gets the value stored with the given key in the persistent memory',
  load_pers_var_param_key: 'Key to get the value from',

  /* --- deletePersistentVariable --- */
  delete_pers_var_description: 'Deletes a value with the given key in the persistent memory',
  delete_pers_var_param_key: 'Key to delete',

  /* ---------- Category Oxocard ---------- */
  category_oxocard: 'Oxocard',

  /* --- reboot --- */
  reboot_description: 'Restarts the Oxocard',

  /* --- turnOff --- */
  turn_off_description: 'Turns off the Oxocard',

  /* --- turnOffWakeUpButton --- */
  turn_off_wake_up_button_description:
    'Turns the Oxocard off and defines buttons as a wake up reason.',
  turn_off_wake_up_button_param_l1: 'True if button L1 is wake up reason',
  turn_off_wake_up_button_param_l2: 'True if button L2 is wake up reason',
  turn_off_wake_up_button_param_l3: 'True if button L3 is wake up reason',
  turn_off_wake_up_button_param_r1: 'True if button R1 is wake up reason',
  turn_off_wake_up_button_param_r2: 'True if button R2 is wake up reason',
  turn_off_wake_up_button_param_r3: 'True if button R3 is wake up reason',

  /* --- configureAutoTurnOff --- */
  configure_auto_turn_off_description:
    'Sets the time in seconds after the Oxocard should turn off, if there is no user input.',
  configure_auto_turn_off_param_seconds: 'Turn off after seconds',

  /* --- enableAutoTurnOff --- */
  enable_auto_turn_off_description: 'Enables the auto turn off function (default time is 120s)',

  /* --- disableAutoTurnOff --- */
  disable_auto_turn_off_description: 'Disables the auto turn off function',

  /* --- sleep --- */
  sleep_description: 'Puts the Oxocard in sleep mode for the given amount of seconds',
  sleep_param_seconds: 'Seconds to sleep',

  /* --- getWakeUpCause --- */
  get_wake_up_cause_description:
    'This function gets the wake up cause\n\tBTN_L1\n\tBTN_L2\n\tBTN_L3\n\tBTN_R1\n\tBTN_R2\n\tBTN_R3\n\tRESET\n\tTIMER',

  /* --- getBatteryVoltage --- */
  get_battery_voltage_description: 'Gets the battery voltage in millivolts',

  /* --- isFullyCharged --- */
  is_fully_charged_description: 'Returns true if the battery is fully charged',

  /* --- isUsbPluggedIn --- */
  is_usb_plugged_in_description: 'Returns true if the USB is plugged in',

  /* --- getTemperature --- */
  get_temperature_description: 'Gets the temperature in degree Celsius',

  /* ---------- Category Matrix ---------- */
  category_matrix: 'Matrix',

  /* --- setColor --- */
  set_color_description: 'Sets the color for all subsequent drawing functions',
  set_color_param_r: 'Red value 0 - 255',
  set_color_param_g: 'Green value 0 - 255',
  set_color_param_b: 'Blue value 0 - 255',

  /* --- setRandomColor --- */
  set_random_color_description: 'Sets a random color',

  /* --- setHueColor --- */
  set_hue_color_description: 'Sets the color for all subsequent drawing functions',
  set_hue_color_param_hue: 'Hue color 0 - 360°',

  /* --- enableAutoUpdate --- */
  enable_auto_update_description:
    'Enables the auto update. The card will draw automatically to the display',

  /* --- disableAutoUpdate --- */
  disable_auto_update_description:
    'Disables the auto update. An update() call is required to draw to the display',

  /* --- isAutoUpdate --- */
  is_auto_update_description: 'Returns true, if the auto update is enabled, false otherwise',

  /* --- update --- */
  update_description: 'Updates the display',

  /* --- fillScreen --- */
  fill_screen_description: 'Fills the whole display with the currently selected color',

  /* --- clearScreen --- */
  clear_screen_description: 'Clears the display',

  /* --- setPixel --- */
  set_pixel_description:
    'Sets a pixel in the frame buffer without drawing it to the matrix. To show it on the screen, the function update() is used',
  set_pixel_param_x: 'X position of the pixel [0 - 7]',
  set_pixel_param_y: 'Y position of the pixel [0 - 7]',
  set_pixel_pixel_param_r: 'Red value [0 - 255]',
  set_pixel_pixel_param_g: 'Green value [0 - 255]',
  set_pixel_pixel_param_b: 'Blue value [0 - 255]',

  /* --- drawPixel --- */
  draw_pixel_description: 'Draws a pixel with the currently selected color at the given position',
  pixel_param_x: 'X position of the pixel [0 - 7]',
  pixel_param_y: 'Y position of the pixel [0 - 7]',

  /* --- clearPixel --- */
  clear_pixel_description: 'Clears a pixel at the given position',

  /* --- readPixel --- */
  read_pixel_description: 'Reads the color of a pixel at the given position',
  read_pixel_param_color: 'Color value to read (RED, GREEN or BLUE)',

  /* --- isPixelSet --- */
  is_pixel_set_description: 'Returns true if the pixel at the given position is set',

  /* --- fill --- */
  fill_description: 'Fill all following geometric forms',

  /* --- noFill --- */
  no_fill_description: 'Do not fill the following geometric forms',

  /* --- drawLine --- */
  draw_line_description: 'Draws a line from x1:y1 to x2:y2',
  draw_line_param_x1: 'X position of the starting coordinate',
  draw_line_param_y1: 'Y position of the starting coordinate',
  draw_line_param_x2: 'X position of the end coordinate',
  draw_line_param_y2: 'Y position of the end coordinate',

  /* --- drawRectangle --- */
  draw_rectangle_description:
    'Draws a rectangle with the top left corner at position x:y and the width and height of w and h',
  draw_rectangle_param_x: 'X position of the top left corner',
  draw_rectangle_param_y: 'Y position of the top left corner',
  draw_rectangle_param_w: 'Width of the rectangle',
  draw_rectangle_param_h: 'Height of the rectangle',

  /* --- drawTriangle --- */
  draw_triangle_description: 'Draws a triangle between the points x1:y1, x2:y2 and x3:y3',
  draw_triangle_param_x1: 'X position of the first point',
  draw_triangle_param_y1: 'Y position of the first point',
  draw_triangle_param_x2: 'X position of the second point',
  draw_triangle_param_y2: 'Y position of the second point',
  draw_triangle_param_x3: 'X position of the third point',
  draw_triangle_param_y3: 'Y position of the third point',

  /* --- drawCircle --- */
  draw_circle_description: 'Draws a circle with the center at x:y and a radius of r',
  draw_circle_param_x: 'X position of the center',
  draw_circle_param_y: 'Y position of the center',
  draw_circle_param_r: 'Radius of the circle',

  /* --- drawNumber --- */
  draw_number_description: 'Draws a number on the display',
  draw_number_param_num: 'Number to draw',

  /* --- drawChar --- */
  draw_char_description: 'Draws a character on the display at the given position',
  draw_char_param_c: 'Character to draw',
  draw_char_param_x: 'X position of the top left corner',
  draw_char_param_y: 'Y position of the top left corner',
  draw_char_param_is_big_font: 'If set to true the big font is used',

  /* --- drawText --- */
  draw_text_description: 'Draws text to the display',
  draw_text_param_text: 'Buffer with the text to draw',
  draw_text_param_is_big_font: 'If set to true the big font is used',

  /* --- drawImage --- */
  draw_image_description:
    'Draws an image to the display. The image is a list of binary values where each bit represents one LED',
  draw_image_param_image: 'Image to draw',
  draw_image_param_x: 'X position of the top left corner of the image',
  draw_image_param_y: 'Y position of the top left corner of the image',

  /* --- drawImage --- */
  draw_image32_description:
    'Draws an image to the display. The image is a list of 32 x 16 bit values, where each value represents two LEDs (Pixel)',
  draw_image32_param_image: 'Image to draw',
  draw_image32_param_x: 'X position of the top left corner of the image',
  draw_image32_param_y: 'Y position of the top left corner of the image',

  /* --- setActiveFrameBuffer --- */
  set_active_frame_buffer_description: 'Sets the currently active frame buffer (1, 2 or 3)',
  set_active_frame_buffer_param_num: 'Frame buffer number',

  /* --- copyFrameBufferContent --- */
  copy_frame_buffer_content_description: 'Copies the content from one frame buffer to another',
  copy_frame_buffer_content_param_from_buffer: 'Source frame buffer number to copy from',
  copy_frame_buffer_content_param_to_buffer: 'Target frame buffer number to paste the content in',

  /* ---------- Category Time ---------- */
  category_time: 'Time',

  /* --- delay --- */
  delay_description: 'Waits for the given time in milliseconds',
  delay_param_ms: 'Milliseconds to wait',

  /* --- msSinceStart --- */
  ms_since_start_description: 'Gets the number of milliseconds since program start',

  /* --- useTimeZone --- */
  use_time_zone_description: 'Sets the time zone',
  use_time_zone_param_id: 'ID of the time zone',

  /* --- setTime --- */
  set_time_description: 'Sets the time of the Oxocard',
  set_time_param_h: 'Hours to set',
  set_time_param_m: 'Minutes to set',
  set_time_param_s: 'Seconds to set',

  /* --- getTime --- */
  get_time_description: 'Gets the requested time unit:\n\tSECONDS\n\tMINUTES\n\tHOURS',
  get_time_param_unit: 'Requested time unit (SECONDS, MINUTES or HOURS)',

  /* --- setDate --- */
  set_date_description: 'Sets the date of the Oxocard',
  set_date_param_d: 'Day to set',
  set_date_param_m: 'Month to set',
  set_date_param_y: 'Year to set',

  /* --- getDate --- */
  get_date_description: 'Gets the requested time unit:\n\tWEEKDAY\n\tDAY\n\tMONTH\n\tYEAR',
  get_date_param_unit: 'Requested time unit (WEEKDAY, DAY, MONTH or YEAR)',

  /* ---------- Category Weather ---------- */
  category_weather: 'Weather',

  /* --- getWeather --- */
  get_weather_description: 'Gets the weather for a specific location from the Internet.',
  get_weather_param_place_id: 'ID of the location',

  /* --- readWeatherParam --- */
  read_weather_param_description:
    'Gets the requested weather parameter:\n\tTEMPERATURE\n\tDEW_POINT\n\tHUMIDITY\n\tWIND\n\tPRECIPITATION',
  read_weather_param_param_param_id: 'ID of the parameter to read',

  /* --- drawCityName --- */
  draw_city_name_description: 'Draws the selected city name to the display',
  draw_city_name_param_is_big_font: 'True if all letters should be uppercase',

  /* --- drawForecastIcon --- */
  draw_forecast_icon_description: 'Draws the forecast icon to the display',

  /* ---------- Category Accelerometer ---------- */
  category_accelerometer: 'Accelerometer',

  /* --- getAcceleration --- */
  get_acceleration_description: 'Gets the acceleration value of the given axis',
  get_acceleration_param_axis: 'AXIS_X, AXIS_Y or AXIS_Z',

  /* --- getNormalizedValue --- */
  get_normalized_value_description: 'Gets the normalized vector of the accelerometer',

  /* --- setAccelerometerCursor --- */
  set_accelerometer_cursor_description: 'Sets the cursor',
  set_accelerometer_cursor_param_x: 'X coordinate of the cursor',
  set_accelerometer_cursor_param_y: 'Y coordinate of the cursor',

  /* --- getAccelerometerCursor --- */
  get_accelerometer_cursor_description: 'Reads the current position of the acceleration cursor',
  get_accelerometer_cursor_param_axis: 'Axis (AXIS_X or AXIS_Y) of the cursor data to read',

  /* --- getOrientation --- */
  get_orientation_description: 'Gets the latest orientation of the card (ORIENTATION_XY).',

  /* --- isOrientation --- */
  is_orientation_description: 'Returns true if the card is in the given orientation',
  is_orientation_param_direction: 'Direction to check (ORIENTATION_XY)',

  /* --- setReferenceVector --- */
  set_reference_vector_description: 'Sets the reference vector to the latest position of the card.',

  /* --- getAngle --- */
  get_angle_description:
    'Gets the latest angle to the reference vector. The default vector is set when the Oxocard is flat on the table.',

  /* --- configureAccelerometer --- */
  config_acc_description: 'Configures the data rate and range of the accelerometer',
  config_acc_param_data_rate: 'Data rate: POWER_DOWN or one of NORMAL_xy_HZ',
  config_acc_param_range: 'Range: One of MAX_xyG',

  /* ---------- Category Audio ---------- */
  category_audio: 'Audio',
  /* --- playTone --- */
  play_tone_description: 'Plays a tone with the given frequency',
  play_tone_param_hz: 'Frequency in Hz of the tone',

  /* --- playToneFor --- */
  play_tone_for_description: 'Plays a tone for a given time',
  play_tone_for_param_hz: 'Frequency in Hz of the tone',
  play_tone_for_param_ms: 'Duration in milliseconds',

  /* --- noteToMs --- */
  note_to_ms_description: 'Converts note durations to milliseconds',
  note_to_ms_param_note_duration: 'Note duration (NOTE_DUR_XY) to convert',

  /* --- playNote --- */
  play_note_description: 'Plays a tone with the given frequency',
  play_note_param_note: 'Note (NOTE_XY) to play',

  /* --- playNoteFor --- */
  play_note_for_description: 'Plays a note for a given time',
  play_note_for_param_note: 'Note (NOTE_XY) to play',
  play_note_for_param_ms: 'Duration in milliseconds',

  /* --- noTone --- */
  no_tone_description: 'Stops the current playing tone',

  /* --- setWaveForm --- */
  set_wave_form_description:
    'Sets the wave form of the audio signal:\n\tWAVEFORM_SINE\n\tWAVEFORM_SQUARE\n\tWAVEFORM_SAWTOOTH\n\tWAVEFORM_TRIANGLE',
  set_wave_form_param_wave_form: 'Wave form (WAVEFORM_XY)',

  /* --- setOctave --- */
  set_octave_description: 'Sets the octave of the notes',
  set_octave_param_octave: 'Octave (OCTAVE_XY) of the notes',

  /* --- setBPM --- */
  set_bpm_description: 'Sets the BPM (beats per minute)',
  set_bpm_param_bpm: 'BPM for the note duration to use',

  /* --- setToneBlocking --- */
  set_tone_blocking_description: 'Waits after each subsequent tone',
  set_tone_blocking_param_is_blocking: 'true -> wait; false -> do not wait',

  /* --- setVolume --- */
  set_volume_description: 'Sets the volume of the audio output',
  set_volume_param_volume: 'Volume value 0 - 255',

  /* --- getVolume --- */
  get_volume_description: 'Gets the latest used volume',

  /* --- volumeUp --- */
  volume_up_description: 'Increases the volume',

  /* --- volumeDown --- */
  volume_down_description: 'Decreases the volume',

  /* --- playSoundEffect --- */
  play_sound_effect_description: 'Plays a sound effect',
  play_sound_effect_param_effect_num: 'One of SOUND_EFFECT_ effects',
  play_sound_effect_param_loop: 'True if it should loop the sound effect',

  /* ---------- Category Microphone ---------- */
  category_microphone: 'Microphone',

  /* --- record --- */
  record_description: 'Records audio for the given time',
  record_param_ms: 'Record time in milliseconds',

  /* --- playRecorded --- */
  play_recorded_description: 'Plays the recorded audio',
  play_recorded_param_loop: 'True if it should loop the output',

  /* --- waitForLoudNoise --- */
  wait_for_loud_noise_description: 'Waits for a loud noise',

  /* --- startMicrophone --- */
  start_microphone_description: 'Activates the microphone',

  /* --- readDecibel --- */
  read_decibel_description: 'Gets the latest noise level of the microphone',

  /* --- readFrequency --- */
  read_frequency_description: 'Gets the frequency of the latest highest amplitude',

  /* --- stopMicrophone --- */
  stop_microphone_description: 'Deactivates the microphone',

  /* ---------- Category Internet ---------- */
  category_internet: 'Internet',

  /* --- connect --- */
  connect_description: 'Connects the card with given the interface (at the moment only WIFI)',
  connect_param_interface: 'Interface to connect to. E.g. WIFI',

  /* --- disconnect --- */
  disconnect_description: 'Disconnects the current connected interface',

  /* --- startAccessPointMode --- */
  start_access_point_mode_description: 'Sets the Oxocard in the access point mode',

  /* --- startPairingMode --- */
  start_pairing_mode_description: 'Starts the pairing process',

  /* --- hasNewScript --- */
  has_new_script_description: 'Returns true if there is a new script ready to be downloaded',

  /* --- downloadScript --- */
  download_script_description: 'Downloads the latest script if available',

  /* ---------- Category Extras ---------- */
  category_extras: 'Extras',

  /* --- setPinMode --- */
  set_pin_mode_description: 'Sets the mode of a pin',
  set_pin_mode_param_pin: 'Pin (IOXY) to set the mode',
  set_pin_mode_param_mode: 'Mode (INPUT, INPUT_PULLUP, INPUT_PULLDOWN or OUTPUT) of the pin',

  /* --- setPin --- */
  set_pin_description: 'Sets the state of a pin',
  set_pin_param_pin: 'Pin (IOXY) to set the state',
  set_pin_param_state: 'State of the pin (HIGH or LOW)',

  /* --- readPin --- */
  read_pin_description: 'Gets the state of the given pin',
  read_pin_param_pin: 'Pin (IOXY) to read',

  /* --- print --- */
  print_description:
    'Prints information to the terminal.\n\nNote: connect(WIFI) has to be called first to be able to send data to the editor',
  print_param_pin: 'Variable, list or string to print',

  /* --- pushValue --- */
  push_value_description: 'Sends a single value to the editor',
  push_value_param_key: 'Name of the value to send',
  push_value_param_value: 'Value to send',

  /* --- pushValues --- */
  push_values_description: 'Sends a list of values to the editor',
  push_values_param_key: 'Name of the value to send',
  push_values_param_values: 'Values to send',

  /* --- getValues --- */
  get_values_description: 'Gets the values with the given key',
  get_values_param_key: 'Name of the values to get',
  get_values_param_values: 'Buffer to write the values in',

  /* --- clearValues --- */
  clear_values_description: 'Deletes all values of the given key',
  clear_values_param_key: 'Name of the values to clear',

  /* --- removeValues --- */
  remove_values_description: 'Deletes all values of the given key and removes the key',
  remove_values_param_key: 'Name of the values to remove',

  /* --- i2cWrite --- */
  i2c_write_description: 'Writes to an I2C device',
  i2c_write_param_slaveAdr: 'Address of the device',
  i2c_write_param_dataAdr: 'Address of the data',
  i2c_write_param_data: 'Data to write',

  /* --- i2cWrite --- */
  i2c_read_description: 'Reads from an I2C device',
  i2c_read_param_slaveAdr: 'Address of the device',
  i2c_read_param_dataAdr: 'Address of the data',
  i2c_read_param_data: 'Buffer to store the data in',
  i2c_read_param_dataLength: 'Number of bytes to read',
};
