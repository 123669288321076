/* eslint-disable import/no-anonymous-default-export */
import editorDe from './editor/de';
import editorEn from './editor/en';
import editorFr from './editor/fr';

import functionDe from './function/de';
import functionEn from './function/en';
import functionFr from './function/fr';

import constantDe from './constant/de';
import constantEn from './constant/en';
import constantFr from './constant/fr';

import syntaxDe from './syntax/de';
import syntaxEn from './syntax/en';
import syntaxFr from './syntax/fr';

import errorCodeDe from './errorCode/de';
import errorCodeEn from './errorCode/en';
import errorCodeFr from './errorCode/fr';

export default {
  de: {
    editor: editorDe,
    function: functionDe,
    constant: constantDe,
    syntax: syntaxDe,
    errorCode: errorCodeDe,
  },
  en: {
    editor: editorEn,
    function: functionEn,
    constant: constantEn,
    syntax: syntaxEn,
    errorCode: errorCodeEn,
  },
  fr: {
    editor: editorFr,
    function: functionFr,
    constant: constantFr,
    syntax: syntaxFr,
    errorCode: errorCodeFr,
  },
};
