import React from 'react';

/* Components */

/* Styles */
import './SubSubCategory.css';

class SubSubCategory extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.theme === nextProps.theme &&
      this.props.name === nextProps.name &&
      this.props.labelValue === nextProps.labelValue &&
      this.props.isOpen === nextProps.isOpen
    ) {
      return false;
    }

    return true;
  }

  renderText = (text, filter) => {
    const index = text.toLowerCase().indexOf(filter.toLowerCase());
    if (!filter.length || index === -1) {
      return <span className="sub_sub_category_header_name">{text}</span>;
    }

    let length = filter.length;
    return [...text].map((char, i) => {
      let className = '';
      if (i >= index && length > 0) {
        className = 'matches';
        length--;
      }
      return (
        <span key={i} className={className}>
          {char}
        </span>
      );
    });
  };

  render() {
    const { children, theme, name, isOpen, onClick, searchString = '' } = this.props;
    let { labelValue = undefined } = this.props;
    if (labelValue === 0) labelValue = undefined;
    return (
      <div
        className={
          isOpen ? `sub_sub_category_wrapper ${theme} open` : `sub_sub_category_wrapper ${theme}`
        }
      >
        <div
          className={
            isOpen ? `sub_sub_category_header ${theme} open` : `sub_sub_category_header ${theme}`
          }
          onClick={onClick}
        >
          <div className="sub_sub_category_header_icon"></div>
          <div className="sub_sub_category_header_name">{this.renderText(name, searchString)}</div>
          {isOpen === false && labelValue && (
            <div className="sub_sub_category_header_label">{labelValue}</div>
          )}
        </div>
        <div className="sub_sub_category_body">{children}</div>
      </div>
    );
  }
}

export default SubSubCategory;
