import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Components */
import MenuCategory from './MenuCategory';
import Start from './Start';
import Simulator from './Simulator';
import MyOxocard from './MyOxocard';
import Docs from './Docs';
import Examples from './Examples';
import Worksheets from './Worksheets';
import Data from './Data';
import Settings from './Settings';
import Feedback from './Feedback';

/* Store */

/* Styles */
import './Menu.css';

const MenuCategories = [
  { key: 'start', title: 'menu_first_steps_title', isNew: false },
  { key: 'simulator', title: 'menu_simulator_title', isNew: true },
  { key: 'doc', title: 'menu_documentation_title', isNew: false },
  { key: 'myOxocard', title: 'menu_my_oxocard_title', isNew: false },
  { key: 'worksheets', title: 'menu_worksheets_title', isNew: false },
  { key: 'examples', title: 'menu_examples_title', isNew: false },
  { key: 'data', title: 'menu_data_title', isNew: false },
  { key: 'settings', title: 'menu_Settings_title', isNew: false },
  { key: 'feedback', title: 'menu_feedback_title', isNew: false },
];

class Menu extends React.Component {
  state = {
    activeIndex: -1,
  };

  renderItem = key => {
    switch (key) {
      case 'start':
        return <Start />;
      case 'simulator':
        return this.state.activeIndex === 1 && <Simulator isOpen={this.state.activeIndex === 1} />;
      case 'myOxocard':
        return <MyOxocard isOpen={this.state.activeIndex === 1} />;
      case 'doc':
        return <Docs />;
      case 'examples':
        return <Examples />;
      case 'worksheets':
        return <Worksheets />;
      case 'data':
        return <Data isOpen={this.state.activeIndex === 5} />;
      case 'settings':
        return <Settings />;
      case 'feedback':
        return <Feedback />;
      default:
        return null;
    }
  };

  render() {
    const { activeIndex } = this.state;
    const { i18n } = this.props;
    return (
      <div className={`menu_wrapper ${this.props.theme}`}>
        {MenuCategories.map((cat, i) => (
          <MenuCategory
            theme={this.props.theme}
            name={i18n.t(cat.title)}
            isNew={cat.isNew}
            key={i}
            isOpen={activeIndex === i}
            onClick={() => {
              this.setState({
                activeIndex: i === activeIndex ? -1 : i,
              });
            }}
          >
            {this.renderItem(cat.key)}
          </MenuCategory>
        ))}
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return {
    theme: settings.editorTheme,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Menu));
