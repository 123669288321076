/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* ---------- Category Utility ---------- */
  category_utility: 'Allgemein',

  /* --- size --- */
  size_description: 'Gibt die Grösse einer Liste zurück',
  size_param_array: 'Liste von der die Grösse ermittelt werden soll',

  /* --- remove --- */
  remove_description: 'Entfernt ein Element von der Liste an der definierten Position',
  remove_param_array: 'Liste von er ein Element entfernt werden soll',
  remove_param_pos: 'Position vom Element das entfernt werden soll',

  /* --- findValue --- */
  find_value_description:
    'Sucht nach einem Element in der Liste und gibt dessen Position zurück, falls es gefunden wird. Ansonsten -1',
  find_value_param_array: 'Liste in der gesucht werden soll',
  find_value_param_value: 'Wert der in der Liste gesucht werden soll',

  /* --- findRange --- */
  find_range_description:
    'Sucht nach einem Bereich in der Liste und gibt dessen Startposition zurück, falls er gefunden wird. Ansonsten -1',
  find_range_param_array: 'Liste in der gesucht werden soll',
  find_range_param_search_range: 'Bereich (Liste) der in der Liste gesucht werden soll',

  /* --- min --- */
  min_description: 'Gibt den kleinsten Wert einer Liste zurück',
  min_param_array: 'Liste in der der kleinste Wert ermittelt werden soll',

  /* --- max --- */
  max_description: 'Gibt den grössten Wert einer Liste zurück',
  max_param_array: 'Liste in der der grösste Wert ermittelt werden soll',

  /* --- avg --- */
  avg_description: 'Gibt den Durchschnittswert einer Liste zurück',
  avg_param_array: 'Liste in der der Durchschnittswert ermittelt werden soll',

  /* --- sin --- */
  sin_description:
    'Gibt den Sinus eines definierten Winkels multipliziert mit einem Faktor (damit keine Kommazahlen benötigt werden) zurück',
  sin_param_degree: 'Winkel in Grad',
  sin_param_factor: 'Faktor des Resultats',

  /* --- cos --- */
  cos_description:
    'Gibt den Cosinus eines definierten Winkels multipliziert mit einem Faktor (damit keine Kommazahlen benötigt werden) zurück',
  cos_param_degree: 'Winkel in Grad',
  cos_param_factor: 'Faktor des Resultats',

  /* --- pow --- */
  pow_description:
    'Gibt den Potenzwert von der definierten Basis und dem definierten Exponent zurück',
  pow_param_base: 'Basis mit der der Potenzwert berechnet werden soll',
  pow_param_exponent: 'Exponent mit der der Potenzwert berechnet werden soll',

  /* --- map --- */
  map_description: 'Ordnet eine Zahl von einem Bereich einem anderen Bereich zu',
  map_param_value: 'Der zu mutierende Wert',
  map_param_a1: 'Bereich 1 Start',
  map_param_a2: 'Bereich 1 Ende',
  map_param_b1: 'Bereich 2 Start',
  map_param_b2: 'Bereich 2 Ende',

  /* --- abs --- */
  abs_description: 'Gibt den absoluten Wert (Wert ohne Vorzeichen) des definierten Werts zurück',
  abs_param_value: 'Wert von der der absolute Wert ermittelt werden soll',

  /* ---------- Category Logic ---------- */
  category_logic: 'Logik',

  /* --- isButtonPressed --- */
  is_button_pressed_description:
    'Gibt wahr zurück wenn der definierte Knopf gedrückt ist. Ansonsten falsch.\nMögliche Knöpfe sind:\n\tBTN_L1\n\tBTN_L2\n\tBTN_L3\n\tBTN_R1\n\tBTN_R2\n\tBTN_R3',
  is_button_pressed_param_button: 'Zu prüfender Knopf',

  /* --- waitForButtonPressed --- */
  wait_for_button_pressed_description:
    'Wartet bis der definierte Knopf gedrückt ist.\nMögliche Knöpfe sind:\n\tBTN_L1\n\tBTN_L2\n\tBTN_L3\n\tBTN_R1\n\tBTN_R2\n\tBTN_R3',
  wait_for_button_pressed_param_button: 'Zu prüfender Knopf',

  /* ---------- Category Variables ---------- */
  category_variables: 'Variablen',

  /* --- random --- */
  random_description:
    'Gibt einen zufälligen Wert zwischen dem definierten Start- und Endwert zurück',
  random_param_start: 'Startwert',
  random_param_end: 'Endwert',

  /* --- hasPersistentVariable --- */
  has_pers_var_description:
    'Gibt wahr zurück wenn ein Wert mit dem definierten Schlüssel im nichtflüchtigen Speicher gespeichert wurde',
  has_pers_var_param_key: 'Schlüssel zum Suchen',

  /* --- savePersistentVariable --- */
  save_pers_var_description:
    'Speichert einen Wert im nichtflüchtigen Speicher unter dem definierten Schlüssel',
  save_pers_var_param_key: 'Schlüssel zum Speichern des Werts',
  save_pers_var_param_value: 'Der zu speichernde Wert',

  /* --- loadPersistentVariable --- */
  load_pers_var_description:
    'Gibt den im nichtflüchtigen Speicher gespeicherten Wert unter dem definierten Schlüssel zurück',
  load_pers_var_param_key: 'Schlüssel zum Lesen des Werts',

  /* --- deletePersistentVariable --- */
  delete_pers_var_description:
    'Löscht einen Wert mit dem definierten Schlüssel aus dem nichtflüchtigen Speicher',
  delete_pers_var_param_key: 'Schlüssel zum Löschen des Werts',

  /* ---------- Category Oxocard ---------- */
  category_oxocard: 'Oxocard',

  /* --- reboot --- */
  reboot_description: 'Oxocard neustarten',

  /* --- turnOff --- */
  turn_off_description: 'Oxocard ausschalten',

  /* --- turnOffWakeUpButton --- */
  turn_off_wake_up_button_description:
    'Schaltet die Oxocard aus und aktiviert die definierten Knöpfe als gültige Aufwachquelle',
  turn_off_wake_up_button_param_l1: 'Wahr wenn Knopf L1 ist gültige Aufwachquelle',
  turn_off_wake_up_button_param_l2: 'Wahr wenn Knopf L2 ist gültige Aufwachquelle',
  turn_off_wake_up_button_param_l3: 'Wahr wenn Knopf L3 ist gültige Aufwachquelle',
  turn_off_wake_up_button_param_r1: 'Wahr wenn Knopf R1 ist gültige Aufwachquelle',
  turn_off_wake_up_button_param_r2: 'Wahr wenn Knopf R2 ist gültige Aufwachquelle',
  turn_off_wake_up_button_param_r3: 'Wahr wenn Knopf R3 ist gültige Aufwachquelle',

  /* --- configureAutoTurnOff --- */
  configure_auto_turn_off_description:
    'Setzt die Zeit in Sekunden nachdem die Oxocard automatisch ausschalten soll, wenn keine Benutzereingabe erfolgt',
  configure_auto_turn_off_param_seconds: 'Ausschalten nach so viel Sekunden',

  /* --- enableAutoTurnOff --- */
  enable_auto_turn_off_description:
    'Aktiviert die autom. Ausschaltfunktion (Standardzeit ist 120s)',

  /* --- disableAutoTurnOff --- */
  disable_auto_turn_off_description: 'Deaktiviert die autom. Ausschaltfunktion',

  /* --- sleep --- */
  sleep_description: 'Versetzt die Oxocard in den Schlafmodus für die definierte Zeit in Sekunden',
  sleep_param_seconds: 'Anzahl zu schlafende Sekunden',

  /* --- getWakeUpCause --- */
  get_wake_up_cause_description:
    'Gibt den Aufwachgrund zurück\n\tBTN_L1\n\tBTN_L2\n\tBTN_L3\n\tBTN_R1\n\tBTN_R2\n\tBTN_R3\n\tRESET\n\tTIMER',

  /* --- getBatteryVoltage --- */
  get_battery_voltage_description: 'Gibt die Batteriespannung in Millivolt zurück',

  /* --- isFullyCharged --- */
  is_fully_charged_description: 'Gibt wahr zurück, wenn die Batterie komplett geladen ist',

  /* --- isUsbPluggedIn --- */
  is_usb_plugged_in_description: 'Gibt wahr zurück, wenn das USB Kabel eingesteckt ist',

  /* --- getTemperature --- */
  get_temperature_description: 'Gibt die Temperatur in Grad Celsius zurück',

  /* ---------- Category Matrix ---------- */
  category_matrix: 'Matrix',

  /* --- setColor --- */
  set_color_description: 'Setzt die Farbe für alle nachfolgenden Zeichnen-Funktionen',
  set_color_param_r: 'Rot 0 - 255',
  set_color_param_g: 'Grün 0 - 255',
  set_color_param_b: 'Blau 0 - 255',

  /* --- setRandomColor --- */
  set_random_color_description:
    'Setzt eine zufällige Farbe für alle nachfolgenden Zeichnen-Funktionen',

  /* --- setHueColor --- */
  set_hue_color_description: 'Setzt die Farbe für alle nachfolgenden Zeichnen-Funktionen',
  set_hue_color_param_hue: 'Farbton 0 - 360°',

  /* --- enableAutoUpdate --- */
  enable_auto_update_description:
    'Aktiviert die Auto-Update-Funktion. Alle Zeichnen-Funktionen zeichnen ihr Resultat direkt aufs Display (Matrix)',

  /* --- disableAutoUpdate --- */
  disable_auto_update_description:
    'Deaktiviert die Auto-Update-Funktion. Nur der Aufruf der update()-Funktion zeichnet aufs Display',

  /* --- isAutoUpdate --- */
  is_auto_update_description:
    'Gibt wahr zurück, wenn die Auto-Update-Funktion aktiviert ist. Ansonsten falsch',

  /* --- update --- */
  update_description: 'Aktualisiert das Display',

  /* --- fillScreen --- */
  fill_screen_description: 'Füllt das ganze Display mit der momentan eingestellten Farbe',

  /* --- clearScreen --- */
  clear_screen_description: 'Löscht das ganze Display',

  /* --- setPixel --- */
  set_pixel_description:
    'Setzt ein Pixel im Bildspeicher ohne es auf dem Display auszugeben (auch nicht wenn die Auto-Update-Funktion aktiviert ist). Nur der Aufruf der update()-Funktion zeichnet es dann aufs Display',
  set_pixel_param_x: 'X Position des Pixels [0 - 7]',
  set_pixel_param_y: 'Y Position des Pixels [0 - 7]',
  set_pixel_pixel_param_r: 'Rot [0 - 255]',
  set_pixel_pixel_param_g: 'Grün [0 - 255]',
  set_pixel_pixel_param_b: 'Blau [0 - 255]',

  /* --- drawPixel --- */
  draw_pixel_description:
    'Zeichnet ein Pixel mit der momentan eingestellten Farbe und definierten Position auf dem Display',
  pixel_param_x: 'X Position des Pixels [0 - 7]',
  pixel_param_y: 'Y Position des Pixels [0 - 7]',

  /* --- clearPixel --- */
  clear_pixel_description: 'Löscht ein Pixel an der definierten Position',

  /* --- readPixel --- */
  read_pixel_description: 'Gibt die Farbe eines Pixels an der definierten Position zurück',
  read_pixel_param_color: 'Zu lesende Farbe (RED, GREEN or BLUE)',

  /* --- isPixelSet --- */
  is_pixel_set_description:
    'Gibt wahr zurück wenn ein Pixel an der definierten Position gesetzt ist',

  /* --- fill --- */
  fill_description: 'Füllt alle nachfolgenden geometrischen Zeichnen-Funktionen aus',

  /* --- noFill --- */
  no_fill_description: 'Füllt nicht alle nachfolgenden geometrischen Zeichnen-Funktionen aus',

  /* --- drawLine --- */
  draw_line_description: 'Zeichnet eine Linie von x1:y1 nach x2:y2',
  draw_line_param_x1: 'X Position der Startkoordinaten',
  draw_line_param_y1: 'Y Position der Startkoordinaten',
  draw_line_param_x2: 'X Position der Endkoordinaten',
  draw_line_param_y2: 'Y Position der Endkoordinaten',

  /* --- drawRectangle --- */
  draw_rectangle_description:
    'Zeichnet ein Rechteck mit der definierten Breite und Höhe mit der oberen linken Ecke an der Position x:y',
  draw_rectangle_param_x: 'X Position der oberen linken Ecke',
  draw_rectangle_param_y: 'Y Position der oberen linken Ecke',
  draw_rectangle_param_w: 'Breite des Rechtecks',
  draw_rectangle_param_h: 'Höhe des Rechtecks',

  /* --- drawTriangle --- */
  draw_triangle_description: 'Zeichnet ein Dreieck an den Punkten x1:y1, x2:y2 und x3:y3',
  draw_triangle_param_x1: 'X Position des ersten Punkts',
  draw_triangle_param_y1: 'Y Position des ersten Punkts',
  draw_triangle_param_x2: 'X Position des zweiten Punkts',
  draw_triangle_param_y2: 'Y Position des zweiten Punkts',
  draw_triangle_param_x3: 'X Position des dritten Punkts',
  draw_triangle_param_y3: 'Y Position des dritten Punkts',

  /* --- drawCircle --- */
  draw_circle_description: 'Zeichnet einen Kreis mit dem definierten Zentrum x:y und Radius',
  draw_circle_param_x: 'X Position des Zentrums',
  draw_circle_param_y: 'Y Position des Zentrums',
  draw_circle_param_r: 'Kreisradius',

  /* --- drawNumber --- */
  draw_number_description: 'Zeichnet eine Nummer auf dem Display',
  draw_number_param_num: 'Zu zeichnende Nummer',

  /* --- drawChar --- */
  draw_char_description:
    'Zeichnet ein einzelnes Zeichen an der definierten Position auf dem Display',
  draw_char_param_c: 'Das zu zeichnende Zeichen',
  draw_char_param_x: 'X Position der oberen linken Ecke',
  draw_char_param_y: 'Y Position der oberen linken Ecke',
  draw_char_param_is_big_font: 'Wenn wahr wird die grosse Schrift verwendet. Ansonsten die kleine',

  /* --- drawText --- */
  draw_text_description: 'Zeichnet einen Lauftext auf dem Display',
  draw_text_param_text: 'Buffer (String) mit dem zu zeichnenden Text',
  draw_text_param_is_big_font: 'Wenn wahr wird die grosse Schrift verwendet. Ansonsten die kleine',

  /* --- drawImage --- */
  draw_image_description:
    'Zeichnet ein Bild mit der momentan eingestellten Farbe auf dem Display. Das Bild ist eine Liste mit 8 Bytes, wobei jedes Bit einem LED (Pixel) entspricht',
  draw_image_param_image: 'Zu zeichnendes Bild',
  draw_image_param_x: 'X Position der oberen linken Ecke des Bildes',
  draw_image_param_y: 'Y Position der oberen linken Ecke des Bildes',

  /* --- drawImage --- */
  draw_image32_description:
    'Zeichnet ein Bild auf dem Display. Das Bild ist eine Liste mit 32 x 16 Bit Werten, wobei jeder Wert zwei LEDs (Pixel) entspricht',
  draw_image32_param_image: 'Zu zeichnendes Bild',
  draw_image32_param_x: 'X Position der oberen linken Ecke des Bildes',
  draw_image32_param_y: 'Y Position der oberen linken Ecke des Bildes',

  /* --- setActiveFrameBuffer --- */
  set_active_frame_buffer_description: 'Setzt den momentan aktiven Bildspeicher (1, 2 or 3)',
  set_active_frame_buffer_param_num: 'Bildspeicher-Nummer',

  /* --- copyFrameBufferContent --- */
  copy_frame_buffer_content_description: 'Kopiert den Inhalt eines Bildspeichers in einen anderen',
  copy_frame_buffer_content_param_from_buffer: 'Bildspeicher-Nummer von dem kopiert werden soll',
  copy_frame_buffer_content_param_to_buffer: 'Bildspeicher-Nummer in den kopiert werden soll',

  /* ---------- Category Time ---------- */
  category_time: 'Zeit',

  /* --- delay --- */
  delay_description: 'Wartet für die definierte Zeit in Millisekunden',
  delay_param_ms: 'Zu wartende Millisekunden',

  /* --- msSinceStart --- */
  ms_since_start_description: 'Gibt die Anzahl Millisekunden seit dem Programmstart zurück',

  /* --- useTimeZone --- */
  use_time_zone_description: 'Setzt die Zeitzone',
  use_time_zone_param_id: 'ID der Zeitzone',

  /* --- setTime --- */
  set_time_description: 'Setzt die Zeit der Oxocard',
  set_time_param_h: 'Stunden',
  set_time_param_m: 'Minuten',
  set_time_param_s: 'Sekunden',

  /* --- getTime --- */
  get_time_description:
    'Gibt den Wert der definierten Zeiteinheit zurück:\n\tSECONDS\n\tMINUTES\n\tHOURS',
  get_time_param_unit: 'Zeiteinheit (SECONDS, MINUTES or HOURS)',

  /* --- setDate --- */
  set_date_description: 'Setzt das Datum der Oxocard',
  set_date_param_d: 'Tag',
  set_date_param_m: 'Monat',
  set_date_param_y: 'Jahr',

  /* --- getDate --- */
  get_date_description:
    'Gibt den Wert der definierten Zeiteinheit zurück:\n\tWEEKDAY\n\tDAY\n\tMONTH\n\tYEAR',
  get_date_param_unit: 'Zeiteinheit (WEEKDAY, DAY, MONTH or YEAR)',

  /* ---------- Category Weather ---------- */
  category_weather: 'Wetter',

  /* --- getWeather --- */
  get_weather_description: 'Holt die Wetterdaten der definierten Ortschaft vom Internet',
  get_weather_param_place_id: 'ID der Ortschaft',

  /* --- readWeatherParam --- */
  read_weather_param_description:
    'Gibt den Wert des definierten Wetterparameters zurück:\n\tTEMPERATURE\n\tDEW_POINT\n\tHUMIDITY\n\tWIND\n\tPRECIPITATION',
  read_weather_param_param_param_id:
    'Wetterparameter (TEMPERATURE, DEW_POINT, HUMIDITY, WIND or PRECIPITATION)',

  /* --- drawCityName --- */
  draw_city_name_description: 'Zeichnet den definierten Ortsnamen auf dem Display',
  draw_city_name_param_is_big_font:
    'Wenn wahr, wird die grosse Schrift verwendet. Ansonsten die kleine',

  /* --- drawForecastIcon --- */
  draw_forecast_icon_description: 'Zeichnet das Prognosesymbol auf dem Display',

  /* ---------- Category Accelerometer ---------- */
  category_accelerometer: 'Beschleunigungssensor',

  /* --- getAcceleration --- */
  get_acceleration_description: 'Gibt den Beschleunigungswert der definierten Achse zurück',
  get_acceleration_param_axis: 'AXIS_X, AXIS_Y oder AXIS_Z',

  /* --- getNormalizedValue --- */
  get_normalized_value_description: 'Gibt den normalisierten Beschleunigungswert zurück',

  /* --- setAccelerometerCursor --- */
  set_accelerometer_cursor_description: 'Setzt den Beschleunigungs-Cursor',
  set_accelerometer_cursor_param_x: 'X Position des Beschleunigungs-Cursors',
  set_accelerometer_cursor_param_y: 'Y Position des Beschleunigungs-Cursors',

  /* --- getAccelerometerCursor --- */
  get_accelerometer_cursor_description:
    'Liest momentane Position (Achse) des Beschleunigungs-Cursors',
  get_accelerometer_cursor_param_axis:
    'Beschleunigungs-Achse (AXIS_X or AXIS_Y) des zu lesenden Cursors',

  /* --- getOrientation --- */
  get_orientation_description: 'Gibt die momentane Orientation der Karte zurück (ORIENTATION_XY)',

  /* --- isOrientation --- */
  is_orientation_description:
    'Gibt wahr zurück wenn sich die Oxocard in der definierten Orientation befindet',
  is_orientation_param_direction: 'Zu prüfende Orientation (ORIENTATION_XY)',

  /* --- setReferenceVector --- */
  set_reference_vector_description: 'Setzt den Referenzvektor auf die aktuelle Position der Karte',

  /* --- getAngle --- */
  get_angle_description:
    'Gibt den momentanen Winkel zum Referenzvektor zurück. Der Standardreferenzvektor entspricht der gerade auf dem Tisch liegenden Oxocard',

  /* --- configureAccelerometer --- */
  config_acc_description: 'Konfiguriert die Beschleunigungs-Datenrate und -Reichweite',
  config_acc_param_data_rate: 'Datenrate: POWER_DOWN oder ein NORMAL_xy_HZ',
  config_acc_param_range: 'Reichweite: MAX_xyG',

  /* ---------- Category Audio ---------- */
  category_audio: 'Audio',
  /* --- playTone --- */
  play_tone_description: 'Spielt einen Ton mit der definierten Frequenz',
  play_tone_param_hz: 'Frequenz in Hz',

  /* --- playToneFor --- */
  play_tone_for_description: 'Spielt einen Ton mit der definierten Frequenz und Dauer',
  play_tone_for_param_hz: 'Frequenz in Hz',
  play_tone_for_param_ms: 'Dauer in Millisekunden',

  /* --- noteToMs --- */
  note_to_ms_description: 'Wandelt Notendauer in Millisekunden um',
  note_to_ms_param_note_duration: 'Notendauer (NOTE_DUR_XY)',

  /* --- playNote --- */
  play_note_description: 'Spielt eine Note',
  play_note_param_note: 'Note (NOTE_XY)',

  /* --- playNoteFor --- */
  play_note_for_description: 'Spielt eine Note für die definierte Dauer',
  play_note_for_param_note: 'Note (NOTE_XY)',
  play_note_for_param_ms: 'Dauer in Millisekunden',

  /* --- noTone --- */
  no_tone_description: 'Stoppt den aktuellen Ton',

  /* --- setWaveForm --- */
  set_wave_form_description:
    'Setzt die Wellenform des Audiosignals:\n\tWAVEFORM_SINE\n\tWAVEFORM_SQUARE\n\tWAVEFORM_SAWTOOTH\n\tWAVEFORM_TRIANGLE',
  set_wave_form_param_wave_form: 'Wellenform (WAVEFORM_XY)',

  /* --- setOctave --- */
  set_octave_description: 'Setzt die Oktave der nachfolgenden Noten',
  set_octave_param_octave: 'Oktave (OCTAVE_XY)',

  /* --- setBPM --- */
  set_bpm_description: 'Setzt den BPM (beats per minute) der nachfolgenden Noten',
  set_bpm_param_bpm: 'BPM',

  /* --- setToneBlocking --- */
  set_tone_blocking_description: 'Warte nach jedem nachfolgenden Ton',
  set_tone_blocking_param_is_blocking: 'true -> warten; false -> nicht warten',

  /* --- setVolume --- */
  set_volume_description: 'Setzt die Lautstärke des Audioausgangs',
  set_volume_param_volume: 'Lautstärke 0 - 255',

  /* --- getVolume --- */
  get_volume_description: 'Gibt die aktuelle Lautstärke zurück',

  /* --- volumeUp --- */
  volume_up_description: 'Erhöht die Lautstärke',

  /* --- volumeDown --- */
  volume_down_description: 'Verringert die Lautstärke',

  /* --- playSoundEffect --- */
  play_sound_effect_description: 'Spielt einen Sound Effekt',
  play_sound_effect_param_effect_num: 'Einer der SOUND_EFFECT_ Effekts',
  play_sound_effect_param_loop: 'True, wenn der Effekt wiederholt werden soll',

  /* ---------- Category Microphone ---------- */
  category_microphone: 'Mikrofon',

  /* --- record --- */
  record_description: 'Nimmt für die definierte Dauer auf',
  record_param_ms: 'Aufnahmedauer in Millisekunden',

  /* --- playRecorded --- */
  play_recorded_description: 'Spiele das Aufgenommene',
  play_recorded_param_loop: 'Wenn die Ausgabe immer wieder ausgegeben werden soll -> true',

  /* --- waitForLoudNoise --- */
  wait_for_loud_noise_description: 'Warte auf lautes Geräusch',

  /* --- startMicrophone --- */
  start_microphone_description: 'Aktiviert das Mikrofon (Audio wird gestoppt)',

  /* --- readDecibel --- */
  read_decibel_description: 'Gibt den momentan vom Mikrofon gemessenen Geräuschpegel zurück',

  /* --- readFrequency --- */
  read_frequency_description:
    'Gibt die momentan vom Mikrofon gemessene Frequenz des höchsten Geräuschpegels zurück',

  /* --- stopMicrophone --- */
  stop_microphone_description: 'Deaktiviert das Mikrofon (Audio wird gestartet)',

  /* ---------- Category Internet ---------- */
  category_internet: 'Internet',

  /* --- connect --- */
  connect_description:
    'Verbindet die Oxocard mit der definierten Verbindungsschnittstelle (im Moment nur WIFI)',
  connect_param_interface: 'Verbindungsschnittstelle (z.B. WIFI)',

  /* --- disconnect --- */
  disconnect_description: 'Trennt die Oxocard von der aktuellen Verbindungsschnittstelle',

  /* --- startAccessPointMode --- */
  start_access_point_mode_description: 'Versetzt die Oxocard in den Accesspoint-Modus',

  /* --- startPairingMode --- */
  start_pairing_mode_description: 'Versetzt die Oxocard in den Pairing-Modus',

  /* --- hasNewScript --- */
  has_new_script_description: 'Gibt wahr zurück, wenn ein neues Script zum Download bereit steht',

  /* --- downloadScript --- */
  download_script_description: 'Versetzt die Oxocard in den Update-Modus',

  /* ---------- Category Extras ---------- */
  category_extras: 'Extras',

  /* --- setPinMode --- */
  set_pin_mode_description: 'Setzt den Modus eines Pins (GPIOs)',
  set_pin_mode_param_pin: 'Pin (IOXY)',
  set_pin_mode_param_mode: 'Modus (INPUT, INPUT_PULLUP, INPUT_PULLDOWN oder OUTPUT)',

  /* --- setPin --- */
  set_pin_description: 'Setzt den Zustand eines Pins',
  set_pin_param_pin: 'Pin (IOXY)',
  set_pin_param_state: 'Zustand (HIGH or LOW)',

  /* --- readPin --- */
  read_pin_description: 'Gibt den momentanen Zustand des definierten Pins zurück',
  read_pin_param_pin: 'Pin (IOXY)',

  /* --- print --- */
  print_description:
    'Gibt Nachricht an das Terminal.\n\nHinweis: connect(WIFI) muss vorher aufgerufen werden, um Nachrichten an den Browser zu schicken',
  print_param_pin: 'Zu sendende Variable, Liste oder Text',

  /* --- pushValue --- */
  push_value_description: 'Sendet ein einzelner Wert mit definiertem Schlüssel an den Browser',
  push_value_param_key: 'Schlüssel des zu sendenden Werts',
  push_value_param_value: 'Der zu sendende Wert',

  /* --- pushValues --- */
  push_values_description: 'Sendet eine Liste mit definiertem Schlüssel an den Browser',
  push_values_param_key: 'Schlüssel der zu senden Liste',
  push_values_param_values: 'Die zu sendende Liste',

  /* --- getValues --- */
  get_values_description:
    'Beschreibt die definierte Liste mit den empfangenen Werten des definierten Schlüssels',
  get_values_param_key: 'Schlüssel der zu empfangenen Werte',
  get_values_param_values: 'Liste zum Beschreiben der empfangenen Werte',

  /* --- clearValues --- */
  clear_values_description: 'Löscht alle Werte des definierten Schlüssels',
  clear_values_param_key: 'Schlüssel der zu löschenden Werte',

  /* --- removeValues --- */
  remove_values_description:
    'Löscht alle Werte des definierten Schlüssels und entfernt den Schlüssel',
  remove_values_param_key: 'Schlüssel der zu löschenden Werte',

  /* --- i2cWrite --- */
  i2c_write_description: 'Sendet Daten an ein I2C Gerät',
  i2c_write_param_slaveAdr: 'Geräteadresse',
  i2c_write_param_dataAdr: 'Datenadresse (Register)',
  i2c_write_param_data: 'Zu sendende Datenliste',

  /* --- i2cWrite --- */
  i2c_read_description: 'Empfängt Daten von einem I2C Gerät',
  i2c_read_param_slaveAdr: 'Geräteadresse',
  i2c_read_param_dataAdr: 'Datenadresse (Register)',
  i2c_read_param_data: 'Zu empfangene Datenliste',
  i2c_read_param_dataLength: 'Anzahl der zu empfangenen Bytes',
};
