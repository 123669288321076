/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  0: `Après "def", un nom de fonction doit suivre.`,
  1: `Caractère manquant: '('`,
  2: `Caractère manquant: ')'`,
  3: `Les noms de variables globales ne doivent pas être utilisés comme noms de paramètres.`,
  4: `Les variables ont besoin d'un type de données. Choisis entre "int", "char" ou "byte".`,
  5: `Il y a une instruction inconnue ou manquante dans un code.`,
  6: `'=', '(' ou '+=' sont attendus.`,
  7: `'++' ou '--' sont attendus.`,
  8: `Nom de la fonction inconnue. Vérifier que le nom est correctement orthographié.`,
  9: `Caractère manquant: '('`,
  10: `Caractère manquant: ')'`,
  11: `Un certain nombre est attendu ici.`,
  12: `Cette fonction attend une liste ou une chaîne, mais tu as passé un numéro.`,
  13: `Cette fonction n'attend aucun paramètre. Mais tu as essayé de transmettre des valeurs.`,
  14: `Nombre de paramètres erroné. Vérifies la documentation relative à la fonction ou regardes un exemple où la fonction a été utilisée.`,
  15: `']' attendu.`,
  16: `'return' n'est pas autorisé dans les procédures d'événements. Utilises plutôt le terme 'exit'.`,
  17: `Caractère manquant: ')'`,
  18: `Cette variable n'est pas définie. Vérifies que le nom est correctement orthographié.`,
  19: `']' attendue mais non trouvée.`,
  20: `Tu dois utiliser une liste de variables ici.`,
  21: `Caractère manquant: ')'`,
  22: `Une erreur s'est produite dans cette expression.`,
  23: `Caractère manquant: ')'`,
  24: `Caractère manquant: ')'`,
  25: `Chaque déclaration doit être séparée soit sur une nouvelle ligne, soit par un point-virgule (';').`,
  26: `Tu ne peux pas attribuer de valeur aux variables globales ici (seulement autorisé dans les fonctions)`,
  27: `Ici, tu as besoin d'un deux-points ':' avec des instructions en retrait.`,
  28: `Erreur dans for déclaration: for variable dans la liste: instructions`,
  29: `après 'for', seule une variable simple peut suivre, pas de liste`,
  30: `'in' attendu.`,
  31: `Un accès indexé avec [] attend une variable préalablement déclarée.`,
  32: `A for .. in .. doit être suivie d'une liste, d'une variable entière ou d'un nombre.`,
  33: `Les types de listes ne sont pas autorisés ici.`,
  34: `Il manque ici un '='.`,
  35: `Le nom de cette variable est déjà utilisé.`,
  36: `Les variables numériques globales ne peuvent être initialisées qu'avec 0.`,
  37: `Les variables de la liste globale ne peuvent être initialisées qu'avec [].`,
  38: `Il doit s'agir d'une déclaration def ou de la déclaration d'une variable globale.`,
  39: `Virgule attendue.`,
  40: `Les lignes doivent être en retrait après un double point.`,
  41: `break ne peut être utilisée que dans une boucle.`,
  42: `continue ne peut être utilisée que dans une boucle.`,
  43: `Numéro non valide.`,
};
