import React, { Component, createRef } from 'react';
import * as monaco from 'monaco-editor';
import {
  getMonarchTokensProvide,
  getMonarchCompletionItemProvider,
} from './oxoscript_code_monarch';

import './EditorError.css';

const SAVE_DEBOUNCE_MS = 250;

/* Register a new language */
monaco.languages.register({ id: 'oxoscript' });

/* Register a tokens provider for the language */
monaco.languages.setMonarchTokensProvider('oxoscript', getMonarchTokensProvide());

monaco.languages.registerCompletionItemProvider('oxoscript', getMonarchCompletionItemProvider());

monaco.editor.defineTheme('light', {
  base: 'vs',
  inherit: true,
  rules: [
    { token: 'keyword.oxoscript', foreground: '00a7e3' },
    { token: 'function.oxoscript', foreground: '5a9400' },
    { token: 'customfunction.oxoscript', foreground: 'e67300' },
    { token: 'constants.oxoscript', foreground: '9d00b5' },
  ],
  colors: {
    'editorGutter.background': '#f1f1f1',
    'editorLineNumber.foreground': '#adadad',
  },
});

monaco.editor.defineTheme('dark', {
  base: 'vs-dark',
  inherit: true,
  rules: [
    { token: 'keyword.oxoscript', foreground: '00a7e3' },
    { token: 'function.oxoscript', foreground: '79b51c' },
    { token: 'customfunction.oxoscript', foreground: 'e67300' },
    { token: 'constants.oxoscript', foreground: 'c657d9' },
  ],
  colors: {
    'editorGutter.background': '#2e2e2e',
    'editorLineNumber.foreground': '#6e6e6e',
  },
});

class MonacoEditor extends Component {
  static defaultProps = {
    theme: 'light',
    onEditorDidMount: editor => null,
  };

  constructor(props) {
    super(props);
    this.editorRef = createRef();
    this.saveTimeoutId = null;
    this.editor = null;
    this.decorations = [];
  }

  componentDidMount() {
    this.editor = monaco.editor.create(this.editorRef.current, {
      value: this.props.value,
      language: 'oxoscript',
      theme: this.props.theme,
      glyphMargin: true,
      lineNumbersMinChars: 2,
      fontSize: this.props.fontSize,
    });

    this.editor.getModel().updateOptions({ tabSize: 4 });
    this.props.onEditorDidMount(this.editor);
    this.editor.onDidChangeModelContent(this.onDidChange);
    window.addEventListener('resize', this.updateDimensions);

    this.editor.changeViewZones(changeAccessor => {
      const domNode = document.createElement('div');
      changeAccessor.addZone({
        afterLineNumber: 0,
        heightInLines: 1,
        domNode: domNode,
      });
    });

    setTimeout(() => {
      this.props.onCompileScript(this.editor.getValue());
    }, SAVE_DEBOUNCE_MS / 2);
  }

  componentDidUpdate(prevProps) {
    if (this.editor) {
      const { theme } = this.props;
      if (prevProps && prevProps.theme !== theme) {
        monaco.editor.setTheme(theme);
      }

      const value = this.editor.getValue();
      if (this.props.value !== value) {
        this.editor.setValue(this.props.value);
      }

      const { fontSize } = this.props;
      if (prevProps && prevProps.fontSize !== fontSize) {
        this.editor.updateOptions({ fontSize: fontSize });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = evt => {
    if (this.editor) {
      this.editor.layout();
    }
  };

  onDidChange = () => {
    // Debouncing change event handler
    clearTimeout(this.saveTimeoutId);
    this.saveTimeoutId = setTimeout(() => {
      this.props.onCompileScript(this.editor.getValue());
      this.props.onChangeDebounced();
    }, SAVE_DEBOUNCE_MS);

    this.props.onChange(this.editor.getValue());
  };

  render() {
    const error = this.props.errorMessage || [];

    if (this.editor && error.length > 0) {
      const errorLine = error[0];

      this.decorations = this.editor.deltaDecorations(this.decorations, [
        {
          range: new monaco.Range(errorLine, 1, errorLine, 1),
          options: {
            isWholeLine: true,
            className: 'myInlineDecoration',
            glyphMarginClassName: 'myLineDecoration',
          },
        },
      ]);
    } else if (this.editor) {
      this.decorations = this.editor.deltaDecorations(this.decorations, []);
    }

    return <div ref={this.editorRef} id="monaco-container" />;
  }
}

export default MonacoEditor;
