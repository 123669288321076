/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* ---------- def main() ---------- */
  main_description:
    'Die Main Funktion ist der Einstiegspunkt deines Programms. Es besteht oft aus dem Setup-Code, welcher nur einmal ausgeführt wird, und einer Schleife, die ständig wiederholt wird.',
  /* ---------- + / - / * / % / / ---------- */
  math_description:
    'Alle gebräuchlichen mathematischen Operatoren können verwendet werden. Der % (Modulo) Operator liefert den Rest einer Division zurück.',
  /* ---------- [lists] ---------- */
  list_description:
    'Listen sind ein guter Weg, um mehrere Werte zusammen zu fassen und mit ihnen zu arbeiten.',
  list_example_name_empty: '[]',
  list_example_description_empty: 'Du kannst eine leere Liste initialisieren.',
  list_example_name_values: '[1, 2]',
  list_example_description_values: 'Du kannst eine Liste mit Werten initialisieren.',
  list_example_name_append: '[1, 2] + 3',
  list_example_description_append:
    'Du kannst einzelne Werte oder andere Listen einer Liste hinzufügen.',
  list_example_name_read: 'meineListe[2]',
  list_example_description_read:
    'Du kannst den Inhalt einer bestimmten Position in einer Liste lesen (beginnend mit 0).',
  list_example_name_size: 'size / min / max / avg',
  list_example_description_size:
    'Du kannst die Grösse, so wie den Minimal-, Maximal- oder Durchschnittswert einer Liste lesen.',
  /* ---------- "strings" ---------- */
  string_description: 'Strings repräsentieren Text im Oxoscript',
  string_example_name_empty: '""',
  string_example_description_empty: 'Du kannst einen leeren String initialisieren.',
  string_example_name_values: '"Hallo Welt"',
  string_example_description_values: 'Du kannst einen String mit einem Wert initialisieren.',
  string_example_name_append: '"Hallo" + "Welt"',
  string_example_description_append: 'Du kannst einen String an einen anderen String anhängen.',
  /* ---------- if / elif / else ---------- */
  if_description:
    'Bedingungen werden verwendet, um Entscheidungen im Code zu treffen. Sie können aus einem if, einem elif und/oder einem else Statement zusammengesetzt sein.',
  if_example_name_if: 'if',
  if_example_description_if:
    'Der Code innerhalb des if wird nur ausgeführt, wenn die Bedingung wahr ist.',
  if_example_name_if_elif_else: 'elif',
  if_example_description_if_elif_else:
    'Der Inhalt vom elif wird nur ausgeführt wenn die definierte if Bedingung falsch war und die elif Bedingung wahr ist.',
  if_example_name_if_else: 'else',
  if_example_description_if_else:
    'Der Inhalt vom else Statement wird nur ausgeführt, wenn die if und elif Bedingung falsch war.',
  /* ---------- while ---------- */
  while_description:
    'Der Inhalt einer while Schleife wird solange ausgeführt, wie die Bedingung der Schleife erfüllt ist',
  /* ---------- repeat ---------- */
  repeat_description:
    'Der Inhalt der repeat Schleife wird für die definierte Anzahl Male ausgeführt.',
  /* ---------- for ---------- */
  for_description:
    'Die for Schleife kann verwendet werden, um über eine liste oder einen Zahlenbereich zu iterieren. Sie besteht aus einer Variable und einem Ausdruck. Die Variable wird nach jedem Durchlauf mit dem nächsten Wert des Ausdrucks aktualisiert. Ein Ausdruck kann eine Liste, ein Zahlenbereich oder eine Variable sein.',
  /* ---------- break / continue ---------- */
  break_continue_description:
    'Break and continue können nur innerhalb einer Schleife verwendet werden. Wird break aufgerufen, so wird die Schleife sofort verlassen. Wird continue aufgerufen, wird der Rest des Codes in der Schleife übersprungen und die Schleife beginnt mit dem nächsten Durchgang.',
  /* ---------- ++/-- ---------- */
  add_sub_description:
    'Der ++ Operator addiert eins zu einem Wert und der -- Operator zieht eins vom Wert ab.',
  /* ---------- Comparison operators ---------- */
  comp_op_description: 'Vergleichsoperatoren werden in if, while und for Statements verwendet.',
  comp_op_example_name_equ: '==',
  comp_op_example_description_equ: 'Ist wahr (true), wenn die rechte Seite gleich der Linken ist.',
  comp_op_example_name_unequ: '!=',
  comp_op_example_description_unequ:
    'Ist wahr (true), wenn die rechte Seite ungleich der Linken ist.',
  comp_op_example_name_smaller: '< / <=',
  comp_op_example_description_smaller:
    'Ist wahr (true), wenn die linke Seite kleiner (<) oder kleiner gleich (<=) der rechten Seite ist.',
  comp_op_example_name_bigger: '> / >=',
  comp_op_example_description_bigger:
    'Ist wahr (true), wenn die linke Seite grösser (>) oder grösser gleich (>=) der rechten Seite ist.',
  comp_op_example_name_and: '&& / and',
  comp_op_example_description_and: 'Ist nur wahr (true), wenn beide Seiten wahr sind.',
  comp_op_example_name_or: '|| / or',
  comp_op_example_description_or: 'Ist wahr (true), wenn eine der Seiten wahr ist',
  /* ---------- not ---------- */
  not_description: 'Negiert einen logischen Wert. True wird false und umgekehrt.',
  /* ---------- def functions() ---------- */
  function_description:
    'Erstelle eine eigene Funktion, wenn du Code mehrere Male ausführen möchtest oder einfach die Struktur verbessern möchtest',
  function_example_name_no_params: 'def meineFunktion():',
  function_example_description_no_params: 'Erstelle eine neue Funktion ',
  function_example_name_params: 'def meineFunktion(p1, p2):',
  function_example_description_params:
    'Du kannst Parameter einer Funktion übergeben und auch etwas zurück liefern',
  function_example_name_by_ref: 'def meineFunktion(list*):',
  function_example_description_by_ref:
    'Listen und Strings müssen immer als Referenz (mit einem *) übergeben werden. Du kannst keine Listen zurück geben aus einer Funktion. Aber wenn etwas per Referenz übergeben wird, kann es direkt verändert werden.',
  /* ---------- 0b / 0x ---------- */
  bit_hex_description:
    'Ein Dezimalwert kann auch als Binärwert (0b) oder als Hexadezimalwert (0x) definiert werden.',
  /* ---------- Bit operators ---------- */
  bit_op_description:
    'Bitweise Operatoren können verwendet werden um effizient mit byte Daten umzugehen.',
  bit_op_example_name_shift_l: '<<',
  bit_op_example_description_shift_l:
    'Verschiebt den Inhalt einer Variable bitweise nach links. Bei einer ganzen nicht negativen Zahl entspricht eine Verschiebung einer Multiplikation mit 2n.',
  bit_op_example_name_shift_r: '>>',
  bit_op_example_description_shift_r:
    'Verschiebt den Inhalt einer Variable bitweise nach rechts. Bei einer ganzen, nicht negativen Zahl entspricht eine Verschiebung einer Division durch 2n und dem Abschneiden der Nachkommastellen (falls vorhanden).',
  bit_op_example_name_and: '&',
  bit_op_example_description_and:
    'Mit dem UND-Operator werden zwei Operanden bitweise UND verknüpft.\n\n0 UND 0 = 0\n0 UND 1 = 0\n1 UND 0 = 0\n1 UND 1 = 1',
  bit_op_example_name_or: '|',
  bit_op_example_description_or:
    'Mit dem ODER-Operator werden zwei Operanden bitweise ODER verknüpft.\n\n0 ODER 0 = 0\n0 ODER 1 = 1\n1 ODER 0 = 1\n1 ODER 1 = 1',
  bit_op_example_name_xor: '^',
  bit_op_example_description_xor:
    'Mit dem XOR-Operator werden zwei Operanden bitweise XOR verknüpft.\n\n0 XOR 0 = 0\n0 XOR 1 = 1\n1 XOR 0 = 1\n1 XOR 1 = 0',
};
