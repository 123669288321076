/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  0: `Nach 'def' muss ein Funktionsname folgen.`,
  1: `Fehlendes Zeichen: '('`,
  2: `Fehlendes Zeichen: ')'`,
  3: `Globale Variablenamen dürfen nicht als Parameter-Namen gebraucht werden.`,
  4: `Variablen brauchen einen Datentyp. Wähle zwischen 'int', 'char', oder 'byte'`,
  5: `Es gibt eine unbekannte oder fehlende Anweisung in einem Code.`,
  6: `'=', '(' oder '+=' werden erwartet.`,
  7: `'++' oder '--' werden erwartet.`,
  8: `Unbekannter Funktionsname. Prüfe, ob der Namen richtig geschrieben wurde.`,
  9: `Fehlendes Zeichen: '('`,
  10: `Fehlendes Zeichen: ')'`,
  11: `Hier wird eine Zahl erwartet.`,
  12: `Diese Funktion erwartet eine Liste oder eine Zeichenkette, du hast aber eine Zahl übergeben.`,
  13: `Diese Funktion erwartet keine Parameter. Du hast aber versucht, Werte zu übergeben.`,
  14: `Falsche Anzahl Parameter. Prüfe die Dokumentation zur Funktion oder schau dir ein Beispiel an, in der die Funktion genutzt wurde.`,
  15: `']' erwartet.`,
  16: `'return' ist in Ereignisprozeduren nicht erlaubt. Nutze stattdessen 'exit'.`,
  17: `Fehlendes Zeichen: ')'`,
  18: `Diese Variable ist nicht definiert. Prüfe, ob der Namen richtig geschrieben wurde.`,
  19: `']' erwartet, aber nicht gefunden.`,
  20: `Du musst hier eine Variablenliste verwenden.`,
  21: `Fehlendes Zeichen: ')'`,
  22: `In diesem Ausdruck ist ein Fehler aufgetreten.`,
  23: `Fehlendes Zeichen: ')'`,
  24: `Fehlendes Zeichen: ')'`,
  25: `Jede Anweisung muss entweder auf einer neuen Zeile oder mit Strichpunkt (';') abgetrennt werden.`,
  26: `Globalen Variablen kann man hier keinen Wert zuweisen (nur in Funktionen erlaubt)`,
  27: `Hier braucht es einen Doppelpunkt ':' mit eingerückten Anweisungen.`,
  28: `Fehler in der for-Anweisung: for Variable in liste: anweisungen`,
  29: `nach 'for' darf nur eine einfache Variable folgen, kein Liste`,
  30: `'in' erwartet.`,
  31: `Ein indizierter Zugriff mit [] erwartet eine vorgaengig deklarierte Variable.`,
  32: `Bei for ... in .. muss eine Liste, eine Integer-Variable oder eine Zahl folgen.`,
  33: `Listen-Typen sind hier nicht erlaubt.`,
  34: `Hier fehlt ein '='.`,
  35: `Dieser Variablen-Namen ist bereits in Verwendung.`,
  36: `Globale numerische Variablen können nur mit 0 initialisiert werden.`,
  37: `Globale Listen-Variablen koennen nur mit [] initialisiert werden.`,
  38: `Hier muss eine def-Anweisung oder die Deklaration einer globalen Variablen stehen.`,
  39: `Komma erwartet.`,
  40: `Nach einem Doppelpunkt müssen die Zeilen eingerückt sein.`,
  41: `break darf nur in einer Schleife verwendet werden.`,
  42: `continue darf nur in einer Schleife verwendet werden.`,
  43: `Ungueltige Zahl.`,
};
