import React from 'react';

/* Components */

/* Styles */
import './MenuCategory.css';

class MenuCategory extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.theme === nextProps.theme &&
      this.props.name === nextProps.name &&
      this.props.isOpen === nextProps.isOpen
    ) {
      return false;
    }

    return true;
  }

  render() {
    const { children, theme, name, isOpen, isNew, onClick } = this.props;
    return (
      <div
        className={
          isOpen ? `menu_category_wrapper ${theme} open` : `menu_category_wrapper ${theme}`
        }
      >
        <div
          className={
            isOpen ? `menu_category_header ${theme} open` : `menu_category_header ${theme}`
          }
          onClick={onClick}
        >
          <div className="menu_category_header_name">
            {name} {isNew && <span className="new">New</span>}
          </div>
          <div className="menu_category_header_icon"></div>
        </div>
        <div className="menu_category_body">{children}</div>
      </div>
    );
  }
}

export default MenuCategory;
