import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import functionData from './functionData';

/* Components */
import SubCategory from '../../SubCategory';
import FunctionList from './FunctionList';

/* Store */

/* Styles */
import './Functions.css';

const filter = (data, filterString) => {
  const filterStringLow = filterString.toLowerCase();

  if (!filterString.length) {
    return data;
  }

  let allFilteredFunctions = [];
  data.map(category => {
    const filteredFunctions = category.functions.filter(func => {
      return func.name.toLowerCase().includes(filterStringLow);
    });
    if (filteredFunctions.length) {
      const functions = filteredFunctions.map(f => {
        f.parentCategory = category.name;
        return f;
      });
      allFilteredFunctions = [...allFilteredFunctions, ...functions];
    }
    return false;
  });

  const filteredData = [
    {
      name: null,
      functions: [...allFilteredFunctions],
    },
  ];
  return filteredData;
};

class Functions extends React.PureComponent {
  state = {
    activeFunctionCategory: -1,
    filterString: '',
    functionData,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.searchString !== state.filterString) {
      return {
        activeFunctionCategory: -1,
        filterString: props.searchString,
        functionData: filter(functionData, props.searchString),
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.props.isOpen === false) {
      this.setState({ activeFunctionCategory: -1 });
    }
  }

  render() {
    const { activeFunctionCategory } = this.state;
    const { i18n } = this.props;
    return (
      <div className="functions_wrapper">
        {this.state.functionData.map((cat, i) => {
          if (cat.name) {
            return (
              <SubCategory
                theme={this.props.theme}
                name={i18n.t('function:' + cat.name)}
                key={i}
                isOpen={activeFunctionCategory === i}
                onClick={() => {
                  this.setState({
                    activeFunctionCategory: i === activeFunctionCategory ? -1 : i,
                  });
                }}
              >
                <FunctionList
                  functions={cat.functions}
                  key={i}
                  isOpen={activeFunctionCategory === i}
                  filterString={this.state.filterString}
                />
              </SubCategory>
            );
          } else {
            return (
              <FunctionList
                functions={cat.functions}
                key={i}
                isOpen={true}
                filterString={this.state.filterString}
              />
            );
          }
        })}
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return { theme: settings.editorTheme };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Functions));
