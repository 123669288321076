import { getCardMessages } from '../../api';

/* Actions */
const SET_NEW_OUTPUT = 'SET_NEW_OUTPUT';
const CLEAR_OUTPUT = 'CLEAR_OUTPUT';
const TOGGLE_PULLING = 'TOGGLE_PULLING';
const RESET_TERMINAL = 'RESET_TERMINAL';

const initialState = {
  pollMessages: false,
  output: [],
};

/* Reducer */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NEW_OUTPUT:
      const output = [...state.output, ...action.payload];
      return { ...state, output };
    case CLEAR_OUTPUT:
      return { ...initialState, pollMessages: state.pollMessages };
    case TOGGLE_PULLING:
      return { ...state, pollMessages: !state.pollMessages };
    case RESET_TERMINAL:
      return { ...initialState };
    default:
      return state;
  }
}

/* Action Creators */
export function addOutput(data) {
  return {
    type: SET_NEW_OUTPUT,
    payload: data,
  };
}

export function clearOutput() {
  return { type: CLEAR_OUTPUT };
}

export function togglePulling() {
  return { type: TOGGLE_PULLING };
}

export function resetTerminal() {
  return { type: RESET_TERMINAL };
}

export function getMessages() {
  return async dispatch => {
    try {
      const res = await getCardMessages();
      if (res !== null) dispatch(addOutput(res));
    } catch (e) {
      throw new Error(e);
    }
  };
}
