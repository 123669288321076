import React from 'react';
import { useTranslation } from 'react-i18next';

/* Components */

/* Store */

/* Styles */
import './SyntaxElement.css';

function SyntaxElement({ syntaxData }) {
  const { t } = useTranslation();

  return (
    <div className="syntax_element_wrapper">
      <div className="syntax_element_description">{t('syntax:' + syntaxData.description)}</div>

      {syntaxData.examples.map((example, i) => (
        <div className="syntax_example" key={i}>
          {example.name && <div className="syntax_example_name">{t('syntax:' + example.name)}</div>}
          {example.description && (
            <div className="syntax_example_description">
              <pre>{t('syntax:' + example.description)}</pre>
            </div>
          )}
          <div className="syntax_example_code_wrapper">
            <div className="syntax_example_code">
              <pre dangerouslySetInnerHTML={{ __html: example.code }}></pre>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default React.memo(SyntaxElement);
