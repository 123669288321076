import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* PDFs */
import installationPdfDe from '../../../assets/pdf/Installationsanleitung_Oxoscript_DE.pdf';
import installationPdfEn from '../../../assets/pdf/Installationsanleitung_Oxoscript_EN.pdf';
import installationPdfFr from '../../../assets/pdf/Installationsanleitung_Oxoscript_FR.pdf';
import keyCommandsDe from '../../../assets/pdf/Oxocardbefehle.pdf';
import bookPdfDe from '../../../assets/pdf/Oxoscript_Handbuch_DE.pdf';
import bookPdfEn from '../../../assets/pdf/Oxoscript_Handbuch_EN.pdf';
import bookPdfFr from '../../../assets/pdf/Oxoscript_Handbuch_FR.pdf';

/* Components */

/* Store */

/* Styles */
import './Start.css';

const installGuides = {
  de: installationPdfDe,
  en: installationPdfEn,
  fr: installationPdfFr,
};

const keyCommands = {
  de: keyCommandsDe,
};

const books = {
  de: bookPdfDe,
  en: bookPdfEn,
  fr: bookPdfFr,
};

function Start() {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.settings.lang);
  return (
    <div className="start_wrapper">
      <p>{t('menu_first_steps_text')}</p>
      <br />
      <a href={installGuides[lang]} target="_blank" rel="noopener noreferrer">
        {t('menu_first_steps_installation')}
      </a>
      <br />
      <a href={books[lang]} target="_blank" rel="noopener noreferrer">
        {t('menu_first_steps_book')}
      </a>
      <br />
      <a href={keyCommands[lang]} target="_blank" rel="noopener noreferrer">
        {t('menu_first_steps_key_commands')}
      </a>
    </div>
  );
}

export default Start;
