/* eslint-disable import/no-anonymous-default-export */
/* The Scripts are base-64 encoded (window.btoa(str);) */
export default [
  // Simple
  { name: 'Simple' },
  {
    content:
      'ZGVmIG1haW4oKToKICAgIHNldENvbG9yKDAsIDE1MCwgNDApICAjIHIsIGcsIGIKICAgIGRyYXdUZXh0KCJIZWxsbyBXb3JsZCIsIGZhbHNlKQogICAgZGVsYXkoMjAwMCkK',
    name: 'Hello World',
  },
  {
    content:
      'IyBUbyBiZSBhYmxlIHRvIHByaW50IHNvbWV0aGluZyB0byB5b3VyIE94b3NjcmlwdAojIGVkaXRvciwganVzdCBjb25uZWN0IHRvIHRoZSBXaUZpIGFuZCBjYWxsIHRoZQojIHByaW50IGZ1bmN0aW9uLgojIE9wZW4gdGhlIG91dHB1dCBzY3JlZW4gYXQgdGhlIGJvdHRvbSBvZiB0aGlzCiMgc2l0ZSB0byBzZWUgYWxsIG1lc3NhZ2VzIGZyb20geW91ciBPeG9jYXJkCmRlZiBtYWluKCk6CiAgICBjb25uZWN0KFdJRkkpCiAgICBwcmludCAiSGVsbG8gV29ybGQgZnJvbSB5b3VyIE94b2NhcmQhIgogICAgc2V0Q29sb3IoMCwgNDAsIDE1MCkKICAgIGRyYXdUZXh0KCJIZWxsbyBXb3JsZCIsIHRydWUpCglkZWxheSgyMDAwKQo=',
    name: 'Hello World Print to Editor',
  },
  {
    content:
      'IyBPcGVuIHRoZSBvdXRwdXQgc2NyZWVuCiMgV2hlbiB5b3UgY2xpY2sgb24gb25lIG9mIHRoZSB0aHJlZSBsZWZ0IGJ1dHRvbnMKIyB5b3Ugd2lsbCBzZWUgZGlmZmVyZW50IG1lc3NhZ2VzCmRlZiBtYWluKCk6CiAgICBjb25uZWN0KFdJRkkpCiAgICBkcmF3VGV4dCgiT0siLGZhbHNlKQogICAgZGVsYXkoNTAwKQogICAgcHJpbnQgIlByZXNzIEwxLCBMMiBvciBMMyBvbiB5b3VyIG94b2NhcmQhIgogICAgd2hpbGUgdHJ1ZToKICAgICAgICBpZiBpc0J1dHRvblByZXNzZWQoQlROX0wxKToKICAgICAgICAgICAgcHJpbnQgIkhlbGxvIGZyb20geW91ciBveG9jYXJkISIKICAgICAgICAgICAgZGVsYXkoMTAwMCkKICAgICAgICBpZiBpc0J1dHRvblByZXNzZWQoQlROX0wyKToKICAgICAgICAgICAgcHJpbnQgIlRoZSBjdXJyZW50IHRlbXBlcmF0dXJlIGlzIiwgZ2V0VGVtcGVyYXR1cmUoKSwgImNlbHNpdXMgZGVncmVlIgogICAgICAgICAgICBkZWxheSgxMDAwKQogICAgICAgIGlmIGlzQnV0dG9uUHJlc3NlZChCVE5fTDMpOgogICAgICAgICAgICBwcmludCAiVGhlIGFuZ2xlIG9mIHlvdXIgY2FyZCBpcyIsIGdldEFuZ2xlKCksICJkZWdyZWUiCiAgICAgICAgICAgIGRlbGF5KDEwMDApCiAgICAgICAgZGVsYXkoNTApCg==',
    name: 'Output on click',
  },
  {
    content:
      'ZGVmIG1haW4oKToKICAgIGRpc2FibGVBdXRvVXBkYXRlKCkKICAgIHdoaWxlIHRydWU6CiAgICAgICAgeCA9IHJhbmRvbSgwLCAzKQogICAgICAgIHkgPSByYW5kb20oMCwgMykKICAgICAgICBzZXRSYW5kb21Db2xvcigpCiAgICAgICAgZHJhd1BpeGVsKHgsIHkpCiAgICAgICAgZHJhd1BpeGVsKHgsIDcgLSB5KQogICAgICAgIGRyYXdQaXhlbCg3IC0geCwgeSkKICAgICAgICBkcmF3UGl4ZWwoNyAtIHgsIDcgLSB5KQogICAgICAgIHVwZGF0ZSgpICAgICAgIAogICAgICAgIGRlbGF5KDUwKQo=',
    name: 'Kaleidoscope',
  },
  {
    content:
      'IyBUaGlzIHNhbXBsZSBleHBsYWlucywgaG93IHRvIG1ha2Ugc29tZSBzb3VuZHMKIyBzZWxlY3Qgb25lIG9mIHRoZSBzaXggYnV0dG9ucyB0byBoZWFyIGEgdG9uZS4KZGVmIG1haW4oKToKICAgIHdoaWxlIHRydWU6CiAgICAgICAgd2hpbGUgaXNCdXR0b25QcmVzc2VkKEJUTl9MMSk6CiAgICAgICAgICAgIHBsYXlUb25lKE5PVEVfQykKICAgICAgICAgICAgZmlsbFNjcmVlbigpCiAgICAgICAgd2hpbGUgaXNCdXR0b25QcmVzc2VkKEJUTl9MMik6CiAgICAgICAgICAgIHBsYXlUb25lKE5PVEVfRCkKICAgICAgICAgICAgZmlsbFNjcmVlbigpCiAgICAgICAgd2hpbGUgaXNCdXR0b25QcmVzc2VkKEJUTl9MMyk6CiAgICAgICAgICAgIHBsYXlUb25lKE5PVEVfRSkKICAgICAgICAgICAgZmlsbFNjcmVlbigpCiAgICAgICAgd2hpbGUgaXNCdXR0b25QcmVzc2VkKEJUTl9SMSk6CiAgICAgICAgICAgIHBsYXlUb25lKE5PVEVfRikKICAgICAgICAgICAgZmlsbFNjcmVlbigpCiAgICAgICAgd2hpbGUgaXNCdXR0b25QcmVzc2VkKEJUTl9SMik6CiAgICAgICAgICAgIHBsYXlUb25lKE5PVEVfRykKICAgICAgICAgICAgZmlsbFNjcmVlbigpCiAgICAgICAgd2hpbGUgaXNCdXR0b25QcmVzc2VkKEJUTl9SMyk6CiAgICAgICAgICAgIHBsYXlUb25lKE5PVEVfQSkKICAgICAgICAgICAgZmlsbFNjcmVlbigpCiAgICAgICAgbm9Ub25lKCkKICAgICAgICBjbGVhclNjcmVlbigpCg==',
    name: 'Piano',
  },
  {
    content:
      'IyBUaGlzIHdpbGwgcHVzaCB0aGUgY3VycmVudCBhbmdsZSB0byB0aGUgb3V0cHV0IHdpbmRvdyAoZXZlcnkgMiBzZWNvbmRzKQpkZWYgbWFpbigpOgogICAgY29ubmVjdChXSUZJKQogICAgZHJhd1RleHQoIk9LIixmYWxzZSkKICAgIHdoaWxlIHRydWU6CiAgICAgICAgYSA9IGdldEFuZ2xlKCkKICAgICAgICBwcmludCAiQW5nbGU6IiwgYQogICAgICAgIGRlbGF5KDIwMDApCg==',
    name: 'Angle to output',
  },
  {
    content:
      'IyBUaGlzIHdpbGwgcHVzaCBldmVyeSB0d28gc2Vjb25kcyB0aGUgY3VycmVudCBhbmdsZSB0byB0aGUgYW5nbGUgbGlzdAojIG9wZW4gdGhlICJkYXRhIiBjYXRlZ29yeSBhbmQgZXhwbG9yZSB0aGUgYW5nbGUgdmFsdWVzIHVzaW5nIHRoZSBsaXN0IG9yIHRoZSBncmFwaApkZWYgbWFpbigpOgogICAgY29ubmVjdChXSUZJKQogICAgZHJhd1RleHQoIk9LIixmYWxzZSkKICAgIHdoaWxlIHRydWU6CiAgICAgICAgYSA9IGdldEFuZ2xlKCkKICAgICAgICBwdXNoVmFsdWUoImFuZ2xlIixhKQogICAgICAgIGRlbGF5KDIwMDApCg==',
    name: 'Angle to variable',
  },
  {
    content:
      'IyBUaGlzIGV4YW1wbGUgd2lsbCBjcmVhdGUgYSBuaWNlIHNpbmUgd2F2ZQojIG9wZW4gdGhlICJkYXRhIiBibG9jayBpbiB0aGUgYnJvd3NlciBhbmQKIyBhbmFseXNlIHRoZSB2YWx1ZXMgInNpbmUiIHdpdGggdGhlIGdyYXBoIHRvb2wKZGVmIG1haW4oKToKICAgIHN2YWx1ZXMgPSBbXQogICAgY29ubmVjdChXSUZJKQogICAgZHJhd1RleHQoIk9LIiwgZmFsc2UpCiAgICBmb3IgZGVncmVlIGluIDkwOgogICAgICAgIHN2YWx1ZXMgPSBzdmFsdWVzICsgc2luZigoZGVncmVlICogNCkgKyAxLCA1MCkKICAgIGNsZWFyVmFsdWVzKCJzaW5lIikKICAgIHB1c2hWYWx1ZXMoInNpbmUiLCBzdmFsdWVzKQo=',
    name: 'Sine wave',
  },
  // Advanced
  { name: 'Advanced' },
  {
    content:
      'IyBUaGlzIGlzIGEgcGVyc2lzdGVuY2Ugb2YgdmlzaW9uIGV4YW1wbGUgKFBPVikKIyAtIHN3aXRjaCB0byBhIGRhcmtlbmVkIHJvb20KIyAtIHRoZW4gbW92ZSB0aGUgY2FyZHMgdmVyeSBmYXN0IGZyb20gbGVmdCB0byByaWdodAojIHlvdSB3aWxsIHNlZSBhIG5pY2Ugd2F2ZSBwYWludGVkIGluIHRoZSBhaXIhCmRlZiBtYWluKCk6CiAgICB5X2xpc3QgPSBbMCwgMSwgMiwgMywgNCwgNCwgMywgMiwgMSwgMF0KICAgIGRpc2FibGVBdXRvVXBkYXRlKCkKICAgIHdoaWxlIHRydWU6IAogICAgICAgIGZvciB5IGluIHlfbGlzdDoKICAgICAgICAgICAgY2xlYXJTY3JlZW4oKQogICAgICAgICAgICBzZXRDb2xvcigyNTUsIDIwNCwgNTEpCiAgICAgICAgICAgIGRyYXdQaXhlbCgwLCB5KQogICAgICAgICAgICBzZXRDb2xvcigyNTUsIDE1NCwgMzEpCiAgICAgICAgICAgIGRyYXdQaXhlbCgwLCB5ICsgMSkKICAgICAgICAgICAgc2V0Q29sb3IoMjU1LCAxMjQsIDUpCiAgICAgICAgICAgIGRyYXdQaXhlbCgwLCB5ICsgMikKICAgICAgICAgICAgdXBkYXRlKCkK',
    name: 'POV waves',
  },
  {
    content:
      'IyB0aGlzIGV4YW1wbGUgd2lsbCByZWFkIHRoZSBkZWNpYmVsLCBjYWxjdWxhdGUgdGhlIGF2ZXJhZ2Ugb2YgdGVuIHZhbHVlcyBhbmQgdGhlbiBwdXNoZXMgZWFjaAojIHZhbHVlIHRvIHRoZSBzZXJ2ZXIuCiMgVXNhZ2U6IE9wZW4gdGhlIGRhdGEgcGFydHMgYW5kIHRoZW4gdHJ5IHRvIG1ha2Ugc29tZSBub2lzZQojIDYwZEIgPSBub3JtYWwgY29udmVyc2F0aW9uLCA5MGRCIHRyYWN0b3IsIDEyMGRCIGFtYnVsYW5jZSBzaXJlbgpkZWYgbWFpbigpOgogICAgY29ubmVjdChXSUZJKQogICAgZHJhd1RleHQoIk9LIiwgZmFsc2UpCiAgICBzdGFydE1pY3JvcGhvbmUoKQogICAgd2hpbGUgdHJ1ZToKICAgICAgICBkYiA9IDAKICAgICAgICByZXBlYXQgMTA6CiAgICAgICAgICAgIGRiID0gZGIgKyByZWFkRGVjaWJlbCgpCiAgICAgICAgZGIgPSBkYiAvIDEwCiAgICAgICAgcHVzaFZhbHVlKCJkZWNpYmVsIiwgZGIpCiAgICAgICAgZGVsYXkoMTAwMCkK',
    name: 'Decibel analyzer',
  },
  {
    content:
      'IyB0aGlzIGV4YW1wbGUgd2lsbCByZWFkIHRoZSBmcmVxdWVuY3ksIGNhbGN1bGF0ZSB0aGUgYXZlcmFnZSBvZiB0ZW4gdmFsdWVzIGFuZCB0aGVuIHB1c2hlcyBlYWNoCiMgdmFsdWUgdG8gdGhlIHNlcnZlci4KIyBVc2FnZTogT3BlbiB0aGUgZGF0YSBwYXJ0cyBhbmQgdGhlbiB0cnkgdG8gbWFrZSBzb21lIGRpZmZlcmVudCBmcmVxdWVuY2llcy4gWW91IG1heSB1c2UgeW91ciBvd24gdm9pY2Ugb3IKIyB0aGlzIHRvb2w6IGh0dHA6Ly9vbmxpbmV0b25lZ2VuZXJhdG9yLmNvbS8KZGVmIG1haW4oKToKICAgIGNvbm5lY3QoV0lGSSkKICAgIGRyYXdUZXh0KCJPSyIsIGZhbHNlKQogICAgc3RhcnRNaWNyb3Bob25lKCkKICAgIHdoaWxlIHRydWU6CiAgICAgICAgZnJlcSA9IDAKICAgICAgICByZXBlYXQgMTA6CiAgICAgICAgICAgIGZyZXEgPSBmcmVxICsgcmVhZEZyZXF1ZW5jeSgpCiAgICAgICAgZnJlcSA9IGZyZXEgLyAxMAogICAgICAgIHB1c2hWYWx1ZSgiZnJlcXVlbmN5IiwgZnJlcSkKICAgICAgICBkZWxheSgxMDAwKQo=',
    name: 'Frequency analyzer',
  },
  {
    content:
      'ZGVmIGRyYXdIZWFkKCk6CiAgICBoZWFkID0gWzBiMTExMTExMTEsCiAgICAgICAgICAgIDBiMTExMTEwMTEsCiAgICAgICAgICAgIDBiMTExMTEwMDEsCiAgICAgICAgICAgIDBiMTAwMDAwMDEsCiAgICAgICAgICAgIDBiMDAwMDAwMDAsCiAgICAgICAgICAgIDBiMTAwMDAwMDEsCiAgICAgICAgICAgIDBiMTAwMDAwMDEsCiAgICAgICAgICAgIDBiMTEwMDAwMTFdCiAgICBzZXRDb2xvcigyNTUsIDIwNCwgMTUzKQogICAgZmlsbFNjcmVlbigpCiAgICBzZXRDb2xvcigxNTMsIDAsIDApCiAgICBkcmF3SW1hZ2UoaGVhZCwgMCwgMCkKCmRlZiBkcmF3RXllcygpOgogICAgc2V0Q29sb3IoMzAsIDMwLCAzMCkKICAgIGRyYXdQaXhlbCgyLCAzKQogICAgZHJhd1BpeGVsKDUsIDMpCiAgICBzZXRDb2xvcigyMjAsIDAsIDApCiAgICBkcmF3UGl4ZWwoMywgNikKICAgIGRyYXdQaXhlbCg0LCA2KQoKZGVmIGRyYXdTbWlsZSgpOgogICAgc2V0Q29sb3IoMjIwLCAyMjAsIDIyMCkKICAgIGRyYXdMaW5lKDIsIDUsIDUsIDUpCiAgICBkcmF3TGluZSgzLCA2LCA0LCA2KQoKZGVmIG1haW4oKToKICAgIGRpc2FibGVBdXRvVXBkYXRlKCkKICAgIHdoaWxlIHRydWU6CiAgICAgICAgY2xlYXJTY3JlZW4oKQogICAgICAgIGRyYXdIZWFkKCkKICAgICAgICBkcmF3RXllcygpICAgICAgICAKICAgICAgICB1cGRhdGUoKQogICAgICAgIGRlbGF5KDEwMDApCiAgICAgICAgZHJhd1NtaWxlKCkKICAgICAgICB1cGRhdGUoKQogICAgICAgIGRlbGF5KDIwMDApCg==',
    name: 'Smile animation',
  },
  {
    content:
      'IyBUaGlzIGV4YW1wbGUgd2lsbCBwdXQgdGhlIG94b2NhcmQgaW4gc2xlZXAgbW9kZSBmb3IgMTAgc2Vjb25kcwojIHlvdSBtYXkgd2FpdCBmb3IgMTAgc2Vjb25kcyBvciBjbGljayBhbnkgYnV0dG9uIHRvIHdha2UgdGhlIG94b2NhcmQgdXAgYWdhaW4KIyBhZnRlciB0aGUgY2FyZCBzdGFydHMsIHRoZSB3YWtldXAgcmVhc29uIHdpbGwgYmUgZGlzcGxheWVkIGluIHRoZSBvdXRwdXQgd2luZG93CmRlZiBtYWluKCk6CiAgICBjb25uZWN0KFdJRkkpCiAgICB3dWMgPSBnZXRXYWtlVXBDYXVzZSgpCiAgICBzdHIgPSAiIgogICAgaWYgd3VjID49IEJUTl9MMSBhbmQgd3VjIDw9IEJUTl9SMzoKICAgICAgICBzdHIgPSAiYnV0dG9uIHByZXNzZWQiCiAgICBlbGlmIHd1YyA9PSBSRVNFVDoKICAgICAgICBzdHIgPSAidW5kZWZpbmVkIgogICAgZWxpZiB3dWMgPT0gVElNRVI6CiAgICAgICAgc3RyID0gInRpbWVyIgogICAgZWxzZToKICAgICAgICBzdHIgPSAidW5kZWZpbmVkIgogICAgcHJpbnQgIldha2UgdXAgY2F1c2U6ICIgKyBzdHIKICAgIHNsZWVwKDEwKSAgIyB0aGUgb3hvY2FyZCB3aWxsIHRha2UgYSAxMCBzZWNvbmRzIG5hcAo=',
    name: 'Sleep and wakeup',
  },
  // Expert
  { name: 'Expert' },
  {
    content:
      'IyBUaGlzIGZ1bmN0aW9uIGNyZWF0ZXMgYSBzbWFsbCBmYWRlIGFuaW1hdGlvbgojIHVzZSB0aGUgZnVuY3Rpb24gcGFyYW1ldGVycyB0byBjaGFuZ2UgdGhlIGJlaGF2aW9yIChzZWUgdGhlIG1haW4gZnVuY3Rpb24pCmRlZiBwdWxzZUltYWdlIChpbWFnZSosIGRlbGF5X2luX21zLCBzdGVwKToKICAgIHJlZF9jID0gMCAgICAgICAgICAgIyBjeWNsaW5nIGNvbG9yIHZhcmlhYmxlCiAgICBkaXJlY3Rpb24gPSBzdGVwICAgICMgZGlyZWN0aW9uIHRvIGFwcGVuZCB0byB0aGUgcmVjX2MgdmFyaWFibGU6ICsxMCAodXAgdG8gMTUwKSBvciAtMTAgKGRvd24gdG8gNTApCiAgICBydW5uaW5nID0gdHJ1ZQogICAgd2hpbGUgcnVubmluZzoKICAgICAgICBzZXRDb2xvcihyZWRfYywgMCwgMCkKICAgICAgICBkcmF3SW1hZ2UoaW1hZ2UsIDAsIDApCiAgICAgICAgcmVkX2MgPSByZWRfYyArIGRpcmVjdGlvbiAgICAKICAgICAgICBpZiBkaXJlY3Rpb24gPT0gMTA6CiAgICAgICAgICAgIGlmIHJlZF9jID4gMTUwOgogICAgICAgICAgICAgICAgZGlyZWN0aW9uID0gLTEgKiBkaXJlY3Rpb24gICAgICAgIAogICAgICAgIGlmIGRpcmVjdGlvbiA9PSAtMTA6CiAgICAgICAgICAgIGlmIHJlZF9jIDw9IDUwOgogICAgICAgICAgICAgICAgcnVubmluZyA9IGZhbHNlCiAgICAgICAgZGVsYXkoZGVsYXlfaW5fbXMpCgpkZWYgbWFpbigpOgogICAgIyBEYXRhIHN0cnVjdHVyZSBvZiB0aGUgc21pbGV5IGltYWdlCiAgICBpbWcxID0gWzBiMDAwMDAwMDAsCiAgICAgICAgICAgIDBiMDAxMDAxMDAsCiAgICAgICAgICAgIDBiMDAxMDAxMDAsCiAgICAgICAgICAgIDBiMDAwMDAwMDAsCiAgICAgICAgICAgIDBiMDAwMDAwMDAsCiAgICAgICAgICAgIDBiMDEwMDAwMTAsCiAgICAgICAgICAgIDBiMDAxMTExMDAsCiAgICAgICAgICAgIDBiMDAwMDAwMDBdCiAgICB3aGlsZSB0cnVlOgogICAgICAgIHJlcGVhdCAzOgogICAgICAgICAgICBwdWxzZUltYWdlKGltZzEsIDIwLCAxMCkKICAgICAgICByZXBlYXQgNjoKICAgICAgICAgICAgcHVsc2VJbWFnZShpbWcxLCAxMCwgMTApCiAgICAgICAgcmVwZWF0IDEyOgogICAgICAgICAgICBwdWxzZUltYWdlKGltZzEsIDUsIDEwKQo=',
    name: 'Pulsating image',
  },
  {
    content:
      'IyB0aGlzIGlzIGFuIGV4YW1wbGUgd2hvIHRvIHVzZSB0aGUgYWNjZWxlcmF0aW9uIHNlbnNvciB0byBtb3ZlIGFuIG9iamVjdAojIHR1cm4gdGhlIG94b2NhcmRzIHRvIHRoZSBsZWZ0IGFuZCB0byB0aGUgcmlnaHQgdG8gbW92ZSB0aGUgYmFyIGF0IHRoZSBib3R0b20KIyBvZiB0aGUgZGlzcGxheS4gV2hlbiB3ZSBkZXRlY3QgdGhlIHBpeGVsLCB0aGUgc2NyZWVuIGZsYXNoZXMgd2hpdGUKZGVmIG1haW4oKToKICAgIHB4ID0gMAogICAgcHkgPSAwCiAgICByYWNrZXRfeCA9IDAKICAgIHRpY2sgPSAwCiAgICBkaXNhYmxlQXV0b1VwZGF0ZSgpICAgICAjIHRoaXMgd2lsbCByZWR1Y2UgZmxpY2tlcmluZwogICAgd2hpbGUgdHJ1ZToKICAgICAgICBjbGVhclNjcmVlbigpCiAgICAgICAgc2V0Q29sb3IoMCwgMTAwLCAxNTUpCiAgICAgICAgZHJhd1BpeGVsKHB4LCBweSkKICAgICAgICBpZiB0aWNrICUgMiA9PSAwOiAgICMgdGhpcyB3aWxsIHNsb3cgZG93biB0aGUgcGl4ZWwgYW5pbWF0aW9uCiAgICAgICAgICAgIHB5KysKICAgICAgICB0aWNrKysKICAgICAgICBpZiBweSA+PSA4OgogICAgICAgICAgICBweCA9IHJhbmRvbSgwLCA3KQogICAgICAgICAgICBweSA9IDAKICAgICAgICBjdXJzb3JfeCA9IGdldEFjY2VsZXJvbWV0ZXJDdXJzb3IoQVhJU19YKSAjIHdlIGdldCB0aGUgeCBjdXJzb3IKICAgICAgICBzZXRDb2xvcigxMDAsIDEwMCwgMCkKICAgICAgICBkcmF3UGl4ZWwoY3Vyc29yX3ggLSAxLCA3KQogICAgICAgIGRyYXdQaXhlbChjdXJzb3JfeCwgNykKICAgICAgICBkcmF3UGl4ZWwoY3Vyc29yX3ggKyAxLCA3KQogICAgICAgICMgdGhpcyBpcyB0aGUgaGl0IGNvbmRpdGlvbiAtIHdlIHNpbXBseSBmbGFzaCB0aGUgc2NyZWVuCiAgICAgICAgaWYgcHkgPT0gNyAmJiAocHggPT0gY3Vyc29yX3gtMSB8fCBweCA9PSBjdXJzb3JfeCB8fCBweCA9PSBjdXJzb3JfeCsxKToKICAgICAgICAgICAgc2V0Q29sb3IoMjAwLCAyMDAsIDIwMCkKICAgICAgICAgICAgZmlsbFNjcmVlbigpCiAgICAgICAgdXBkYXRlKCkKICAgICAgICBkZWxheSgxMDApCg==',
    name: 'Catch the pixel',
  },
  {
    content:
      'ZGVmIHNoaWZ0X3N0YXJzKCk6CiAgICBzaGlmdF94ID0gMAogICAgc2hpZnRfeSA9IDAKICAgIHN0YXJfcGl4ZWxfeCA9IDAKICAgIHN0YXJ0X3BpeGVsX3kgPSAwCiAgICBmb3Igc2hpZnRfeCBpbiA4OgogICAgICAgIGZvciBpIGluIDg6CiAgICAgICAgICAgIHNoaWZ0X3kgPSA3IC0gaQogICAgICAgICAgICBwaXhlbCA9IFtdCiAgICAgICAgICAgIHJlZF92YWx1ZSA9IHJlYWRQaXhlbChSRUQsIHNoaWZ0X3gsIHNoaWZ0X3kpCiAgICAgICAgICAgIGlmIHJlZF92YWx1ZSAhPSAwOgogICAgICAgICAgICAgICAgc3Rhcl9waXhlbF94ID0gc2hpZnRfeAogICAgICAgICAgICAgICAgc3RhcnRfcGl4ZWxfeSA9IHNoaWZ0X3kKICAgICAgICAgICAgICAgIHN0YXJ0X3BpeGVsX3krKwogICAgICAgICAgICAgICAgc2V0Q29sb3IoMCwgMCwgMCkKICAgICAgICAgICAgICAgIGRyYXdQaXhlbChzaGlmdF94LCBzaGlmdF95KQogICAgICAgICAgICAgICAgc2V0Q29sb3IocmVkX3ZhbHVlLCByZWRfdmFsdWUsIHJlZF92YWx1ZSkKICAgICAgICAgICAgICAgIGRyYXdQaXhlbChzdGFyX3BpeGVsX3gsIHN0YXJ0X3BpeGVsX3kpCgpkZWYgbWFpbigpOgogICAgZGlzYWJsZUF1dG9VcGRhdGUoKQogICAgd2hpbGUgdHJ1ZToKICAgICAgICBjb2xvciA9IHJhbmRvbSgyMCwgMTgwKQogICAgICAgIHNldENvbG9yKGNvbG9yLCBjb2xvciwgY29sb3IpCiAgICAgICAgZHJhd1BpeGVsKHJhbmRvbSgwLCA4KSwgMCkKICAgICAgICBzaGlmdF9zdGFycygpCiAgICAgICAgdXBkYXRlKCkK',
    name: 'Stars animation',
  },
  {
    content:
      'IyBUaGlzIHNhbXBsZSBzaW11bGF0ZXMgZmlyZQoKIyB0aGlzIHdpbGwgZ2VuZXJhdGUgdGhlIHBhcnRpY2xlIGxpc3QKZGVmIGdlbmVyYXRlUGFydGljbGUoeCosIHkqLCBsaWZlKiwgbGlzdF9zaXplKToKICAgIHJlcGVhdCBsaXN0X3NpemU6ICAgICAgICAgICAgICAgIyBudW1iZXIgb2YgZ2VuZXJhdGVkIHBhcnRpY2xlcwogICAgICAgIHkgPSB5ICsgNyAgICAgICAgICAgICAgICAgICAjIGVhY2ggcGFydGljbGUgc3RhcnRzIGF0IHRoZSBib3R0b20gb2YgdGhlIHNjcmVlbgogICAgICAgIHggPSB4ICsgcmFuZG9tKDAsIDcpICAgICAgICAjIGFueSBwb3NpdGlvbgogICAgICAgIGxpZmUgPSBsaWZlICsgcmFuZG9tKDQsIDYpICAjIHRoaXMgaXMgdGhlIGFnZSBvZiB0aGUgcGFydGljbGUKCiMgdGhpcyBkZXN0cm95cyBlbmQgb2YgbGlmZSBwYXJ0aWNsZXMgYW5kIGNyZWF0ZXMgbmV3IG9uZXMKZGVmIHVwZGF0ZVBhcnRpY2xlKHgqLCB5KiwgbGlmZSopOgogICAgZm9yIGkgaW4gc2l6ZSh4KToKICAgICAgICBpZiB5W2ldID09IDAgb3IgbGlmZVtpXSA9PSAwOgogICAgICAgICAgICB5W2ldID0gNwogICAgICAgICAgICB4W2ldID0gcmFuZG9tKDAsIDcpCiAgICAgICAgICAgIGxpZmVbaV0gPSByYW5kb20oNCwgNikKICAgIAojIHRoaXMgd2lsbCBtb3ZlIHRoZSBwYXJ0aWNsZXMgYW5kIHVwZGF0ZXMgdGhlIGxpZmUgY291bnRlcgpkZWYgaXRlcmF0ZVBhcnRpY2xlKHgqLCB5KiwgbGlmZSopOgogICAgZm9yIGkgaW4gc2l6ZSh4KToKICAgICAgICBpZiB5W2ldID4gMCBhbmQgbGlmZVtpXSA+IDA6CiAgICAgICAgICAgIHlbaV0gPSB5W2ldIC0gMQogICAgICAgICAgICBsaWZlW2ldID0gbGlmZVtpXSAtIDEKICAgICAgICAgICAgCmRlZiBtYWluKCk6CiAgICBhcnJfeCA9IFtdCiAgICBhcnJfeSA9IFtdCiAgICBhcnJfbGlmZSA9IFtdCiAgICBkaXNhYmxlQXV0b1VwZGF0ZSgpCiAgICBnZW5lcmF0ZVBhcnRpY2xlKGFycl94LCBhcnJfeSwgYXJyX2xpZmUsIDQwKQogICAgd2hpbGUgdHJ1ZToKICAgICAgICBmb3IgaSBpbiBzaXplKGFycl94KToKICAgICAgICAgICAgc2V0Q29sb3IoYXJyX2xpZmVbaV0gKiA0MCwgYXJyX2xpZmVbaV0gKiAxNSwgMCkKICAgICAgICAgICAgZHJhd1BpeGVsKGFycl94W2ldLCBhcnJfeVtpXSkKICAgICAgICBpdGVyYXRlUGFydGljbGUoYXJyX3gsIGFycl95LCBhcnJfbGlmZSkKICAgICAgICB1cGRhdGVQYXJ0aWNsZShhcnJfeCwgYXJyX3ksIGFycl9saWZlKQogICAgICAgIHVwZGF0ZSgpCiAgICAgICAgZGVsYXkoMTApCg==',
    name: 'Fire simulator',
  },
];
