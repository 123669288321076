import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Components */

/* Store */

/* Styles */
import './TopInfoBar.css';

class InfoBar extends React.Component {
  render() {
    const { i18n } = this.props;

    return (
      <div className="top_info_bar">
        <h3>
          {i18n.t('top_banner_text')}{' '}
          <a href="https://oxoscript.com/" target="_blank" rel="noreferrer">
            www.oxoscript.com
          </a>
        </h3>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InfoBar));
