import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* Components */

/* Store */

/* Styles */
import './Feedback.css';

const imprintLinks = {
  de: 'https://oxocard.ch/de/impressum/',
  en: 'https://oxocard.ch/de/imprint/',
  fr: 'https://oxocard.ch/fr/informations-legales/',
};

function Feedback() {
  const { t } = useTranslation();
  const lang = useSelector(state => state.settings.lang);
  return (
    <div className="feedback_wrapper">
      <p>{t('menu_feedback_text')}</p>
      <br />
      <div><a href="mailto: oxoscript@oxocard.ch">{t('menu_feedback_link')}</a></div>
      <br />
      <div className="impressum"><a href={imprintLinks[lang]} target="_blank" rel="noopener noreferrer">{t('menu_feedback_imprintLink')}</a></div>
    </div>
  );
}


export default Feedback;
