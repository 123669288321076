import React, { useEffect } from 'react';

/* Components */

/* Store */

/* Styles */
import './OutputPanel.css';

function OutputPanel({ output }) {
  const getDate = timestamp => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = '0' + date.getMinutes();
    const seconds = '0' + date.getSeconds();
    const microseconds = '00' + date.getMilliseconds();
    const formattedTime =
      hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2) + '.' + microseconds.substr(-3);
    return formattedTime;
  };

  useEffect(() => {
    const dataField = document.querySelector('.output_panel_data');
    dataField.scrollTop = dataField.scrollHeight;
  });

  return (
    <div className="output_panel">
      <div className="output_panel_data">
        {output.map((arr, i) => (
          <div className="output_panel_message">
            <pre key={i}>
              <span className="output_panel_timestamp">{`${getDate(arr.created_at)} `}</span>
            </pre>
            <pre>
              <span className="output_panel_data">{arr.content}</span>
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OutputPanel;
