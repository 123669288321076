import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { shareScript } from '../../store/ducks/editor';
import { ui } from 'OxocardCore';

/* Components */

/* Store */
import { setFilename, saveFile, setContent } from '../../store/ducks/editor';

/* Styles */
import './TopBar.css';

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.fileRef = createRef();
  }

  openFileDialog = () => this.fileRef.current.click();

  onOpen = evt => {
    const file = evt.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = e => {
      const contents = e.target.result;
      this.props.setFilename(file.name);
      this.props.setContent(contents);
    };

    reader.readAsText(file);
  };

  handleSubmit = evt => evt.preventDefault();

  onSave = () => this.props.saveFile(this.props.editorContent, this.props.filename);

  onShare = () => {
    this.props
      .shareScript()
      .then(res => {
        const url = new URL(window.location.href);
        const link = url.origin + '?load-hash=' + res.hash;
        const { i18n } = this.props;
        const popup = new ui.Popup({
          title: i18n.t('popup_shared_script_title'),
          mood: 'positive',
          content: `<p>${i18n.t('popup_shared_script_body')}</p>
          <br/>
          <a href="${link}" class="share_link">${link}</s>`,
          buttons: [
            {
              id: 'close',
              class: 'negative',
              shouldClose: true,
              text: i18n.t('popup_shared_script_cancel'),
              callback: () => {},
            },
            {
              id: 'email',
              class: 'positive',
              shouldClose: true,
              text: i18n.t('popup_shared_script_email'),
              callback: () => {
                const url = [
                  'mailto:?subject=',
                  i18n.t('popup_shared_script_email_subject'),
                  `&body=${link}`,
                ].join('');
                window.open(url, '_blank');
              },
            },
            {
              id: 'copy',
              class: 'positive',
              shouldClose: false,
              text: i18n.t('popup_shared_script_copy'),
              callback: button => {
                var dummy = document.createElement('textarea');
                document.body.appendChild(dummy);
                dummy.value = link;
                dummy.select();
                document.execCommand('copy');
                document.body.removeChild(dummy);
                button.element.innerHTML = i18n.t('popup_shared_script_copied');
              },
            },
          ],
        });
        popup.show();
      })
      .catch(error => console.log(error));
  };

  onClear = () => {
    const { i18n } = this.props;
    if (this.props.unsavedContent) {
      const popup = new ui.Popup({
        title: i18n.t('popup_unsaved_changes_title'),
        mood: 'negative',
        failure: true,
        content: i18n.t('popup_unsaved_changes_body'),
        buttons: [
          {
            class: 'negative',
            text: i18n.t('popup_unsaved_changes_cancel'),
            shouldClose: true,
            callback() {},
          },
          {
            class: 'positive',
            text: i18n.t('popup_unsaved_changes_continue'),
            shouldClose: true,
            callback: () => {
              this.props.setFilename(i18n.t('top_bar_new_script_name'));
              this.props.setContent(
                'def main():\n\twhile true:\n\t\tsetRandomColor()\n\t\tdrawText("Hello World", true)\n\t\tdelay(500)\n'
              );
            },
          },
        ],
      });
      popup.show();
    } else {
      this.props.setFilename(i18n.t('top_bar_new_script_name'));
      this.props.setContent(
        'def main():\n\twhile true:\n\t\tsetRandomColor()\n\t\tdrawText("Hello World", true)\n\t\tdelay(500)\n'
      );
    }
  };

  render() {
    const { i18n } = this.props;
    return (
      <div className={`top_bar ${this.props.theme}`}>
        <div className="top_bar_left">
          <div className="top_bar_logo" />
          <form onSubmit={this.handleSubmit}>
            <input
              aria-label="Script name input"
              id="top_bar_input"
              type="text"
              value={this.props.filename}
              onChange={evt => {
                this.props.setFilename(evt.target.value);
              }}
              autoComplete="off"
            />
          </form>
        </div>
        <div className="top_bar_right">
          <button
            type="button"
            aria-label="Share script"
            className="top_bar_btn"
            onClick={this.onShare}
          >
            <div className="icon share"></div>
            <p>{i18n.t('top_bar_button_share')}</p>
          </button>
          <button
            type="button"
            aria-label="Clear script"
            className="top_bar_btn"
            onClick={this.onClear}
          >
            <div className="icon new"></div>
            <p>{i18n.t('top_bar_button_new')}</p>
          </button>
          <button
            type="button"
            aria-label="Save script"
            className="top_bar_btn"
            onClick={this.onSave}
          >
            <div className="icon save"></div>
            <p>{i18n.t('top_bar_button_save')}</p>
          </button>
          <button
            type="button"
            aria-label="Open script"
            className="top_bar_btn"
            onClick={this.openFileDialog}
          >
            <div className="icon open"></div>
            <p>{i18n.t('top_bar_button_open')}</p>
          </button>
          <input
            aria-label="Open script name not visible"
            type="file"
            onChange={this.onOpen}
            ref={this.fileRef}
            style={{ display: 'none' }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ editor, settings }) {
  return {
    theme: settings.editorTheme,
    filename: editor.filename,
    editorContent: editor.content,
    unsavedContent: editor.unsavedContent,
  };
}

const mapDispatchToProps = {
  setFilename,
  saveFile,
  setContent,
  shareScript,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TopBar));
