import { register } from '../../api';

/* Actions */
const CLIENT_REGISTER_SUCCESS = 'client_register_success';
const CLIENT_REGISTER_FAIL = 'client_register_fail';

const initialState = {
  hmacId: '',
  hmacSecret: '',
};

/* Reducer */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLIENT_REGISTER_FAIL:
      return { ...initialState };
    case CLIENT_REGISTER_SUCCESS:
      const { payload } = action;
      const { hmacId, hmacSecret } = payload;
      return {
        ...state,
        hmacId,
        hmacSecret,
      };
    default:
      return state;
  }
}

/* Action Creators */
export function registerClientSuccess(client) {
  return { type: CLIENT_REGISTER_SUCCESS, payload: client };
}

export function registerClientFail() {
  return { type: CLIENT_REGISTER_FAIL };
}

export function registerClient() {
  return async dispatch => {
    try {
      const res = await register();
      const { hmacId, hmacSecret } = res;
      const client = {
        hmacId,
        hmacSecret,
      };
      localStorage.setItem('client', JSON.stringify(client));
      dispatch(registerClientSuccess(client));
    } catch (e) {
      dispatch(registerClientFail(e));
      throw new Error(e);
    }
  };
}

export function loadClient() {
  return dispatch => {
    let client = localStorage.getItem('client');
    if (client) {
      try {
        client = JSON.parse(client);
        dispatch(registerClientSuccess(client));
      } catch (e) {
        return null;
      }
      return client;
    }
    return null;
  };
}
