/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* ---------- def main() ---------- */
  main_description:
    'The main function is the entry point of your program. It often consists of setup code that runs only once and code in a forever loop that runs repeatedly.',
  /* ---------- + / - / * / % / / ---------- */
  math_description:
    'All common math operators can be used. The % (modulo) returns the rest of a division.',
  /* ---------- [lists] ---------- */
  list_description: 'Lists are a great way to collect and work with multiple values.',
  list_example_name_empty: '[]',
  list_example_description_empty: 'You can initialize an empty list.',
  list_example_name_values: '[1, 2]',
  list_example_description_values: 'You can initialize a list with values.',
  list_example_name_append: '[1, 2] + 3',
  list_example_description_append: 'You can append single values or lists to a list.',
  list_example_name_read: 'myList[2]',
  list_example_description_read:
    'You can read an entry of your list with a defined index (starting from 0).',
  list_example_name_size: 'size / min / max / avg',
  list_example_description_size:
    'You can read the size, min, max and avg (average) value of a list by calling the corresponding function.',
  /* ---------- "strings" ---------- */
  string_description: 'Strings represent text in the oxoscript language',
  string_example_name_empty: '""',
  string_example_description_empty: 'You can initialize an empty string.',
  string_example_name_values: '"Hello world"',
  string_example_description_values: 'You can initialize a string with values.',
  string_example_name_append: '"Hello" + "world"',
  string_example_description_append: 'You can append values and strings to a string.',
  /* ---------- if / elif / else ---------- */
  if_description:
    'Conditional statements are used to make decisions. They can be formulated with an if, an elif and/or an else.',
  if_example_name_if: 'if',
  if_example_description_if:
    'The body of an if will only be executed when the defined condition is true.',
  if_example_name_if_elif_else: 'elif',
  if_example_description_if_elif_else:
    'The body of an elif will only be executed when the defined if condition was false and the elif condition is true.',
  if_example_name_if_else: 'else',
  if_example_description_if_else:
    'The body of an else will only be executed when the defined if and elif conditions were false.',
  /* ---------- while ---------- */
  while_description:
    'The body of a while loop will be executed as long as the defined condition is true.',
  /* ---------- repeat ---------- */
  repeat_description: 'The body of a repeat loop will be executed for the defined number of times.',
  /* ---------- for ---------- */
  for_description:
    'The for statement can be used to loop over a list of elements. It consits of a variable and an expression. Whereas the variable is updated after every passing of the loop with the next value of your expression. An expression can either be a number, a list or a variable.',
  /* ---------- break / continue ---------- */
  break_continue_description:
    'The break and continue statements can only be used within a loop. If break is called, the loop is left instantly. When continue is called, the rest of the code in the loop is skipped and the loop starts with the next pass.',
  /* ---------- ++/-- ---------- */
  add_sub_description: 'The ++ adds 1 to and the -- subtracts 1 from the pre appended variable.',
  /* ---------- Comparison operators ---------- */
  comp_op_description: 'Comparison operators are used in if, while and for statements.',
  comp_op_example_name_equ: '==',
  comp_op_example_description_equ: 'Is true when left side is the same as right side.',
  comp_op_example_name_unequ: '!=',
  comp_op_example_description_unequ: 'Is true when left side is not the same as right side.',
  comp_op_example_name_smaller: '< / <=',
  comp_op_example_description_smaller:
    'Is true when left side is smaller than (or smaller or equal than) right side.',
  comp_op_example_name_bigger: '> / >=',
  comp_op_example_description_bigger:
    'Is true when left side is bigger than (or bigger or equal than) right side.',
  comp_op_example_name_and: '&& / and',
  comp_op_example_description_and: 'Is only true when left side and right side are true.',
  comp_op_example_name_or: '|| / or',
  comp_op_example_description_or:
    'Is true when either left side or right side is true or if both are true.',
  /* ---------- not ---------- */
  not_description: 'Negates given logic value. True becomes false and vice versa.',
  /* ---------- def functions() ---------- */
  function_description:
    'Create a function if you want to execute some code multiple times or just want to improve the structure of your program.',
  function_example_name_no_params: 'def myFunction():',
  function_example_description_no_params:
    'Insert a function either above or below your main function.',
  function_example_name_params: 'def myFunction(p1, p2):',
  function_example_description_params:
    'You can pass parameters to a function and also return a value.',
  function_example_name_by_ref: 'def myFunction(list*):',
  function_example_description_by_ref:
    'Lists and strings must always be passed as a reference (with a *). You can not return a list from a function. But when you pass something by reference you can directly change its content.',
  /* ---------- 0b / 0x ---------- */
  bit_hex_description:
    'A decimal value can also be written as a binary (0b) or hexadecimal (0x) value.',
  /* ---------- Bit operators ---------- */
  bit_op_description: 'Bit operators can be used to efficiently handle byte data.',
  bit_op_example_name_shift_l: '<<',
  bit_op_example_description_shift_l:
    'Shifts first value to the left by the second value. 0 will be shifted in.',
  bit_op_example_name_shift_r: '>>',
  bit_op_example_description_shift_r:
    'Shifts first value to the right by the second value. 1 will be shifted in when the first bit is 1 (value is negative) or 0 otherwise.',
  bit_op_example_name_and: '&',
  bit_op_example_description_and:
    'Calculates a new value by linking the first value with a logic AND to the second value.\n\n0 AND 0 = 0\n0 AND 1 = 0\n1 AND 0 = 0\n1 AND 1 = 1',
  bit_op_example_name_or: '|',
  bit_op_example_description_or:
    'Calculates a new value by linking the first value with a logic OR to the second value.\n\n0 OR 0 = 0\n0 OR 1 = 1\n1 OR 0 = 1\n1 OR 1 = 1',
  bit_op_example_name_xor: '^',
  bit_op_example_description_xor:
    'Calculates a new value by linking the first value with a logic XOR to the second value.\n\n0 XOR 0 = 0\n0 XOR 1 = 1\n1 XOR 0 = 1\n1 XOR 1 = 0',
};
