import React from 'react';

/* Components */

/* Store */

/* Styles */
import './Graph.css';
import Plot from 'react-plotly.js';

function Graph({ name, data, onClose }) {
  data = data || [];
  const xData = data.map(item => new Date(item.timestamp));
  const yData = data.map(item => item.value);
  return (
    <div className="graph_wrapper" onClick={onClose}>
      <div
        className="graph_content"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Plot
          useResizeHandler={true}
          data={[
            {
              x: xData,
              y: yData,
              type: 'scatter',
              mode: 'lines',
              line: {
                color: '#62b22e',
                width: 4,
              },
            },
          ]}
          layout={{
            title: `Plot of variable ${name}`,
            xaxis: { tickformat: '%I:%M:%S.%L' },
          }}
        />
      </div>
    </div>
  );
}

export default Graph;
