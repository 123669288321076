import { net, config as coreConfig } from 'OxocardCore';
import { getBrowserInfo } from './util';

const api = {
  hmacId: '',
  hmacSecret: '',
};

/* POST client/register
      body: browserType, browserVersion
      return: hmacId, hamcSecret        */
export function register() {
  const { browser, version } = getBrowserInfo();
  const config = {
    path: 'client/register',
    postData: { browserType: browser, browserVersion: version },
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* POST client/pair
      body: colorCode
      return: hmacIdCard        */
export function pair(colorCode) {
  const config = {
    path: 'client/pair',
    ...api,
    postData: { colorCode },
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* POST client/transferPairing
      body: hmacIdClient
      return: card info        */
export function transferPairing(hmacIdClient) {
  const config = {
    path: 'client/transferPairing',
    ...api,
    postData: { hmacIdClient },
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* POST client/unpair
      body: colorCode
      return: hmacIdCard        */
export function unpair() {
  const config = {
    path: 'client/unpair',
    ...api,
    postData: {},
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* POST client/setScript
      body: name, script: type, code
      return: none                    */
export function setScript(name, code) {
  const config = {
    path: 'client/setScript',
    ...api,
    postData: { name, type: 'byteCode', content: '', byteContent: code },
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* POST client/shareCode
      body: name, type, code
      return: none                    */
export function shareCode(name, code) {
  const config = {
    path: 'client/shareCode',
    ...api,
    postData: { name, type: 'script', content: code, byteContent: '' },
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* GET client/getSharedCode
      return: script                    */
export function getSharedCode(hash) {
  const config = {
    path: `client/getSharedCode`,
    parameters: { hash },
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* GET client/getCardInfo
      return: none                    */
export function getCardInfo() {
  const config = {
    path: 'client/getCardInfo',
    ...api,
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* GET client/receiveMessages?type=log
      return: messages                 */
export function getCardMessages() {
  const config = {
    path: 'client/receiveMessages',
    parameters: { type: 'log' },
    ...api,
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* GET /client/getDataHeaders
      return: data headers      */
export function getDataHeaders() {
  const config = {
    path: 'client/getDataHeaders',
    ...api,
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* GET /client/getData?key=[key]
      return: data array      */
export function getData(key) {
  const config = {
    path: 'client/getData',
    parameters: { key },
    ...api,
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* POST client/clearData
      body: key          */
export function clearData(key) {
  const config = {
    path: 'client/clearData',
    ...api,
    postData: { key },
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* POST client/clearDataHeader
      body: key          */
export function clearDataHeader(key) {
  const config = {
    path: 'client/clearDataHeader',
    ...api,
    postData: { key },
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

/* POST client/resetCard
      body:                     */
export function resetCard() {
  const config = {
    path: 'client/resetCard',
    ...api,
    postData: {},
  };
  const xhr = new net.Request();
  return xhr.makeRequest(config);
}

export default function configureApi(store) {
  const { API_HOST, API_PORT, API_PREFIX, FORCE_SSL } = window.env;
  if (API_HOST) {
    const port = API_PORT || 80;
    let protocol = window.location.protocol;
    if (FORCE_SSL) {
      protocol = 'https:';
    }
    coreConfig.apiHost = `${protocol}//${API_HOST}:${port}`;
    coreConfig.host = API_HOST;
  }
  coreConfig.apiPrefix = `${API_PREFIX || ''}/`;

  const unsubscribe = store.subscribe(() => {
    const { client } = store.getState();
    const { hmacId, hmacSecret } = client;
    if (hmacId && hmacSecret) {
      api.hmacId = hmacId;
      api.hmacSecret = hmacSecret;
    } else {
      api.hmacId = '';
      api.hmacSecret = '';
    }
  });
  return unsubscribe;
}
