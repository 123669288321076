import React from 'react';
import { connect } from 'react-redux';

import syntaxData from './syntaxData';

/* Components */
import SyntaxList from './SyntaxList';

/* Store */

/* Styles */
import './Syntax.css';

const filter = (data, filterString) => {
  if (!filterString.length) {
    return data;
  }

  const filterStringLow = filterString.toLowerCase();

  let allFilteredSyntax = data.filter(syntax =>
    syntax.name.toLowerCase().includes(filterStringLow)
  );

  return allFilteredSyntax;
};

class Syntax extends React.PureComponent {
  state = {
    filterString: '',
    syntaxData,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.searchString !== state.filterString) {
      return {
        filterString: props.searchString,
        syntaxData: filter(syntaxData, props.searchString),
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.props.isOpen === false) {
      this.setState({ activeFunctionCategory: -1 });
    }
  }

  render() {
    return (
      <div className="syntax_wrapper">
        <SyntaxList
          syntax={this.state.syntaxData}
          isOpen={this.props.isOpen}
          filterString={this.state.filterString}
        />
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return { theme: settings.editorTheme };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Syntax);
