import { getDataHeaders, getData, clearData, clearDataHeader } from '../../api';
import { compareArrays } from '../../util';

/* Actions */
const SET_HEADER = 'SET_HEADER';
const SET_LATEST_DATA = 'SET_LATEST_DATA';
const SET_NEW_DATA = 'SET_NEW_DATA';
const SET_VARIABLE = 'SET_VARIABLE';
const RESET_VARIABLES = 'RESET_VARIABLES';

const initialState = {
  keys: [],
  values: {},
  latestData: {},
  newData: {},
  hashes: {},
};

/* Reducer */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_HEADER:
      return { ...state, keys: action.payload.keys, hashes: action.payload.hashes };

    case SET_LATEST_DATA:
      return { ...state, latestData: action.payload };

    case SET_NEW_DATA:
      return { ...state, newData: action.payload };

    case SET_VARIABLE: {
      let values = { ...state.values };
      values[action.payload.key] = action.payload.value;
      return { ...state, values };
    }

    case RESET_VARIABLES:
      return { ...initialState };

    default:
      return state;
  }
}

/* Action Creators */
export function setVariableHeader(keys, hashes) {
  return { type: SET_HEADER, payload: { keys, hashes } };
}

export function setLatestData(latestData) {
  return { type: SET_LATEST_DATA, payload: latestData };
}

export function setNewData(newData) {
  return { type: SET_NEW_DATA, payload: newData };
}

export function setVariable(key, value) {
  return { type: SET_VARIABLE, payload: { key, value } };
}

export function resetVariables() {
  return { type: RESET_VARIABLES };
}

export function loadVariableHeaders() {
  return (dispatch, getState) => {
    getDataHeaders()
      .then(res => {
        res = res || [];
        let redLatestData = {};
        let redNewData = {};
        let hashes = {};
        const keys = res.map(header => {
          redLatestData[header.key] = header.lastDataValue;
          redNewData[header.key] = header.numberOfNewData;
          hashes[header.key] = header.readHash;
          return header.key;
        });
        /* Only set the latestData object if it changed */
        if (!(JSON.stringify(getState().variables.latestData) === JSON.stringify(redLatestData))) {
          dispatch(setLatestData(redLatestData));
        }
        /* Only set the newData object if it changed */
        if (!(JSON.stringify(getState().variables.newData) === JSON.stringify(redNewData))) {
          dispatch(setNewData(redNewData));
        }
        /* Set the headers only if they are different from the current state */
        if (!compareArrays(getState().variables.keys, keys)) {
          dispatch(setVariableHeader(keys, hashes));
        }
      })
      .catch(e => console.log(e));
  };
}

export function loadVariable(key) {
  return (dispatch, getState) => {
    getData(key)
      .then(res => {
        const valuesForKey = getState().variables.values[key];
        /* Set the values only if they are different from the current state */
        if (
          (!res && valuesForKey) ||
          (res &&
            res.length &&
            (!valuesForKey ||
              valuesForKey.length === 0 ||
              valuesForKey[valuesForKey.length - 1].timestamp < res[res.length - 1].timestamp))
        ) {
          dispatch(setVariable(key, res));
        }
      })
      .catch(e => console.log(e));
  };
}

export function clearVariable(key) {
  return () => {
    clearData(key).catch(e => console.log(e));
  };
}

export function deleteVariable(key) {
  return () => {
    clearDataHeader(key).catch(e => console.log(e));
  };
}
