import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Components */
import SubCategory from '../SubCategory';
import DataList from './DataList';

/* Store */
import { loadVariableHeaders } from '../../../store/ducks/variables';

/* Styles */
import './Data.css';

class Data extends React.PureComponent {
  state = {
    activeIndex: -1,
  };

  pollHeaders = () => {
    setTimeout(() => {
      if (this.props.isOpen && this.props.isPaired) {
        this.props.loadVariableHeaders();
        this.pollHeaders();
      }
    }, 1000);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen || prevProps.isPaired !== this.props.isPaired) {
      if (this.props.isOpen && this.props.isPaired) {
        this.pollHeaders();
      }
    }

    if (this.props.isOpen === false) {
      this.setState({ activeIndex: -1 });
    }

    if (prevProps.keys.length > this.props.keys.length) {
      this.setState({ activeIndex: -1 });
    }
  }

  render() {
    const { activeIndex } = this.state;
    const { i18n } = this.props;

    return (
      <div className="data_wrapper">
        <p>{i18n.t('menu_data_text')}</p>

        <h2>{i18n.t('menu_data_note_header')}</h2>
        <p>{i18n.t('menu_data_note_text')}</p>
        <br />

        {this.props.keys.map((key, i) => (
          <SubCategory
            theme={this.props.theme}
            name={key}
            key={i}
            latestValue={(this.props.latestValue[key] !== undefined) ? `${i18n.t('menu_data_latest')}: ${this.props.latestValue[key]}` : undefined}
            labelValue={this.props.newData[key]}
            isOpen={activeIndex === i}
            onClick={() => {
              this.setState({
                activeIndex: i === activeIndex ? -1 : i,
              });
            }}
          >
            <DataList
              variableName={key}
              isActive={activeIndex === i && this.props.isPaired}
              theme={this.props.theme}
            />
          </SubCategory>
        ))}
      </div>
    );
  }
}

function mapStateToProps({ card, settings, variables }) {
  return {
    theme: settings.editorTheme,
    isPaired: card.isPaired,
    keys: variables.keys,
    newData: variables.newData,
    latestValue: variables.latestData,
    values: variables.values,
  };
}

const mapDispatchToProps = { loadVariableHeaders };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Data));
