import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ui } from 'OxocardCore';
import Graph from './Graph';

/* Components */

/* Store */
import { loadVariable, clearVariable, deleteVariable } from '../../../store/ducks/variables';

/* Styles */
import './DataList.css';

class DataList extends React.Component {
  intervalID = 0;

  state = {
    showGraph: false,
  };

  getDate = timestamp => {
    const date = new Date(timestamp);
    const hours = '0' + date.getHours();
    const minutes = '0' + date.getMinutes();
    const seconds = '0' + date.getSeconds();
    const microseconds = '00' + date.getMilliseconds();
    const formattedTime =
      hours.substr(-2) +
      ':' +
      minutes.substr(-2) +
      ':' +
      seconds.substr(-2) +
      '.' +
      microseconds.substr(-3);
    return formattedTime;
  };

  downloadCSV = (fileName, data) => {
    let csv = 'Time,Value\n';
    data.forEach(row => {
      csv += [this.getDate(row.timestamp), row.value];
      csv += '\n';
    });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${fileName}.csv`;
    hiddenElement.click();
  };

  pollVariable = () => {
    this.intervalID = setTimeout(() => {
      if (this.props.isActive) {
        this.props.loadVariable(this.props.variableName);
        this.pollVariable();
      }
    }, 1000);
  };

  onCloseGraph = () => {
    this.setState({ showGraph: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive) {
      if (this.props.isActive) {
        this.props.loadVariable(this.props.variableName);
        this.pollVariable();
      }
    }

    //const dataField = document.querySelector(`.data_list_body.${this.props.variableName}`);
    //dataField.scrollTop = dataField.scrollHeight;
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { i18n } = this.props;
    return (
      <div className={`data_list_wrapper ${this.props.theme}`}>
        <div className="data_list_header">
          <div className="label">{`Variable "${this.props.variableName}"`}</div>
          <div className="controls">
            <div className="icon graph" onClick={() => this.setState({ showGraph: true })}></div>
            <div
              className="icon download"
              onClick={() =>
                this.downloadCSV(
                  this.props.variableName,
                  this.props.values[this.props.variableName]
                )
              }
            ></div>
            <div
              className="icon clear"
              onClick={() => {
                const props = this.props;
                if (this.props.values[this.props.variableName] && this.props.values[this.props.variableName].length > 0) {
                  /* Clear values popup */
                  const popup = new ui.Popup({
                    show: true,
                    title: i18n.t('popup_really_clear_data_title'),
                    mood: 'neutral',
                    content: i18n.t('popup_really_clear_data_body'),
                    buttons: [
                      {
                        class: 'negative',
                        text: i18n.t('popup_really_clear_data_cancel'),
                        shouldClose: true,
                        callback() {},
                      },
                      {
                        class: 'positive',
                        text: i18n.t('popup_really_clear_data_continue'),
                        shouldClose: true,
                        callback() {
                          props.clearVariable(props.variableName);
                        },
                      },
                    ],
                  });
                  popup.show();
                } else {
                  /* No data -> delete variable popup */
                  const popup = new ui.Popup({
                    show: true,
                    title: i18n.t('popup_really_delete_variable_title'),
                    mood: 'neutral',
                    content: i18n.t('popup_really_delete_variable_body'),
                    buttons: [
                      {
                        class: 'negative',
                        text: i18n.t('popup_really_delete_variable_cancel'),
                        shouldClose: true,
                        callback() {},
                      },
                      {
                        class: 'positive',
                        text: i18n.t('popup_really_delete_variable_continue'),
                        shouldClose: true,
                        callback() {
                          props.deleteVariable(props.variableName);
                        },
                      },
                    ],
                  });
                  popup.show();
                }
              }}
            ></div>
          </div>
        </div>

        <div className={`data_list_body ${this.props.variableName}`}>
          {this.state.showGraph && (
            <Graph
              name={this.props.variableName}
              data={this.props.values[this.props.variableName]}
              onClose={this.onCloseGraph}
            />
          )}
          {this.props.values[this.props.variableName] &&
            (this.props.values[this.props.variableName].slice(0)).reverse().map((arr, i) => (
              <div className="data_list_item_wrapper" key={i}>
                <div className="data_list_item">
                  <div className="timestamp">{this.getDate(arr.timestamp)}</div>
                  <div className="value">{arr.value}</div>
                </div>
              </div>
            ))}
        </div>
        <div className="data_hash">
          <a
            href={`https://${window.env.API_HOST}:${window.env.API_PORT}${
              window.env.API_PREFIX
            }/getData?readHash=${this.props.hashes[this.props.variableName]}`}
            className="publik_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.t('menu_data_public_link')}
          </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ variables }) {
  return {
    values: variables.values,
    hashes: variables.hashes,
  };
}

const mapDispatchToProps = { loadVariable, clearVariable, deleteVariable };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DataList));
