import React from 'react';
import { useTranslation } from 'react-i18next';

/* Components */

/* Store */

/* Styles */
import './SearchBar.css';

let timerId = null;

function SearchBar({ input, onChange }) {
  const { t } = useTranslation();

  const onValueChange = evt => {
    const input = evt.target.value;
    clearTimeout(timerId);
    timerId = setTimeout(() => onChange(input), 500);
  };

  return (
    <div className="search_bar_wrapper">
      <input
        aria-label="Search documentation input field"
        type="text"
        value={input}
        onChange={onValueChange}
        placeholder={t('menu_documentation_search')}
      />
      <div className="search_icon"></div>
    </div>
  );
}

export default SearchBar;
