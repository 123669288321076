import React from 'react';
import { connect } from 'react-redux';

import examples from './data';

/* Components */

/* Store */
import { setContent, setFilename } from '../../../store/ducks/editor';

/* Styles */
import './Examples.css';

class Examples extends React.PureComponent {
  loadExample = (name, content) => {
    let data = window.atob(content);
    this.props.setContent(data);
    this.props.setFilename(name);
  };

  render() {
    return (
      <div className="examples">
        <ul>
          {examples.map((example, i) => {
            if (example.content) {
              return (
                <li
                  className="example"
                  key={i}
                  onClick={() => this.loadExample(example.name, example.content)}
                >
                  {example.name}
                </li>
              );
            } else {
              return (
                <li className="example_category" key={i}>
                  {example.name}
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = { setContent, setFilename };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Examples);
