/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    name: 'category_utility',
    functions: [
      {
        name: 'size',
        description: 'size_description',
        returnValue: 'int',
        parameter: [{ name: 'array', type: 'int[]', description: 'size_param_array' }],
        examples: ['len = size(myArray)\n'],
      },
      {
        name: 'remove',
        description: 'remove_description',
        returnValue: null,
        parameter: [
          { name: 'array', type: 'int[]', description: 'remove_param_array' },
          { name: 'pos', type: 'int', description: 'remove_param_pos' },
        ],
        examples: ['remove(myArray, 2)\n'],
      },
      {
        name: 'findValue',
        description: 'find_value_description',
        returnValue: 'int',
        parameter: [
          { name: 'array', type: 'int[]', description: 'find_value_param_array' },
          { name: 'value', type: 'int', description: 'find_value_param_value' },
        ],
        examples: ['pos = findValue(myArray, 2)\n'],
      },
      {
        name: 'findRange',
        description: 'find_range_description',
        returnValue: 'int',
        parameter: [
          { name: 'array', type: 'int[]', description: 'find_range_param_array' },
          { name: 'searchRange', type: 'int[]', description: 'find_range_param_search_range' },
        ],
        examples: ['pos = findRange(myArray, 2)\n'],
      },
      {
        name: 'min',
        description: 'min_description',
        returnValue: 'int',
        parameter: [{ name: 'array', type: 'int[]', description: 'min_param_array' }],
        examples: ['minValue = min(list)\n'],
      },
      {
        name: 'max',
        description: 'max_description',
        returnValue: 'int',
        parameter: [{ name: 'array', type: 'int[]', description: 'max_param_array' }],
        examples: ['maxValue = max(list)\n'],
      },
      {
        name: 'avg',
        description: 'avg_description',
        returnValue: 'int',
        parameter: [{ name: 'array', type: 'int[]', description: 'avg_param_array' }],
        examples: ['avg = avg(list)\n'],
      },
      {
        name: 'sin',
        description: 'sin_description',
        returnValue: 'int',
        parameter: [
          { name: 'degree', type: 'int', description: 'sin_param_degree' },
          { name: 'factor', type: 'int', description: 'sin_param_factor' },
        ],
        examples: ['s = sin(30, 100)\n'],
      },
      {
        name: 'cos',
        description: 'cos_description',
        returnValue: 'int',
        parameter: [
          { name: 'degree', type: 'int', description: 'cos_param_degree' },
          { name: 'factor', type: 'int', description: 'cos_param_factor' },
        ],
        examples: ['c = cos(30, 100)\n'],
      },
      {
        name: 'pow',
        description: 'pow_description',
        returnValue: 'int',
        parameter: [
          { name: 'base', type: 'int', description: 'pow_param_base' },
          { name: 'exponent', type: 'int', description: 'pow_param_exponent' },
        ],
        examples: ['res = pow(2, 8)\n'],
      },
      {
        name: 'map',
        description: 'map_description',
        returnValue: 'int',
        parameter: [
          { name: 'value', type: 'int', description: 'map_param_value' },
          { name: 'a1', type: 'int', description: 'map_param_a1' },
          { name: 'a2', type: 'int', description: 'map_param_a2' },
          { name: 'b1', type: 'int', description: 'map_param_b1' },
          { name: 'b2', type: 'int', description: 'map_param_b2' },
        ],
        examples: ['res = map(64, 0, 100, 0, 255)\n'],
      },
      {
        name: 'abs',
        description: 'abs_description',
        returnValue: 'int',
        parameter: [{ name: 'value', type: 'int', description: 'abs_param_value' }],
        examples: ['res = abs(-20)\n'],
      },
    ],
  },
  {
    name: 'category_logic',
    functions: [
      {
        name: 'isButtonPressed',
        description: 'is_button_pressed_description',
        returnValue: 'int',
        parameter: [{ name: 'button', type: 'int', description: 'is_button_pressed_param_button' }],
        examples: [
          'i = isButtonPressed(BTN_L1)\n',
          'if isButtonPressed(BTN_L1):\n    drawNumber(1)\n',
        ],
      },
      {
        name: 'waitForButtonPressed',
        description: 'wait_for_button_pressed_description',
        returnValue: null,
        parameter: [
          { name: 'button', type: 'int', description: 'wait_for_button_pressed_param_button' },
        ],
        examples: ['waitForButtonPressed(BTN_L1)\n'],
      },
    ],
  },
  {
    name: 'category_variables',
    functions: [
      {
        name: 'random',
        description: 'random_description',
        returnValue: 'int',
        parameter: [
          { name: 'start', type: 'int', description: 'random_param_start' },
          { name: 'end', type: 'int', description: 'random_param_end' },
        ],
        examples: ['rand = random(1, 10)\n'],
      },
      {
        name: 'hasPersistentVariable',
        description: 'has_pers_var_description',
        returnValue: 'int',
        parameter: [{ name: 'key', type: 'int[]', description: 'has_pers_var_param_key' }],
        examples: ['hasPersistentVariable("test")\n'],
      },
      {
        name: 'savePersistentVariable',
        description: 'save_pers_var_description',
        returnValue: null,
        parameter: [
          { name: 'key', type: 'int[]', description: 'save_pers_var_param_key' },
          { name: 'value', type: 'int', description: 'save_pers_var_param_value' },
        ],
        examples: ['savePersistentVariable("test", 10)\n'],
      },
      {
        name: 'loadPersistentVariable',
        description: 'load_pers_var_description',
        returnValue: 'int',
        parameter: [{ name: 'key', type: 'int[]', description: 'load_pers_var_param_key' }],
        examples: ['val = loadPersistentVariable("test")\n'],
      },
      {
        name: 'deletePersistentVariable',
        description: 'delete_pers_var_description',
        returnValue: null,
        parameter: [{ name: 'key', type: 'int[]', description: 'delete_pers_var_param_key' }],
        examples: ['deletePersistentVariable("test")\n'],
      },
    ],
  },
  {
    name: 'category_oxocard',
    functions: [
      {
        name: 'reboot',
        description: 'reboot_description',
        returnValue: null,
        parameter: null,
        examples: ['reboot()\n'],
      },
      {
        name: 'turnOff',
        description: 'turn_off_description',
        returnValue: null,
        parameter: null,
        examples: ['turnOff()\n'],
      },
      {
        name: 'turnOffWakeUpButton',
        description: 'turn_off_wake_up_button_description',
        returnValue: null,
        parameter: [
          { name: 'L1', type: 'int', description: 'turn_off_wake_up_button_param_l1' },
          { name: 'L2', type: 'int', description: 'turn_off_wake_up_button_param_l2' },
          { name: 'L3', type: 'int', description: 'turn_off_wake_up_button_param_l3' },
          { name: 'R1', type: 'int', description: 'turn_off_wake_up_button_param_r1' },
          { name: 'R2', type: 'int', description: 'turn_off_wake_up_button_param_r2' },
          { name: 'R3', type: 'int', description: 'turn_off_wake_up_button_param_r3' },
        ],
        examples: ['turnOffWakeUpButton(true, false, false, true, false, false)\n'],
      },
      {
        name: 'configureAutoTurnOff',
        description: 'configure_auto_turn_off_description',
        returnValue: null,
        parameter: [
          { name: 'seconds', type: 'int', description: 'configure_auto_turn_off_param_seconds' },
        ],
        examples: ['configureAutoTurnOff(60)\n'],
      },
      {
        name: 'enableAutoTurnOff',
        description: 'enable_auto_turn_off_description',
        returnValue: null,
        parameter: null,
        examples: ['enableAutoTurnOff()\n'],
      },
      {
        name: 'disableAutoTurnOff',
        description: 'disable_auto_turn_off_description',
        returnValue: null,
        parameter: null,
        examples: ['disableAutoTurnOff()\n'],
      },
      {
        name: 'sleep',
        description: 'sleep_description',
        returnValue: null,
        parameter: [{ name: 'seconds', type: 'int', description: 'sleep_param_seconds' }],
        examples: ['sleep(10)\n'],
      },
      {
        name: 'getWakeUpCause',
        description: 'get_wake_up_cause_description',
        returnValue: 'int',
        parameter: null,
        examples: ['wu = getWakeUpCause()\n'],
      },
      {
        name: 'getBatteryVoltage',
        description: 'get_battery_voltage_description',
        returnValue: 'int',
        parameter: null,
        examples: ['getBatteryVoltage()\n'],
      },
      {
        name: 'isFullyCharged',
        description: 'is_fully_charged_description',
        returnValue: 'int',
        parameter: null,
        examples: ['isFullyCharged()\n'],
      },
      {
        name: 'isUsbPluggedIn',
        description: 'is_usb_plugged_in_description',
        returnValue: 'int',
        parameter: null,
        examples: ['isUsbPluggedIn()\n'],
      },
      {
        name: 'getTemperature',
        description: 'get_temperature_description',
        returnValue: 'int',
        parameter: null,
        examples: ['getTemperature()\n'],
      },
    ],
  },
  {
    name: 'category_matrix',
    functions: [
      {
        name: 'setColor',
        description: 'set_color_description',
        returnValue: null,
        parameter: [
          { name: 'r', type: 'int', description: 'set_color_param_r' },
          { name: 'g', type: 'int', description: 'set_color_param_g' },
          { name: 'b', type: 'int', description: 'set_color_param_b' },
        ],
        examples: ['setColor(255, 0, 0)\n'],
      },
      {
        name: 'setRandomColor',
        description: 'set_random_color_description',
        returnValue: null,
        parameter: null,
        examples: ['setRandomColor()\n'],
      },
      {
        name: 'setHueColor',
        description: 'set_hue_color_description',
        returnValue: null,
        parameter: [{ name: 'hue', type: 'int', description: 'set_hue_color_param_hue' }],
        examples: ['setHueColor(90)\n'],
      },
      {
        name: 'enableAutoUpdate',
        description: 'enable_auto_update_description',
        returnValue: null,
        parameter: null,
        examples: ['enableAutoUpdate()\n'],
      },
      {
        name: 'disableAutoUpdate',
        description: 'disable_auto_update_description',
        returnValue: null,
        parameter: null,
        examples: ['disableAutoUpdate()\n'],
      },
      {
        name: 'isAutoUpdate',
        description: 'is_auto_update_description',
        returnValue: 'int',
        parameter: null,
      },
      {
        name: 'update',
        description: 'update_description',
        returnValue: null,
        parameter: null,
        examples: ['update()\n'],
      },
      {
        name: 'fillScreen',
        description: 'fill_screen_description',
        returnValue: null,
        parameter: null,
        examples: ['fillScreen()\n'],
      },
      {
        name: 'clearScreen',
        description: 'clear_screen_description',
        returnValue: null,
        parameter: null,
        examples: ['clearScreen()\n'],
      },
      {
        name: 'setPixel',
        description: 'set_pixel_description',
        returnValue: null,
        parameter: [
          { name: 'x', type: 'int', description: 'set_pixel_pixel_param_x' },
          { name: 'y', type: 'int', description: 'set_pixel_pixel_param_y' },
          { name: 'r', type: 'int', description: 'set_pixel_pixel_param_r' },
          { name: 'g', type: 'int', description: 'set_pixel_pixel_param_g' },
          { name: 'b', type: 'int', description: 'set_pixel_pixel_param_b' },
        ],
        examples: ['setPixel(1, 1, 255, 0, 0)\n'],
      },
      {
        name: 'drawPixel',
        description: 'draw_pixel_description',
        returnValue: null,
        parameter: [
          { name: 'x', type: 'int', description: 'pixel_param_x' },
          { name: 'y', type: 'int', description: 'pixel_param_y' },
        ],
        examples: ['drawPixel(0, 1)\n'],
      },
      {
        name: 'clearPixel',
        description: 'clear_pixel_description',
        returnValue: null,
        parameter: [
          { name: 'x', type: 'int', description: 'pixel_param_x' },
          { name: 'y', type: 'int', description: 'pixel_param_y' },
        ],
        examples: ['clearPixel(0, 1)\n'],
      },
      {
        name: 'readPixel',
        description: 'read_pixel_description',
        returnValue: null,
        parameter: [
          { name: 'color', type: 'int', description: 'read_pixel_param_color' },
          { name: 'x', type: 'int', description: 'pixel_param_x' },
          { name: 'y', type: 'int', description: 'pixel_param_y' },
        ],
        examples: ['readPixel(RED, 0, 1)\n'],
      },
      {
        name: 'isPixelSet',
        description: 'is_pixel_set_description',
        returnValue: 'int',
        parameter: [
          { name: 'x', type: 'int', description: 'pixel_param_x' },
          { name: 'y', type: 'int', description: 'pixel_param_y' },
        ],
        examples: ['if isPixelSet(0, 1):\n    clearPixel(0, 1)\n'],
      },
      {
        name: 'fill',
        description: 'fill_description',
        returnValue: null,
        parameter: null,
        examples: ['fill()\n'],
      },
      {
        name: 'noFill',
        description: 'no_fill_description',
        returnValue: null,
        parameter: null,
        examples: ['noFill()\n'],
      },
      {
        name: 'drawLine',
        description: 'draw_line_description',
        returnValue: null,
        parameter: [
          { name: 'x1', type: 'int', description: 'draw_line_param_x1' },
          { name: 'y1', type: 'int', description: 'draw_line_param_y1' },
          { name: 'x2', type: 'int', description: 'draw_line_param_x2' },
          { name: 'y2', type: 'int', description: 'draw_line_param_y2' },
        ],
        examples: ['drawLine(0, 0, 7, 7)\n'],
      },
      {
        name: 'drawRectangle',
        description: 'draw_rectangle_description',
        returnValue: null,
        parameter: [
          { name: 'x', type: 'int', description: 'draw_rectangle_param_x' },
          { name: 'y', type: 'int', description: 'draw_rectangle_param_y' },
          { name: 'w', type: 'int', description: 'draw_rectangle_param_w' },
          { name: 'h', type: 'int', description: 'draw_rectangle_param_h' },
        ],
        examples: ['drawRectangle(2, 2, 3, 3)\n'],
      },
      {
        name: 'drawTriangle',
        description: 'draw_triangle_description',
        returnValue: null,
        parameter: [
          { name: 'x1', type: 'int', description: 'draw_triangle_param_x1' },
          { name: 'y1', type: 'int', description: 'draw_triangle_param_y1' },
          { name: 'x2', type: 'int', description: 'draw_triangle_param_x2' },
          { name: 'y2', type: 'int', description: 'draw_triangle_param_y2' },
          { name: 'x3', type: 'int', description: 'draw_triangle_param_x3' },
          { name: 'y3', type: 'int', description: 'draw_triangle_param_y3' },
        ],
        examples: ['drawTriangle(3, 0, 0, 3, 6, 3)\n'],
      },
      {
        name: 'drawCircle',
        description: 'draw_circle_description',
        returnValue: null,
        parameter: [
          { name: 'x', type: 'int', description: 'draw_circle_param_x' },
          { name: 'y', type: 'int', description: 'draw_circle_param_y' },
          { name: 'r', type: 'int', description: 'draw_circle_param_r' },
        ],
        examples: ['drawCircle(3, 3, 3)\n'],
      },
      {
        name: 'drawNumber',
        description: 'draw_number_description',
        returnValue: null,
        parameter: [{ name: 'num', type: 'int', description: 'draw_number_param_num' }],
        examples: ['drawNumber(77)\n'],
      },
      {
        name: 'drawChar',
        description: 'draw_char_description',
        returnValue: null,
        parameter: [
          { name: 'c', type: 'int', description: 'draw_char_param_c' },
          { name: 'x', type: 'int', description: 'draw_char_param_x' },
          { name: 'y', type: 'int', description: 'draw_char_param_y' },
          { name: 'isBigFont', type: 'int', description: 'draw_char_param_is_big_font' },
        ],
        examples: ["drawChar('M')\n"],
      },
      {
        name: 'drawText',
        description: 'draw_text_description',
        returnValue: null,
        parameter: [
          { name: 'text', type: 'int[]', description: 'draw_text_param_text' },
          { name: 'isBigFont', type: 'int', description: 'draw_text_param_is_big_font' },
        ],
        examples: ['drawText("Hello World", true)\n'],
      },
      {
        name: 'drawImage',
        description: 'draw_image_description',
        returnValue: null,
        parameter: [
          { name: 'image', type: 'int[]', description: 'draw_image_param_image' },
          { name: 'x', type: 'int', description: 'draw_image_param_x' },
          { name: 'y', type: 'int', description: 'draw_image_param_y' },
        ],
        examples: [
          'img = [0b00000000,\n       0b00100100,\n       0b00100100,\n       0b00000000,\n       0b00000000,\n       0b01000010,\n       0b00111100,\n       0b00000000]\n\nsetColor(0, 255, 0)\ndrawImage(img, 0, 0)\n',
        ],
      },
      {
        name: 'drawImage32',
        description: 'draw_image32_description',
        returnValue: null,
        parameter: [
          { name: 'image', type: 'int[]', description: 'draw_image32_param_image' },
          { name: 'x', type: 'int', description: 'draw_image32_param_x' },
          { name: 'y', type: 'int', description: 'draw_image32_param_y' },
        ],
        examples: [
          'img = [0x0606, 0x0606, 0x0606, 0x0606,\n       0x0606, 0x4606, 0x060A, 0x0606,\n       0x0606, 0x4606, 0x060A, 0x0606,\n       0x0606, 0x0606, 0x0606, 0x0606,\n       0x0606, 0x0606, 0x0606, 0x0606,\n       0x061E, 0x0606, 0x0606, 0x2606,\n       0x0606, 0x1E1E, 0x2626, 0x0606,\n       0x0606, 0x0606, 0x0606, 0x0606]\n\ndrawImage32(img, 0, 0)\n',
        ],
      },
      {
        name: 'setActiveFrameBuffer',
        description: 'set_active_frame_buffer_description',
        returnValue: null,
        parameter: [{ name: 'num', type: 'int', description: 'set_active_frame_buffer_param_num' }],
        examples: ['setActiveFrameBuffer(2)\n'],
      },
      {
        name: 'copyFrameBufferContent',
        description: 'copy_frame_buffer_content_description',
        returnValue: null,
        parameter: [
          {
            name: 'fromBuffer',
            type: 'int',
            description: 'copy_frame_buffer_content_param_from_buffer',
          },
          {
            name: 'toBuffer',
            type: 'int',
            description: 'copy_frame_buffer_content_param_to_buffer',
          },
        ],
        examples: ['copyFrameBufferContent(2, 1)\n'],
      },
    ],
  },
  {
    name: 'category_time',
    functions: [
      {
        name: 'delay',
        description: 'delay_description',
        returnValue: null,
        parameter: [{ name: 'ms', type: 'int', description: 'delay_param_ms' }],
        examples: ['delay(1000)\n'],
      },
      {
        name: 'msSinceStart',
        description: 'ms_since_start_description',
        returnValue: 'int',
        parameter: null,
        examples: ['time = msSinceStart()\n'],
      },
      {
        name: 'useTimeZone',
        description: 'use_time_zone_description',
        returnValue: null,
        parameter: [{ name: 'id', type: 'int', description: 'use_time_zone_param_id' }],
        examples: ['useTimeZone(12)\n'],
      },
      {
        name: 'setTime',
        description: 'set_time_description',
        returnValue: null,
        parameter: [
          { name: 'h', type: 'int', description: 'set_time_param_h' },
          { name: 'm', type: 'int', description: 'set_time_param_m' },
          { name: 's', type: 'int', description: 'set_time_param_s' },
        ],
        examples: ['setTime(12, 34, 00)\n'],
      },
      {
        name: 'getTime',
        description: 'get_time_description',
        returnValue: null,
        parameter: [{ name: 'unit', type: 'int', description: 'get_time_param_unit' }],
        examples: ['s = getTime(SECONDS)\nm = getTime(MINUTES)\nh = getTime(HOURS)\n'],
      },
      {
        name: 'setDate',
        description: 'set_date_description',
        returnValue: null,
        parameter: [
          { name: 'd', type: 'int', description: 'set_date_param_d' },
          { name: 'm', type: 'int', description: 'set_date_param_m' },
          { name: 'y', type: 'int', description: 'set_date_param_y' },
        ],
        examples: ['setDate(17, 6, 1989)\n'],
      },
      {
        name: 'getDate',
        description: 'get_date_description',
        returnValue: null,
        parameter: [{ name: 'unit', type: 'int', description: 'get_date_param_unit' }],
        examples: ['d = getDate(DAY)\nm = getDate(MONTH)\ny = getDate(YEAR)\n'],
      },
    ],
  },
  {
    name: 'category_weather',
    functions: [
      {
        name: 'getWeather',
        description: 'get_weather_description',
        returnValue: null,
        parameter: [{ name: 'placeId', type: 'int', description: 'get_weather_param_place_id' }],
        examples: ['getWeather(66310)\n'],
      },
      {
        name: 'readWeatherParam',
        description: 'read_weather_param_description',
        returnValue: 'int',
        parameter: [
          { name: 'paramId', type: 'int', description: 'read_weather_param_param_param_id' },
        ],
        examples: ['readWeatherParam(TEMPERATURE)\n'],
      },
      {
        name: 'drawCityName',
        description: 'draw_city_name_description',
        returnValue: 'int',
        parameter: [
          { name: 'isBigFont', type: 'int', description: 'draw_city_name_param_is_big_font' },
        ],
        examples: ['drawCityName(false)\n'],
      },
      {
        name: 'drawForecastIcon',
        description: 'draw_forecast_icon_description',
        returnValue: null,
        parameter: null,
        examples: ['drawForecastIcon()\n'],
      },
    ],
  },
  {
    name: 'category_accelerometer',
    functions: [
      {
        name: 'getAcceleration',
        description: 'get_acceleration_description',
        returnValue: 'int',
        parameter: [{ name: 'axis', type: 'int', description: 'get_acceleration_param_axis' }],
        examples: ['xAcc = getAcceleration(AXIS_X)\n'],
      },
      {
        name: 'getNormalizedValue',
        description: 'get_normalized_value_description',
        returnValue: 'int',
        parameter: null,
        examples: ['acc = getNormalizedValue()\n'],
      },
      {
        name: 'setAccelerometerCursor',
        description: 'set_accelerometer_cursor_description',
        returnValue: null,
        parameter: [
          { name: 'x', type: 'int', description: 'set_accelerometer_cursor_param_x' },
          { name: 'y', type: 'int', description: 'set_accelerometer_cursor_param_y' },
        ],
        examples: ['setAccelerometerCursor(AXIS_X)\n'],
      },
      {
        name: 'getAccelerometerCursor',
        description: 'get_accelerometer_cursor_description',
        returnValue: 'int',
        parameter: [
          { name: 'axis', type: 'int', description: 'get_accelerometer_cursor_param_axis' },
        ],
        examples: ['cursorX = getAccelerometerCursor(AXIS_X)\n'],
      },
      {
        name: 'getOrientation',
        description: 'get_orientation_description',
        returnValue: 'int',
        parameter: null,
        examples: ['orientation = getOrientation()\n'],
      },
      {
        name: 'isOrientation',
        description: 'is_orientation_description',
        returnValue: 'int',
        parameter: [
          { name: 'direction', type: 'int', description: 'is_orientation_param_direction' },
        ],
        examples: ['isOrientation(ORIENTATION_FLAT)\n'],
      },
      {
        name: 'setReferenceVector',
        description: 'set_reference_vector_description',
        returnValue: null,
        parameter: null,
        examples: ['setReferenceVector()\n'],
      },
      {
        name: 'getAngle',
        description: 'get_angle_description',
        returnValue: 'int',
        parameter: null,
        examples: ['a = getAngle()\n'],
      },
      {
        name: 'configureAccelerometer',
        description: 'config_acc_description',
        returnValue: null,
        parameter: [
          { name: 'dataRate', type: 'int', description: 'config_acc_param_data_rate' },
          { name: 'range', type: 'int', description: 'config_acc_param_range' },
        ],
        examples: ['configureAccelerometer(NORMAL_100_HZ, MAX_4G)\n'],
      },
    ],
  },
  {
    name: 'category_audio',
    functions: [
      {
        name: 'playTone',
        description: 'play_tone_description',
        returnValue: null,
        parameter: [{ name: 'hz', type: 'int', description: 'play_tone_param_hz' }],
        examples: ['playTone(440)\n'],
      },
      {
        name: 'playToneFor',
        description: 'play_tone_for_description',
        returnValue: null,
        parameter: [
          { name: 'hz', type: 'int', description: 'play_tone_for_param_hz' },
          { name: 'ms', type: 'int', description: 'play_tone_for_param_ms' },
        ],
        examples: ['playToneFor(880, 1000)\n'],
      },
      {
        name: 'noteToMs',
        description: 'note_to_ms_description',
        returnValue: 'int',
        parameter: [
          { name: 'noteDuration', type: 'int', description: 'note_to_ms_param_note_duration' },
        ],
        examples: ['ms = noteToMs(NOTE_DUR_EIGHTH)\n'],
      },
      {
        name: 'playNote',
        description: 'play_note_description',
        returnValue: null,
        parameter: [{ name: 'note', type: 'int', description: 'play_note_param_note' }],
        examples: ['playNote(NOTE_C)\n'],
      },
      {
        name: 'playNoteFor',
        description: 'play_note_for_description',
        returnValue: null,
        parameter: [
          { name: 'note', type: 'int', description: 'play_note_for_param_note' },
          { name: 'ms', type: 'int', description: 'play_note_for_param_ms' },
        ],
        examples: ['playNoteFor(NOTE_A, 500)\n'],
      },
      {
        name: 'noTone',
        description: 'no_tone_description',
        returnValue: null,
        parameter: null,
        examples: ['noTone()\n'],
      },
      {
        name: 'setWaveForm',
        description: 'set_wave_form_description',
        returnValue: null,
        parameter: [
          { name: 'waveForm', type: 'int', description: 'set_wave_form_param_wave_form' },
        ],
        examples: ['setWaveForm(WAVEFORM_SQUARE)\n'],
      },
      {
        name: 'setOctave',
        description: 'set_octave_description',
        returnValue: null,
        parameter: [{ name: 'octave', type: 'int', description: 'set_octave_param_octave' }],
        examples: ['setOctave(OCTAVE_C3)\n'],
      },
      {
        name: 'setBPM',
        description: 'set_bpm_description',
        returnValue: null,
        parameter: [{ name: 'bpm', type: 'int', description: 'set_bpm_param_bpm' }],
        examples: ['setBPM(120)\n'],
      },
      {
        name: 'setToneBlocking',
        description: 'set_tone_blocking_description',
        returnValue: null,
        parameter: [
          { name: 'isBlocking', type: 'int', description: 'set_tone_blocking_param_is_blocking' },
        ],
        examples: ['setToneBlocking(true)\n'],
      },
      {
        name: 'setVolume',
        description: 'set_volume_description',
        returnValue: null,
        parameter: [{ name: 'volume', type: 'int', description: 'set_volume_param_volume' }],
        examples: ['setVolume(128)\n'],
      },
      {
        name: 'getVolume',
        description: 'get_volume_description',
        returnValue: 'int',
        parameter: null,
        examples: ['vol = getVolume()\n'],
      },
      {
        name: 'volumeUp',
        description: 'volume_up_description',
        returnValue: null,
        parameter: null,
        examples: ['volumeUp()\n'],
      },
      {
        name: 'volumeDown',
        description: 'volume_down_description',
        returnValue: null,
        parameter: null,
        examples: ['volumeDown()\n'],
      },
      {
        name: 'playSoundEffect',
        description: 'play_sound_effect_description',
        returnValue: null,
        parameter: [
          { name: 'effectNum', type: 'int', description: 'play_sound_effect_param_effect_num' },
          { name: 'loop', type: 'int', description: 'play_sound_effect_param_loop' },
        ],
        examples: ['playSoundEffect(SOUND_EFFECT_ALARM, TRUE)\n'],
      },
    ],
  },
  {
    name: 'category_microphone',
    functions: [
      {
        name: 'record',
        description: 'record_description',
        returnValue: null,
        parameter: [{ name: 'ms', type: 'int', description: 'record_param_ms' }],
        examples: ['record(1000)\n'],
      },
      {
        name: 'playRecorded',
        description: 'play_recorded_description',
        returnValue: null,
        parameter: [{ name: 'loop', type: 'int', description: 'play_recorded_param_loop' }],
        examples: ['playRecorded(true)\n'],
      },
      {
        name: 'waitForLoudNoise',
        description: 'wait_for_loud_noise_description',
        returnValue: null,
        parameter: null,
        examples: ['waitForLoudNoise()\n'],
      },
      {
        name: 'startMicrophone',
        description: 'start_microphone_description',
        returnValue: null,
        parameter: null,
        examples: ['startMicrophone()\n'],
      },
      {
        name: 'readDecibel',
        description: 'read_decibel_description',
        returnValue: 'int',
        parameter: null,
        examples: ['noise = readDecibel()\n'],
      },
      {
        name: 'readFrequency',
        description: 'read_frequency_description',
        returnValue: 'int',
        parameter: null,
        examples: ['frq = readFrequency()\n'],
      },
      {
        name: 'stopMicrophone',
        description: 'stop_microphone_description',
        returnValue: null,
        parameter: null,
        examples: ['stopMicrophone()\n'],
      },
    ],
  },
  {
    name: 'category_internet',
    functions: [
      {
        name: 'connect',
        description: 'connect_description',
        returnValue: null,
        parameter: [{ name: 'interface', type: 'int', description: 'connect_param_interface' }],
        examples: ['connect(WIFI)\n'],
      },
      {
        name: 'disconnect',
        description: 'disconnect_description',
        returnValue: null,
        parameter: null,
        examples: ['disconnect()\n'],
      },
      {
        name: 'startAccessPointMode',
        description: 'start_access_point_mode_description',
        returnValue: null,
        parameter: null,
        examples: ['startAccessPointMode()\n'],
      },
      {
        name: 'startPairingMode',
        description: 'start_pairing_mode_description',
        returnValue: null,
        parameter: null,
        examples: ['startPairingMode()\n'],
      },
      {
        name: 'hasNewScript',
        description: 'has_new_script_description',
        returnValue: null,
        parameter: null,
        examples: ['if hasNewScript():\n    downloadScript()\n'],
      },
      {
        name: 'downloadScript',
        description: 'download_script_description',
        returnValue: null,
        parameter: null,
        examples: ['downloadScript()\n'],
      },
    ],
  },
  {
    name: 'category_extras',
    functions: [
      {
        name: 'setPinMode',
        description: 'set_pin_mode_description',
        returnValue: null,
        parameter: [
          { name: 'pin', type: 'int', description: 'set_pin_mode_param_pin' },
          { name: 'mode', type: 'int', description: 'set_pin_mode_param_mode' },
        ],
        examples: ['setPinMode(IO16, OUTPUT)\n'],
      },
      {
        name: 'setPin',
        description: 'set_pin_description',
        returnValue: null,
        parameter: [
          { name: 'pin', type: 'int', description: 'set_pin_param_pin' },
          { name: 'state', type: 'int', description: 'set_pin_param_state' },
        ],
        examples: ['setPin(IO5, HIGH)\n'],
      },
      {
        name: 'readPin',
        description: 'read_pin_description',
        returnValue: 'int',
        parameter: [{ name: 'pin', type: 'int', description: 'read_pin_param_pin' }],
        examples: ['pin5 = readPin(IO5)\n'],
      },
      {
        name: 'print',
        description: 'print_description',
        returnValue: 'int',
        parameter: [{ name: '...', type: 'int or int[]', description: 'print_param_pin' }],
        examples: ['connect(WIFI)\nprint "Hello World"\n'],
      },
      {
        name: 'pushValue',
        description: 'push_value_description',
        returnValue: null,
        parameter: [
          { name: 'key', type: 'int[]', description: 'push_value_param_key' },
          { name: 'value', type: 'int', description: 'push_value_param_value' },
        ],
        examples: ['pushValue("temp", 22)\n'],
      },
      {
        name: 'pushValues',
        description: 'push_values_description',
        returnValue: null,
        parameter: [
          { name: 'key', type: 'int[]', description: 'push_values_param_key' },
          { name: 'values', type: 'int[]', description: 'push_values_param_values' },
        ],
        examples: ['pushValues("Test", [1,2,3])\n'],
      },
      {
        name: 'getValues',
        description: 'get_values_description',
        returnValue: null,
        parameter: [
          { name: 'key', type: 'int[]', description: 'get_values_param_key' },
          { name: 'values', type: 'int[]', description: 'get_values_param_values' },
        ],
        examples: ['getValues("Temp", buff)\n'],
      },
      {
        name: 'clearValues',
        description: 'clear_values_description',
        returnValue: null,
        parameter: [{ name: 'key', type: 'int[]', description: 'clear_values_param_key' }],
        examples: ['clearValues("Test")\n'],
      },
      {
        name: 'removeValues',
        description: 'remove_values_description',
        returnValue: null,
        parameter: [{ name: 'key', type: 'int[]', description: 'remove_values_param_key' }],
        examples: ['removeValues("Test")\n'],
      },
      {
        name: 'i2cWrite',
        description: 'i2c_write_description',
        returnValue: 'int',
        parameter: [
          { name: 'slaveAdr', type: 'int', description: 'i2c_write_param_slaveAdr' },
          { name: 'dataAdr', type: 'int', description: 'i2c_write_param_dataAdr' },
          { name: 'data', type: 'int[]', description: 'i2c_write_param_data' },
        ],
        examples: ['i2cWrite(0x5A, 0x7B, [0x0B])\n'],
      },
      {
        name: 'i2cRead',
        description: 'i2c_read_description',
        returnValue: 'int',
        parameter: [
          { name: 'slaveAdr', type: 'int', description: 'i2c_read_param_slaveAdr' },
          { name: 'dataAdr', type: 'int', description: 'i2c_read_param_dataAdr' },
          { name: 'data', type: 'int[]', description: 'i2c_read_param_data' },
          { name: 'dataLength', type: 'int', description: 'i2c_read_param_dataLength' },
        ],
        examples: ['i2cRead(0x5A, 0x7B, buff, 2)\n'],
      },
    ],
  },
];
