import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* PDFs */
import A01De from '../../../assets/pdf/A01_Smarte_Leuchte_DE.pdf';
import A01En from '../../../assets/pdf/A01_Smarte_Leuchte_EN.pdf';
import A01Fr from '../../../assets/pdf/A01_Smarte_Leuchte_EN.pdf';

/* Components */

/* Store */

/* Styles */
import './Worksheets.css';

const worksheets_a01 = {
  de: A01De,
  en: A01En,
  fr: A01Fr,
};

function Worksheets() {
  const { t } = useTranslation();
  const lang = useSelector(state => state.settings.lang);
  return (
    <div className="worksheets_wrapper">
      <p>{t('menu_worksheets_text')}</p>
      <br />
      <a href={worksheets_a01[lang]} target="_blank" rel="noopener noreferrer">
        {t('menu_worksheets_a01')}
      </a>
      <br />
    </div>
  );
}

export default Worksheets;
