import { sendByteCode } from './editor';

/* Actions */
const WASM_READY = 'wasm_ready';
const WASM_CODE_CHANGED = 'wasm_code_changed';
const WASM_COMPILE_SUCCESSFUL = 'wasm_compile_successful';
const WASM_COMPILE_FAIL = 'wasm_compile_fail';

const initialState = {
  wasmReady: false,
  codeChanging: false,
  compiledSuccessful: true,
  byteCode: [],
  errorMessage: [],
  hasErrors: false,
};

/* Reducer */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case WASM_READY:
      return { ...initialState, wasmReady: true };
    case WASM_CODE_CHANGED:
      return { ...state, codeChanging: true };
    case WASM_COMPILE_SUCCESSFUL:
      return {
        ...state,
        codeChanging: false,
        compiledSuccessful: true,
        byteCode: action.payload,
        errorMessage: [],
        hasErrors: false,
      };
    case WASM_COMPILE_FAIL:
      return {
        ...state,
        codeChanging: false,
        compiledSuccessful: false,
        byteCode: [],
        errorMessage: action.payload,
        hasErrors: true,
      };
    default:
      return state;
  }
}

/* Action Creators */
export function wasmReady() {
  return { type: WASM_READY };
}

export function wasmCodeChanged() {
  return { type: WASM_CODE_CHANGED };
}

export function wasmCompileSuccess(byteCode) {
  return { type: WASM_COMPILE_SUCCESSFUL, payload: byteCode };
}

export function wasmCompileFail(error) {
  console.log('WASM Error => ' + error);
  return { type: WASM_COMPILE_FAIL, payload: error };
}

export function compileScript(script) {
  return (dispatch, getState) => {
    let success = false;
    if (!window.Module.compileScript(script + '\n')) {
      const byteCode = Array.from(window.Module.getByteCode());
      if (getState().card.isPaired) {
        dispatch(sendByteCode('test.tc', byteCode));
      }
      dispatch(wasmCompileSuccess(byteCode));
      success = true;
    } else {
      console.log('Compiling failed');
      const error = Array.from(window.Module.getError());
      dispatch(wasmCompileFail(error));
    }
    return success;
  };
}
