/* eslint-disable */
import * as monaco from 'monaco-editor';

const oxoscriptConstants = [
  'true',
  'false',
  'True',
  'False',
  'TRUE',
  'FALSE',
  'BTN_L1',
  'BTN_L2',
  'BTN_L3',
  'BTN_R1',
  'BTN_R2',
  'BTN_R3',
  'BTN_ANY',
  'NOTE_REST',
  'NOTE_C',
  'NOTE_CS',
  'NOTE_D',
  'NOTE_DS',
  'NOTE_E',
  'NOTE_F',
  'NOTE_FS',
  'NOTE_G',
  'NOTE_GS',
  'NOTE_A',
  'NOTE_AS',
  'NOTE_B',
  'NOTE_DUR_WHOLE',
  'NOTE_DUR_HALF',
  'NOTE_DUR_QUARTER',
  'NOTE_DUR_EIGHTH',
  'NOTE_DUR_SIXTEENTH',
  'NOTE_DUR_THIRTY_SECOND',
  'OCTAVE_C3',
  'OCTAVE_C4',
  'OCTAVE_C5',
  'OCTAVE_C6',
  'OCTAVE_C7',
  'OCTAVE_C8',
  'WAVEFORM_SINE',
  'WAVEFORM_SQUARE',
  'WAVEFORM_SAWTOOTH',
  'WAVEFORM_TRIANGLE',
  'WIFI',
  'LOCAL',
  'GLOBAL',
  'ORIENTATION_UNKNOWN',
  'ORIENTATION_FLAT',
  'ORIENTATION_FLAT_FLIPPED',
  'ORIENTATION_UP',
  'ORIENTATION_HALF_UP',
  'ORIENTATION_DOWN',
  'ORIENTATION_HALF_DOWN',
  'ORIENTATION_LEFT',
  'ORIENTATION_HALF_LEFT',
  'ORIENTATION_RIGHT',
  'ORIENTATION_HALF_RIGHT',
  'AXIS_X',
  'AXIS_Y',
  'AXIS_Z',
  'POWER_DOWN',
  'NORMAL_1_HZ',
  'NORMAL_10_HZ',
  'NORMAL_25_HZ',
  'NORMAL_50_HZ',
  'NORMAL_100_HZ',
  'NORMAL_200_HZ',
  'NORMAL_400_HZ',
  'NORMAL_1344_HZ',
  'MAX_2G',
  'MAX_4G',
  'MAX_8G',
  'MAX_16G',
  'RESET',
  'TIMER',
  'SECONDS',
  'MINUTES',
  'HOURS',
  'WEEKDAY',
  'DAY',
  'MONTH',
  'YEAR',
  'IO5',
  'IO16',
  'IO17',
  'IO18',
  'IO19',
  'IO23',
  'INPUT',
  'INPUT_PULLUP',
  'INPUT_PULLDOWN',
  'OUTPUT',
  'HIGH',
  'LOW',
  'TEMPERATURE',
  'DEW_POINT',
  'HUMIDITY',
  'WIND',
  'PRECIPITATION',
  'RED',
  'GREEN',
  'BLUE',
  'SOUND_EFFECT_ALARM',
  'SOUND_EFFECT_BABY_SNEEZE',
  'SOUND_EFFECT_CLING',
  'SOUND_EFFECT_OPEN_CAN',
  'SOUND_EFFECT_PEW',
  'SOUND_EFFECT_PLING',
  'SOUND_EFFECT_POOL_SHOT',
  'SOUND_EFFECT_SLAP',
  'SOUND_EFFECT_SPIN_JUMP',
  'SOUND_EFFECT_SPURS',
  'SOUND_EFFECT_TURKEY',
  'SOUND_EFFECT_WATER',
  'SOUND_EFFECT_BEAT1',
  'SOUND_EFFECT_BEAT2',
  'SOUND_EFFECT_BEAT3',
  'SOUND_EFFECT_BEAT4',
  'SOUND_EFFECT_BEAT5',
  'SOUND_EFFECT_BEAT6',
];

const oxoscriptFunctions = [
  { name: 'size', insertText: 'size(${1:array})' },
  { name: 'remove', insertText: 'remove(${1:array}, ${2:pos})' },
  { name: 'findValue', insertText: 'findValue(${1:array}, ${2:value})' },
  { name: 'findRange', insertText: 'findRange(${1:array}, ${2:searchRange})' },
  { name: 'min', insertText: 'min(${1:array})' },
  { name: 'max', insertText: 'max(${1:array})' },
  { name: 'avg', insertText: 'avg(${1:array})' },
  { name: 'sin', insertText: 'sin(${1:degree}, ${2:factor})' },
  { name: 'cos', insertText: 'cos(${1:degree}, ${2:factor})' },
  { name: 'pow', insertText: 'pow(${1:base}, ${2:exponent})' },
  { name: 'map', insertText: 'map(${1:value}, ${2:a1}, ${3:a2}, ${4:b1}, ${5:b2})' },
  { name: 'abs', insertText: 'abs(${1:value})' },
  { name: 'isButtonPressed', insertText: 'isButtonPressed(${1:BTN_})' },
  {
    name: 'waitForButtonPressed',
    insertText: 'waitForButtonPressed(${1:BTN_})',
  },
  { name: 'canConnect', insertText: 'canConnect()' },
  { name: 'random', insertText: 'random(${1:from}, ${2:to})' },
  { name: 'hasPersistentVariable', insertText: 'hasPersistentVariable(${1:key})' },
  { name: 'savePersistentVariable', insertText: 'savePersistentVariable(${1:key}, ${2:value})' },
  { name: 'loadPersistentVariable', insertText: 'loadPersistentVariable(${1:key})' },
  { name: 'deletePersistentVariable', insertText: 'deletePersistentVariable(${1:key})' },
  { name: 'reboot', insertText: 'reboot()' },
  { name: 'turnOff', insertText: 'turnOff()' },
  {
    name: 'turnOffWakeUpButton',
    insertText: 'turnOffWakeUpButton(${1:L1}, ${2:L2}, ${3:L3}, ${4:R1}, ${5:R2}, ${6:R3})',
  },
  {
    name: 'configureAutoTurnOff',
    insertText: 'configureAutoTurnOff(${1:sec})',
  },
  { name: 'enableAutoTurnOff', insertText: 'enableAutoTurnOff()' },
  { name: 'disableAutoTurnOff', insertText: 'disableAutoTurnOff()' },
  { name: 'sleep', insertText: 'sleep(${1:seconds})' },
  { name: 'getWakeUpCause', insertText: 'getWakeUpCause()' },
  { name: 'getBatteryVoltage', insertText: 'getBatteryVoltage()' },
  { name: 'isFullyCharged', insertText: 'isFullyCharged()' },
  { name: 'isUsbPluggedIn', insertText: 'isUsbPluggedIn()' },
  { name: 'getTemperature', insertText: 'getTemperature()' },
  { name: 'setColor', insertText: 'setColor(${1:r}, ${2:g}, ${3:b})' },
  { name: 'setRandomColor', insertText: 'setRandomColor()' },
  { name: 'setHueColor', insertText: 'setHueColor(${1:hue})' },
  { name: 'enableAutoUpdate', insertText: 'enableAutoUpdate()' },
  { name: 'disableAutoUpdate', insertText: 'disableAutoUpdate()' },
  { name: 'isAutoUpdate', insertText: 'isAutoUpdate()' },
  { name: 'update', insertText: 'update()' },
  { name: 'fillScreen', insertText: 'fillScreen()' },
  { name: 'clearScreen', insertText: 'clearScreen()' },
  { name: 'setPixel', insertText: 'setPixel(${1:x}, ${2:y}, ${2:r}, ${2:g}, ${2:b})' },
  { name: 'drawPixel', insertText: 'drawPixel(${1:x}, ${2:y})' },
  { name: 'clearPixel', insertText: 'clearPixel(${1:x}, ${2:y})' },
  { name: 'readPixel', insertText: 'readPixel(${1:color}, ${2:x}, ${3:y})' },
  { name: 'isPixelSet', insertText: 'isPixelSet(${1:x}, ${2:y})' },
  { name: 'fill', insertText: 'fill()' },
  { name: 'noFill', insertText: 'noFill()' },
  {
    name: 'drawLine',
    insertText: 'drawLine(${1:x1}, ${2:y1}, ${3:x2}, ${4:y2})',
  },
  {
    name: 'drawRectangle',
    insertText: 'drawRectangle(${1:x}, ${2:y}, ${3:w}, ${4:h})',
  },
  {
    name: 'drawTriangle',
    insertText: 'drawTriangle(${1:x1}, ${2:y1}, ${3:x2}, ${4:y2}, ${5:x3}, ${6:y3})',
  },
  { name: 'drawCircle', insertText: 'drawCircle(${1:x}, ${2:y}, ${3:r})' },
  { name: 'drawNumber', insertText: 'drawNumber(${1:num})' },
  { name: 'drawChar', insertText: 'drawChar(${1:c}, ${2:x}, ${3:y}, ${4:isBigFont})' },
  { name: 'drawText', insertText: 'drawText(${1:text}, ${2:isBigFont})' },
  { name: 'drawImage', insertText: 'drawImage(${1:image}, ${2:x}, ${3:y})' },
  { name: 'drawImage32', insertText: 'drawImage32(${1:image}, ${2:x}, ${3:y})' },
  {
    name: 'setActiveFrameBuffer',
    insertText: 'setActiveFrameBuffer(${1:num})',
  },
  {
    name: 'copyFrameBufferContent',
    insertText: 'copyFrameBufferContent(${1:fromBuffer}, ${2:toBuffer})',
  },
  { name: 'delay', insertText: 'delay(${1:ms})' },
  { name: 'msSinceStart', insertText: 'msSinceStart()' },
  { name: 'useTimeZone', insertText: 'useTimeZone(${1:id})' },
  { name: 'setTime', insertText: 'setTime(${1:h}, ${2:m}, ${3:s})' },
  { name: 'getTime', insertText: 'getTime(${1:unit})' },
  { name: 'setDate', insertText: 'setDate(${1:d}, ${2:m}, ${3:y})' },
  { name: 'getDate', insertText: 'getDate(${1:unit})' },
  { name: 'getWeather', insertText: 'getWeather(${1:placeId})' },
  { name: 'readWeatherParam', insertText: 'readWeatherParam(${1:paramId})' },
  { name: 'drawCityName', insertText: 'drawCityName(${1:isBigFont})' },
  { name: 'drawForecastIcon', insertText: 'drawForecastIcon()' },
  { name: 'getAcceleration', insertText: 'getAcceleration(${1:AXIS_})' },
  { name: 'getNormalizedValue', insertText: 'getNormalizedValue()' },
  {
    name: 'setAccelerometerCursor',
    insertText: 'setAccelerometerCursor(${1:x}, ${2:y})',
  },
  {
    name: 'getAccelerometerCursor',
    insertText: 'getAccelerometerCursor(${1:AXIS_})',
  },
  { name: 'getOrientation', insertText: 'getOrientation()' },
  { name: 'isOrientation', insertText: 'isOrientation(${1:ORIENTATION_})' },
  { name: 'setReferenceVector', insertText: 'setReferenceVector()' },
  { name: 'getAngle', insertText: 'getAngle()' },
  {
    name: 'configureAccelerometer',
    insertText: 'configureAccelerometer(${1:dataRate}, ${2:ranage})',
  },
  { name: 'playTone', insertText: 'playTone(${1:hz})' },
  { name: 'playToneFor', insertText: 'playToneFor(${1:hz}, ${2:ms})' },
  { name: 'noteToMs', insertText: 'noteToMs(${1:NOTE_DUR_})' },
  { name: 'playNote', insertText: 'playNote(${1:NOTE_})' },
  { name: 'playNoteFor', insertText: 'playNoteFor(${1:NOTE_}, ${2:ms})' },
  { name: 'noTone', insertText: 'noTone()' },
  { name: 'setWaveForm', insertText: 'setWaveForm(${1:waveForm})' },
  { name: 'setOctave', insertText: 'setOctave(${1:OCTAVE_})' },
  { name: 'setBPM', insertText: 'setBPM(${1:bpm})' },
  { name: 'setToneBlocking', insertText: 'setToneBlocking(${1:isBlocking})' },
  { name: 'setVolume', insertText: 'setVolume(${1:volume})' },
  { name: 'getVolume', insertText: 'getVolume()' },
  { name: 'volumeUp', insertText: 'volumeUp()' },
  { name: 'volumeDown', insertText: 'volumeDown()' },
  { name: 'playSoundEffect', insertText: 'playSoundEffect(${1:SOUND_EFFECT_}, ${2:loop})' },
  { name: 'record', insertText: 'record(${1:ms})' },
  { name: 'playRecorded', insertText: 'playRecorded(${1:loop})' },
  { name: 'waitForLoudNoise', insertText: 'waitForLoudNoise()' },
  { name: 'startMicrophone', insertText: 'startMicrophone()' },
  { name: 'readDecibel', insertText: 'readDecibel()' },
  { name: 'readFrequency', insertText: 'readFrequency()' },
  { name: 'stopMicrophone', insertText: 'stopMicrophone()' },
  { name: 'connect', insertText: 'connect(WIFI)' },
  { name: 'disconnect', insertText: 'disconnect()' },
  { name: 'startAccessPointMode', insertText: 'startAccessPointMode()' },
  { name: 'startPairingMode', insertText: 'startPairingMode()' },
  { name: 'hasNewScript', insertText: 'hasNewScript()' },
  { name: 'downloadScript', insertText: 'downloadScript()' },
  { name: 'setPinMode', insertText: 'setPinMode(${1:pin}, ${2:mode})' },
  { name: 'setPin', insertText: 'setPin(${1:pin}, ${2:state})' },
  { name: 'readPin', insertText: 'readPin(${1:pin})' },
  { name: 'print', insertText: 'print ${1:msg}' },
  { name: 'pushValue', insertText: 'pushValue(${1:key}, ${2:value})' },
  { name: 'pushValues', insertText: 'pushValues(${1:key}, ${2:values})' },
  { name: 'getValues', insertText: 'getValues(${1:key}, ${2:values})' },
  { name: 'clearValues', insertText: 'clearValues(${1:key})' },
  { name: 'removeValues', insertText: 'removeValues(${1:key})' },
  { name: 'i2cWrite', insertText: 'i2cWrite(${1:slaveAdr}, ${2:dataAdr}, ${3:data})' },
  {
    name: 'i2cRead',
    insertText: 'i2cRead(${1:slaveAdr}, ${2:dataAdr}, ${3:data}, ${4:dataLength})',
  },
  { name: 'selectCommunicationType', insertText: 'selectCommunicationType(${1:type})' },
  { name: 'startCommunication', insertText: 'startCommunication()' },
  { name: 'stopCommunication', insertText: 'stopCommunication()' },
  { name: 'setReceivers', insertText: 'setReceivers(${1:receivers})' },
  { name: 'setTextToSend', insertText: 'setTextToSend(${1:text})' },
  { name: 'setVariableToSend', insertText: 'setVariableToSend(${1:var}, ${2:value})' },
  { name: 'sendMessage', insertText: 'sendMessage()' },
  { name: 'hasNewMessage', insertText: 'hasNewMessage()' },
  { name: 'isSender', insertText: 'isSender(${1:sender})' },
  { name: 'getSender', insertText: 'getSender(${1:sender})' },
  { name: 'isReceivedText', insertText: 'isReceivedText(${1:text})' },
  { name: 'getReceivedText', insertText: 'getReceivedText(${1:text})' },
  { name: 'getReceivedVariable', insertText: 'getReceivedVariable(${1:var})' },
];

/* Monaco Editor Monarch definition for the Oxoscript language.
 * The language is inherited from the python language definition. */
export function getMonarchTokensProvide() {
  return {
    defaultToken: '',

    keywords: [
      'def',
      'main',
      'while',
      'for',
      'if',
      'elif',
      'else',
      'in',
      'repeat',
      'break',
      'continue',
      'return',
    ],

    constants: oxoscriptConstants,

    functions: oxoscriptFunctions.map(func => func.name),

    brackets: [
      { open: '{', close: '}', token: 'delimiter.curly' },
      { open: '[', close: ']', token: 'delimiter.bracket' },
      { open: '(', close: ')', token: 'delimiter.parenthesis' },
    ],

    tokenizer: {
      root: [
        { include: '@whitespace' },
        { include: '@numbers' },
        { include: '@strings' },

        [/[,:;]/, 'delimiter'],
        [/[{}[\]()]/, '@brackets'],

        [/@[a-zA-Z]\w*/, 'tag'],

        [
          /([a-zA-Z_{1}][a-zA-Z0-9_]+\s?)(?=\()/,
          {
            cases: {
              '@keywords': 'keyword',
              '@constants': 'constants',
              '@functions': 'function',
              '@default': 'customfunction',
            },
          },
        ],

        [
          /[a-zA-Z]\w*/,
          {
            cases: {
              '@keywords': 'keyword',
              '@constants': 'constants',
              '@functions': 'function',
              '@default': 'identifier',
            },
          },
        ],
      ],

      // Deal with white space, including single and multi-line comments
      whitespace: [
        [/\s+/, 'white'],
        [/(^#.*$)/, 'comment'],
        [/('''.*''')|(""".*""")/, 'string'],
        [/'''.*$/, 'string', '@endDocString'],
        [/""".*$/, 'string', '@endDblDocString'],
      ],
      endDocString: [[/\\'/, 'string'], [/.*'''/, 'string', '@popall'], [/.*$/, 'string']],
      endDblDocString: [[/\\"/, 'string'], [/.*"""/, 'string', '@popall'], [/.*$/, 'string']],

      // Recognize hex, negatives, decimals, imaginaries, longs, and scientific notation
      numbers: [
        [/-?0x([abcdef]|[ABCDEF]|\d)+[lL]?/, 'number'],
        [/-?0b([01])+/, 'number'],
        [/-?(\d*\.)?\d+([eE][+-]?\d+)?[jJ]?[lL]?/, 'number'],
      ],

      // Recognize strings, including those broken across lines with \ (but not without)
      strings: [
        [/'$/, 'string.escape', '@popall'],
        [/'/, 'string.escape', '@stringBody'],
        [/"$/, 'string.escape', '@popall'],
        [/"/, 'string.escape', '@dblStringBody'],
      ],
      stringBody: [
        [/[^\\']+$/, 'string', '@popall'],
        [/[^\\']+/, 'string'],
        [/\\./, 'string'],
        [/'/, 'string.escape', '@popall'],
        [/\\$/, 'string'],
      ],
      dblStringBody: [
        [/[^\\"]+$/, 'string', '@popall'],
        [/[^\\"]+/, 'string'],
        [/\\./, 'string'],
        [/"/, 'string.escape', '@popall'],
        [/\\$/, 'string'],
      ],
    },
  };
}

export function getMonarchCompletionItemProvider() {
  return {
    provideCompletionItems: () => {
      var suggestions = [];

      /* Add al constants to the suggestions array */
      const constandSuggestions = oxoscriptConstants.map(constant => ({
        label: constant,
        kind: monaco.languages.CompletionItemKind.Constant,
        insertText: constant,
      }));

      suggestions = suggestions.concat(constandSuggestions);

      /* Add all functions to the suggestions array */
      const functionSuggestions = oxoscriptFunctions.map(func => ({
        label: func.name,
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: func.insertText,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      }));

      suggestions = suggestions.concat(functionSuggestions);

      return { suggestions };
    },
  };
}
