import FileSaver from 'file-saver';
import { batch } from 'react-redux';
import { setScript, shareCode, getSharedCode } from '../../api';
import { unPair } from './card';

/* Actions */
const SET_CONTENT = 'EDITOR_SET_CONTENT';
const SET_FILE_NAME = 'EDITOR_SET_FILENAME';

/* Reducer */
const initialState = {
  filename: 'Mein neues Script',
  content:
    'def main():\n\twhile true:\n\t\tsetRandomColor()\n\t\tdrawText("Hello World", true)\n\t\tdelay(500)\n',
  unsavedContent: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FILE_NAME:
      return { ...state, filename: action.payload };
    case SET_CONTENT:
      let changed = true;
      if (action.payload.fromSource || action.payload.content.localeCompare(state.content) === 0) {
        changed = false;
      }
      return {
        ...state,
        content: action.payload.content,
        unsavedContent: changed,
      };
    default:
      return state;
  }
}

/* Action Creators */
export function setFilename(name) {
  return {
    type: SET_FILE_NAME,
    payload: name,
  };
}

export function setContent(content, fromSource = true) {
  return {
    type: SET_CONTENT,
    payload: { content, fromSource },
  };
}

export function saveFile(text, filename) {
  return async dispatch => {
    if (!filename || !filename.length) {
      filename = initialState.filename;
    }
    const ending = filename.substr(filename.length - 3, filename.length);
    if (ending !== '.tc') {
      filename += '.tc';
    }
    const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, `${filename}`);
  };
}

export function loadContent() {
  return dispatch => {
    const content = localStorage.getItem('editorContent');
    const fileName = localStorage.getItem('editorFileName');
    if (content) {
      batch(() => {
        dispatch(setFilename(fileName));
        dispatch(setContent(content));
      });
    }
  };
}

export function loadFromHash(hash) {
  return async dispatch => {
    try {
      const res = await getSharedCode(hash);
      const content = res.content;
      const fileName = res.name;
      if (content) {
        batch(() => {
          dispatch(setFilename(fileName));
          dispatch(setContent(content));
        });
      }
    } catch (e) {
      batch(() => {
        dispatch(setFilename('error'));
        dispatch(setContent('#Error loading script from hash'));
      });
    }
  };
}

export function saveContent() {
  return (dispatch, getState) => {
    const editor = getState().editor;
    localStorage.setItem('editorContent', editor.content);
    localStorage.setItem('editorFileName', editor.filename);
  };
}

export function sendByteCode(name, code) {
  return async dispatch => {
    try {
      await setScript(name, code);
    } catch (e) {
      dispatch(unPair());
    }
  };
}

export function shareScript() {
  return async (dispatch, getState) => {
    try {
      const editor = getState().editor;
      return await shareCode(editor.filename, editor.content);
    } catch (e) {
      throw new Error(e);
    }
  };
}
