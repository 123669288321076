/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  hello: 'Bonjour',

  /* Top Banner */
  top_banner_text:
    "Cette version d'Oxoscript est obsolète et n'est plus développée. La nouvelle version 2.0 nécessite une Oxocard Science, Galaxy ou Artwork et ne fonctionne plus sur l'Oxocard Blockly. Plus d'informations sur la nouvelle version d'Oxoscript ici:",

  /* Top Bar */
  top_bar_button_new: 'Nouveau',
  top_bar_button_open: 'Ouvrir',
  top_bar_button_save: 'Enregistrer',
  top_bar_button_share: 'Partager',
  top_bar_new_script_name: 'Mon nouveau scénario',

  /* Info Bar */
  info_bar_connect: 'Relies maintenant votre Oxocard et charges le premier programme sur la carte',
  info_bar_compiling: 'Le programme est vérifié et compilé. Tu peux continuer à y travailler',
  info_bar_error: 'Erreur en ligne',
  info_bar_ready: 'Le programme a été compilé et peut maintenant être chargé sur la carte',

  /* Terminal */
  terminal_header_output: 'Sortie',
  terminal_header_clear: 'Supprimer',

  /* Menu - First Steps */
  menu_first_steps_title: 'Commencer maintenant',
  menu_first_steps_text: 'Tu trouves ici un point de départ',
  menu_first_steps_installation: "Mode d'empoi",
  menu_first_steps_key_commands: '',
  menu_first_steps_book: 'Oxoscript Manuel',

  /* Menu - Simulator */
  menu_simulator_title: 'Simulateur',
  menu_simulator_unknown_functions: "n'est pas pris en charge par le simulateur",

  /* Menu - My Oxocard */
  menu_my_oxocard_title: 'Mon Oxocard',
  menu_my_oxocard_no_card_text: "Tu n'as pas encore jumelé une carte.",
  menu_my_oxocard_no_card_button: "Procède au jumelage d'une carte",
  menu_my_oxocard_link_other_card_button: 'Jumeler une autre carte',
  menu_my_oxocard_reset_card_button: "Restaurer le firmware d'usine",
  menu_my_oxocard_switch_to_blockly: 'Passer en mode Blockly',

  /* Menu - Documentation */
  menu_documentation_title: 'Documentation',
  menu_documentation_search: 'Recherche de documentation',
  menu_documentation_functions: 'Fonctions',
  menu_documentation_constants: 'Constantes',

  /* Menu - Examples */
  menu_examples_title: 'Exemples',

  /* Menu - Worksheets */
  menu_worksheets_title: 'Fiches de travail',
  menu_worksheets_text:
    "Les fiches de travail sont toujours en cours et seront répertoriées ici dès qu'elles seront disponibles",
  menu_worksheets_a01: 'F01 La lumière intelligente',

  /* Menu - Data */
  menu_data_title: 'Données',
  menu_data_text: "Tu trouves ici les variables que tu as envoyées au navigateur avec l'Oxocard.",
  menu_data_note_header: 'Remarque:',
  menu_data_note_text: 'Un maximum de 100 valeurs par variable sont stockées et affichées.',
  menu_data_public_link: 'Lien de partage',
  menu_data_latest: 'Actuel',

  /* Menu - Settings */
  menu_Settings_title: 'Préférences',
  menu_Settings_Language_title: 'Langue préférée:',
  menu_Settings_Language_option_en: 'English',
  menu_Settings_Language_option_de: 'Deutsch',
  menu_Settings_Language_option_fr: 'Français',
  menu_Settings_theme_title: "Changer la conception de l'éditeur:",
  menu_Settings_theme_option_light: 'lumineux',
  menu_Settings_theme_option_dark: 'sombre',
  menu_Settings_editor_font_size: "Taille de la fonte de l'éditeur:",

  /* Menu - Feedback */
  menu_feedback_title: 'Feedback',
  menu_feedback_text: 'Ton avis est important pour nous',
  menu_feedback_link: 'Envoies nous un email',
  menu_feedback_imprintLink: 'Informations Légales',

  /* Popup - Unsaved changed */
  popup_unsaved_changes_title: 'Tu as un script non sauvegardé!',
  popup_unsaved_changes_body:
    'Si tu continue sans sauvegarder, tu perdras ton changements. Veux-tu continuer?',
  popup_unsaved_changes_cancel: 'Non!',
  popup_unsaved_changes_continue: 'Continuer',

  /* Popup - Shared script */
  popup_shared_script_title: 'Ton lien a été créé!',
  popup_shared_script_body: 'Partages ce lien avec les amis ou enregistres-le dans tes favoris.',
  popup_shared_script_cancel: 'Fermer',
  popup_shared_script_email: 'Envoyer un email',
  popup_shared_script_email_subject: 'Voir mon script',
  popup_shared_script_copy: 'Copier le lien',
  popup_shared_script_copied: 'Le lien a été copié!',

  /* Popup - Switch to Blockly */
  popup_switch_to_blockly_title: 'Tu préfères programmer avec Blockly?',
  popup_switch_to_blockly_body:
    'Si tu passes à Blockly, le lien sera supprimé. Tu peux toujours revenir en arrière.',
  popup_switch_to_blockly_cancel: "Rester dans l'éditeur",
  popup_switch_to_blockly_continue: 'Passer en mode Blockly',

  /* Popup - Really reset */
  popup_really_reset_title: 'Tu veux vraiment remettre la carte à zéro?',
  popup_really_reset_body:
    'Si t\'appuies sur "Reset", toutes les données et le script de la carte seront effacés',
  popup_really_reset_cancel: 'Non!',
  popup_really_reset_continue: 'Reset',

  /* Popup - Really clear data */
  popup_really_clear_data_title: 'Tu veux vraiment supprimer ces données?',
  popup_really_clear_data_body:
    'Si tu appuies sur Oui, toutes les données de cette variable seront definitivement supprimées.',
  popup_really_clear_data_cancel: 'Non',
  popup_really_clear_data_continue: 'Oui',

  /* Popup - Really delete variable */
  popup_really_delete_variable_title: 'Pas de données disponibles. Tu veux supprimer la variable?',
  popup_really_delete_variable_body:
    'Si tu appuies sur Oui, la variable sera definitivement supprimée.',
  popup_really_delete_variable_cancel: 'Non',
  popup_really_delete_variable_continue: 'Oui',
};
