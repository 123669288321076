import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Components */
import SubSubCategory from '../../SubSubCategory';
import SyntaxElement from './SyntaxElement';

/* Store */

/* Styles */
import './SyntaxList.css';

class SyntaxList extends React.PureComponent {
  state = {
    activeSyntax: -1,
  };

  componentDidUpdate() {
    if (this.props.isOpen === false) {
      this.setState({ activeSyntax: -1 });
    }
  }

  render() {
    const { activeSyntax } = this.state;
    return (
      <div className="syntax_list_wrapper">
        {this.props.syntax.map((syntax, i) => (
          <SubSubCategory
            theme={this.props.theme}
            name={syntax.name}
            key={i}
            isOpen={activeSyntax === i}
            searchString={this.props.filterString}
            onClick={() => {
              this.setState({
                activeSyntax: i === activeSyntax ? -1 : i,
              });
            }}
          >
            <SyntaxElement syntaxData={syntax} key={i} />
          </SubSubCategory>
        ))}
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return { theme: settings.editorTheme };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SyntaxList));
