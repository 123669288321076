import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Components */
import OutputPanel from './OutputPanel';

/* Store */
import { toggleDockContent } from '../../store/ducks/layout';
import { addOutput, clearOutput, togglePulling, getMessages } from '../../store/ducks/terminal';

/* Styles */
import './Terminal.css';

class Terminal extends React.Component {
  pollingEnabled = false;

  pollMessages = () => {
    setTimeout(() => {
      if (this.pollingEnabled) {
        this.props
          .getMessages()
          .then(() => this.pollMessages())
          .catch(e =>
            this.props.addOutput([
              {
                created_at: Date.now(),
                content: 'Error loading message from Card',
              },
            ])
          );
      }
    }, 1000);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.pollMessages !== this.props.pollMessages ||
      prevProps.isPaired !== this.props.isPaired
    ) {
      if (this.props.pollMessages && this.props.isPaired) {
        this.pollingEnabled = true;
        this.pollMessages();
      } else {
        this.pollingEnabled = false;
      }
    }
  }

  render() {
    const { i18n } = this.props;

    return (
      <div
        className={
          this.props.showTerminal
            ? `terminal-wrapper ${this.props.theme} open`
            : `terminal-wrapper ${this.props.theme}`
        }
      >
        <div
          className="terminal-header"
          onClick={() => {
            if (
              (this.props.showTerminal && this.props.pollMessages) ||
              (!this.props.showTerminal && !this.props.pollMessages)
            ) {
              this.props.togglePulling();
            }
            this.props.toggleDockContent();
          }}
        >
          <div className="terminal_header_left">
            <p>{i18n.t('terminal_header_output')}</p>
            <button
              aria-label="Toggle polling"
              className={this.props.pollMessages ? 'output_icon polling' : 'output_icon'}
              onClick={e => {
                e.stopPropagation();
                if (this.props.showTerminal) {
                  this.props.togglePulling();
                }
              }}
            ></button>
          </div>

          <button
            aria-label="Clear output"
            className="terminal_header_right"
            onClick={e => {
              e.stopPropagation();
              this.props.clearOutput();
            }}
          >
            <div className="bin_icon"></div>
            <p>{i18n.t('terminal_header_clear')}</p>
          </button>
        </div>
        <OutputPanel output={this.props.output} />
      </div>
    );
  }
}

function mapStateToProps({ card, layout, settings, terminal }) {
  return {
    isPaired: card.isPaired,
    pollMessages: terminal.pollMessages,
    showTerminal: layout.showTerminal,
    theme: settings.editorTheme,
    output: terminal.output,
  };
}

const mapDispatchToProps = {
  toggleDockContent,
  addOutput,
  clearOutput,
  togglePulling,
  getMessages,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Terminal));
