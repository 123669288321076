import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ui } from 'OxocardCore';

/* Components */
import OwmButton from '../../common/Button';

/* Store */
import {
  unPairCard,
  resetToInitial,
  pairWithCard,
  loadCardInfo,
  clearCard,
} from '../../../store/ducks/card';

/* Styles */
import './MyOxocard.css';

class MyOxocard extends React.Component {
  pollingEnabled = false;
  intervalID = 0;

  pollCardInfo = () => {
    this.intervalID = setTimeout(() => {
      if (this.pollingEnabled) {
        this.props
          .loadCardInfo()
          .then(() => this.pollCardInfo())
          .catch(e => {
            console.log(e);
          });
      }
    }, 30000);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen || prevProps.isPaired !== this.props.isPaired) {
      if (this.props.isOpen && this.props.isPaired) {
        this.pollingEnabled = true;
        this.props
          .loadCardInfo()
          .then(() => this.pollCardInfo())
          .catch(e => {
            console.log(e);
          });
      } else {
        this.pollingEnabled = false;
        clearInterval(this.intervalID);
      }
    }
  }

  onResetCard = () => {
    const { i18n } = this.props;
    const popup = new ui.Popup({
      title: i18n.t('popup_really_reset_title'),
      mood: 'negative',
      failure: true,
      content: i18n.t('popup_really_reset_body'),
      buttons: [
        {
          class: 'negative',
          text: i18n.t('popup_really_reset_cancel'),
          shouldClose: true,
          callback() {},
        },
        {
          class: 'positive',
          text: i18n.t('popup_really_reset_continue'),
          shouldClose: true,
          callback: () => {
            this.props.resetToInitial().catch(e => {
              /* error message */
            });

            const config = { onProceed: () => null };
            const popup = new ui.components.FlashPopup(config);
            popup.show();
          },
        },
      ],
    });
    popup.show();
  };

  onLink = () => {
    /* pairHelp popup */
    const onProceed = async () => {
      /* onProceed: pair popup */
      const onProceed = async challenge => {
        this.props
          .pairWithCard(challenge)
          .then(() => {
            /* check if fwVersion is empty, old ("1.x.x") or contains garbage ("//") */
            if (this.props.fwVersion === '' || this.props.fwVersion[0] === '1' ||
                this.props.fwVersion.indexOf("//") >= 0) {
              this.props.clearCard();
              const config = {
                message: 'popupDefaultImageSuccessBody',
                mood: 'positive',
                buttons: [
                  {
                    class: 'positive',
                    callback() {},
                  },
                ],
                onProceed: () => {
                  const config = { onProceed: () => null };
                  const popup = new ui.components.FlashPopup(config);
                  popup.show();
                },
              };
              const popup = new ui.components.OldFwVersionPopup(config);
              popup.show();
            }
          })
          .catch(e => console.log(e));
      };
      const config = { onProceed, onCancel: () => null };
      const popup = new ui.components.PairPopup(config);
      popup.show();
    };
    const config = { onProceed, onCancel: () => null };
    const popup = new ui.components.PairHelpPopup(config);
    popup.show();
  };

  onRelink = () => {
    this.props
      .unPairCard()
      .then(() => this.onLink())
      .catch(e => {
        /* error message */
      });
  };

  onSwitchToBlockly = () => {
    const { i18n } = this.props;
    const popup = new ui.Popup({
      title: i18n.t('popup_switch_to_blockly_title'),
      mood: 'positive',
      failure: true,
      content: i18n.t('popup_switch_to_blockly_body'),
      buttons: [
        {
          class: 'negative',
          text: i18n.t('popup_switch_to_blockly_cancel'),
          shouldClose: true,
          callback() {},
        },
        {
          class: 'positive',
          text: i18n.t('popup_switch_to_blockly_continue'),
          shouldClose: true,
          callback: () => {
            this.props.clearCard();
            const url = window.location.href;
            const hmacIdClient = JSON.parse(localStorage.getItem('client')).hmacId;
            if (url.indexOf('stage') >= 0) {
              window.location.href = `https://blockly.stage.oxocard.ch?hmacId=${hmacIdClient}`;
            } else {
              window.location.href = `https://blockly.oxocard.ch?hmacId=${hmacIdClient}`;
            }
          },
        },
      ],
    });
    popup.show();
  };

  render() {
    const { i18n, isPaired } = this.props;

    if (!isPaired) {
      return (
        <div className="my-oxocard">
          <p>{i18n.t('menu_my_oxocard_no_card_text')}</p>
          <OwmButton
            onClick={() => {
              this.onLink();
            }}
            text={i18n.t('menu_my_oxocard_no_card_button')}
          />
        </div>
      );
    }

    return (
      <div className="my-oxocard">
        <h2>{this.props.name}</h2>
        <div className="property">HW-Version: {this.props.hwVersion}</div>
        <div className="property">FW-Version: {this.props.fwVersion}</div>
        {this.props.usedSpiffsSize !== 0 && (
          <div className="property">
            SPIFFS: {((100 / this.props.totalSpiffsSize) * this.props.usedSpiffsSize).toFixed(2)}%
          </div>
        )}
        <OwmButton onClick={this.onResetCard} text={i18n.t('menu_my_oxocard_reset_card_button')} />
        <OwmButton
          onClick={this.onRelink}
          text={i18n.t('menu_my_oxocard_link_other_card_button')}
        />
        <OwmButton
          onClick={this.onSwitchToBlockly}
          text={i18n.t('menu_my_oxocard_switch_to_blockly')}
        />
      </div>
    );
  }
}

function mapStateToProps({ card }) {
  return {
    ...card,
  };
}

const mapDispatchToProps = {
  unPairCard,
  resetToInitial,
  pairWithCard,
  loadCardInfo,
  clearCard,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MyOxocard));
