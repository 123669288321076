/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable max-len */
export default {
  /* ---------- Boolean ---------- */
  boolean_title: 'Boolean',
  boolean_description:
    'Booleans werden gebraucht um etwas auf entweder wahr (true) oder falsch (false) zu setzen.',
  boolean_const_true: 'Wenn etwas wahr sein soll',
  boolean_const_false: 'Wenn etwas falsch sein soll',

  /* ---------- Buttons ---------- */
  button_title: 'Knöpfe',
  button_description: 'Konstanten für alle Knöpfe der Oxocard',
  button_const_btn_l1: 'Knopf L1 (linke Seite oben)',
  button_const_btn_l2: 'Knopf L2 (linke Seite unten links)',
  button_const_btn_l3: 'Knopf L3 (linke Seite unten rechts)',
  button_const_btn_r1: 'Knopf R1 (rechte Seite oben)',
  button_const_btn_r2: 'Knopf R2 (rechte Seite unten links)',
  button_const_btn_r3: 'Knopf R3 (rechte Seite unten rechts)',
  button_const_btn_any: 'Irgendein Knopf',

  /* ---------- Wake up reason ---------- */
  wake_up_reasons_title: 'Aufwach Gründe',
  wake_up_reasons_description: 'Konstanten für alle Gründe mit der die Oxocard aufwachen kann',
  wake_up_reasons_const_reset: 'Wenn die Karte von einem Neustart geweckt wurde.',
  wake_up_reasons_const_timer: 'Wenn die Karte vom sleep Timer geweckt wurde',

  /* ---------- Color ---------- */
  color_title: 'Farben',
  color_description: 'Konstanten für die Farbkanäle eines Pixels der Matrix',
  color_const_red: 'Roter Kanal',
  color_const_green: 'Grüner Kanal',
  color_const_blue: 'Blauer Kanal',

  /* ---------- Time ---------- */
  time_title: 'Zeit',
  time_description: 'Konstanten für die Zeit Parameter',
  time_const_seconds: 'Sekunden Parameter',
  time_const_minutes: 'Minuten  Parameter',
  time_const_hours: 'Stunden Parameter',

  /* ---------- Date ---------- */
  date_title: 'Datum',
  date_description: 'Konstanten für die Datum Parameter',
  date_const_weekday: 'Wochentag Parameter',
  date_const_day: 'Tag Parameter',
  date_const_month: 'Monat Parameter',
  date_const_year: 'Jahr Parameter',

  /* ---------- Weather parameter ---------- */
  weather_param_title: 'Wetter',
  weather_param_description: 'Konstanten für die Wetter Parameter',
  weather_param_const_temp: 'Temperatur Parameter',
  weather_param_const_dew_point: 'Taupunkt Parameter',
  weather_param_const_humidity: 'Luftfeuchtigkeit Parameter',
  weather_param_const_wind: 'Wind Parameter',
  weather_param_const_precipitation: 'Niederschlag Parameter',

  /* ---------- Communication ---------- */
  communication_title: 'Kommunikation',
  communication_description:
    'Konstanten für die Art der Kommunikation.\nLOCAL: Direktverbindung von Karte zu Karte\nGLOBAL: Verbindung über Server',
  communication_const_local: 'Lokale Kommunikation Karte zu Karte',
  communication_const_global: 'Globale  Kommunikation über Server',

  /* ---------- Axis ---------- */
  axis_title: 'Achsen',
  axis_description: 'Konstanten für die Achsen des Beschleunigungssensors',
  axis_const_x: 'X Achse',
  axis_const_y: 'Y Achse',
  axis_const_z: 'Z Achse',

  /* ---------- Orientations ---------- */
  orientations_title: 'Orientierung',
  orientations_description: 'Konstanten für die Orientierung des Beschleunigungssensors',
  orientations_const_unknown: 'Unbekannte Orientierung',
  orientations_const_flat: 'Karte flach auf dem Tisch',
  orientations_const_flipped: 'Umgekehrt liegend auf dem Tisch',
  orientations_const_up: 'Karte stehend mit den Knöpfe unten',
  orientations_const_half_up: 'Halb stehend mit den Knöpfe unten',
  orientations_const_down: 'Karte stehend mit den Knöpfe oben',
  orientations_const_half_down: 'Halb stehend mit den Knöpfe oben',
  orientations_const_left: 'Karte stehend auf der linken Seite',
  orientations_const_half_left: 'Halb stehend auf der linken Seite',
  orientations_const_right: 'Karte stehend auf der rechten Seite',
  orientations_const_half_right: 'Halb stehend auf der rechten Seite',

  /* ---------- Data Rates ---------- */
  data_rate_title: 'Datenraten',
  data_rate_description: 'Konstanten für die verschiedenen Datenraten des Beschleunigungssensors',
  data_rate_const_data_rate_const_power_down: 'Power down Modus',
  data_rate_const_data_rate_const_1hz: 'Datenrate von 1 Hz',
  data_rate_const_data_rate_const_10hz: 'Datenrate von 10 Hz',
  data_rate_const_data_rate_const_25hz: 'Datenrate von 25 Hz',
  data_rate_const_data_rate_const_50hz: 'Datenrate von 50 Hz',
  data_rate_const_data_rate_const_100hz: 'Datenrate von 100 Hz',
  data_rate_const_data_rate_const_200hz: 'Datenrate von 200 Hz',
  data_rate_const_data_rate_const_400hz: 'Datenrate von 400 Hz',
  data_rate_const_data_rate_const_1334hz: 'Datenrate von 1334 Hz',

  /* ---------- Accelerometer ranges ---------- */
  accelerometer_range_title: 'Beschleunigungs Bereiche',
  accelerometer_range_description:
    'Konstanten für die verschiedenen Auflösungen des Beschleunigungssensors',
  accelerometer_range_const_2g: '+- 2G',
  accelerometer_range_const_4g: '+- 4G',
  accelerometer_range_const_8g: '+- 8G',
  accelerometer_range_const_16g: '+- 16G',

  /* ---------- Notes ---------- */
  notes_title: 'Noten',
  notes_description: 'Konstanten für die Noten',
  notes_const_note_rest: 'Pausen Ton',
  notes_const_note_c: 'Ton C',
  notes_const_note_cs: 'Ton CS',
  notes_const_note_d: 'Ton D',
  notes_const_note_ds: 'Ton DS',
  notes_const_note_e: 'Ton E',
  notes_const_note_f: 'Ton F',
  notes_const_note_fs: 'Ton FS',
  notes_const_note_g: 'Ton G',
  notes_const_note_gs: 'Ton GS',
  notes_const_note_a: 'Ton A',
  notes_const_note_as: 'Ton AS',
  notes_const_note_b: 'Ton B',

  /* ---------- Note durations ---------- */
  note_durations_title: 'Notenlänge',
  note_durations_description: 'Konstanten für die Notenlängen',
  note_durations_const_whole: '1/1 Takt',
  note_durations_const_half: '1/2 Takt',
  note_durations_const_quarter: '1/4 Takt',
  note_durations_const_eight: '1/8 Takt',
  note_durations_const_sixteenth: '1/16 Takt',
  note_durations_const_thirty_second: '1/32 Takt',

  /* ---------- Octaves ---------- */
  octaves_title: 'Oktaven',
  octaves_description: 'Konstanten für die Oktaven der Noten',
  octaves_const_c3: 'Oktave C3',
  octaves_const_c4: 'Oktave C4',
  octaves_const_c5: 'Oktave C5',
  octaves_const_c6: 'Oktave C6',
  octaves_const_c7: 'Oktave C7',
  octaves_const_c8: 'Oktave C8',

  /* ---------- Waveforms ---------- */
  waveforms_title: 'Wellenformen',
  waveforms_description: 'Konstanten für die Wellenform der Töne',
  waveforms_const_sine: 'Sinus Wellenform',
  waveforms_const_square: 'Rechteck Wellenform',
  waveforms_const_sawtooth: 'Sägezahn Wellenform',
  waveforms_const_triangle: 'Dreieck Wellenform',

  /* ---------- Sound effects ---------- */
  sound_effects_title: 'Sound Effects',
  sound_effects_description: 'Konstanten für die abspielbaren Sound Effekte',
  sound_effects_const_description: 'Spiele mich mit playSoundEffect()',

  /* ---------- Connection interfaces ---------- */
  connection_interface_title: 'Verbindungsschnittstellen',
  connection_interface_description:
    'Konstanten für die Verbindungsschnittstellen der Oxocard (Nur WIFI im Moment)',
  connection_interface_const_wifi: 'WIFI Schnittstelle',

  /* ---------- GPIO ---------- */
  gpio_title: 'GPIO',
  gpio_description: 'Konstanten für die GPIO Pins der Karte',
  gpio_const_io5: 'GPIO 5',
  gpio_const_io16: 'GPIO 16',
  gpio_const_io17: 'GPIO 17',
  gpio_const_io18: 'GPIO 18',
  gpio_const_io19: 'GPIO 19',
  gpio_const_io23: 'GPIO 23',

  /* ---------- GPIO modes ---------- */
  gpio_mode_title: 'GPIO Modi',
  gpio_mode_description: 'Konstanten für die GPIO Modi',
  gpio_mode_const_input: 'GPIO als Eingang',
  gpio_mode_const_input_pullup: 'GPIO als Eingang mit einem pull-up Widerstand',
  gpio_mode_const_input_pulldown: 'GPIO als Eingang mit einem pull-down Widerstand',
  gpio_mode_const_output: 'GPIO als Ausgang',

  /* ---------- GPIO states ---------- */
  gpio_state_title: 'GPIO Zustände',
  gpio_state_description: 'Konstanten für die GPIO Zustände',
  gpio_state_const_high: 'GPIO high - 3.3V',
  gpio_state_const_low: 'GPIO low - 0V',
};
