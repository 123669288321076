/* eslint-disable import/no-anonymous-default-export */
export default [
  'size()', // C_FN_SIZE
  'remove()', // C_FN_REMOVE
  'findValue()', // C_FN_FINDVALUE
  'findRange()', // C_FN_FINDRANGE
  'min()', // C_FN_MIN
  'max()', // C_FN_MAX
  'avg()', // C_FN_AVG
  'sinf()', // C_FN_SINF
  'cosf()', // C_FN_COSF
  'pow()', // C_FN_POW
  'map()', // C_FN_MAP
  'abs()', // C_FN_ABS
  'isButtonPressed()', // C_FN_IS_BUTTON_PRESSED
  'waitForButtonPressed()', // C_FN_WAIT_BUTTON_PRESSED
  'canConnect()', // C_FN_CAN_CONNECT
  'random()', // C_FN_RANDOM
  'hasPersistentVariable()', // C_FN_HAS_PERSISTENT_VAR
  'savePersistentVariable()', // C_FN_SAVE_PERSISTENT_VAR
  'loadPersistentVariable()', // C_FN_LOAD_PERSISTENT_VAR
  'deletePersistentVariable()', // C_FN_DELETE_PERSISTENT_VAR
  'reboot()', // C_FN_REBOOT
  'turnOff()', // C_FN_TURN_OFF
  'turnOffWakeUpButton()', // C_FN_TURN_OFF_WAKE_UP_BUTTON
  'configureAutoTurnOff()', // C_FN_CONF_AUTO_TURN_OFF
  'enableAutoTurnOff()', // C_FN_ENABLE_AUTO_TURN_OFF
  'disableAutoTurnOff()', // C_FN_DISABLE_AUTO_TURN_OFF
  'sleep()', // C_FN_SLEEP
  'getWakeUpCause()', // C_FN_GET_WAKE_UP_CAUSE
  'getBatteryVoltage()', // C_FN_GET_BATTERY_VOLTAGE
  'isFullyCharged()', // C_FN_IS_FULLY_CHARGED
  'isUsbPluggedIn()', // C_FN_IS_USB_PLUGGED_IN
  'getTemperature()', // C_FN_GET_TEMPERATURE
  'setColor()', // C_FN_SET_COLOR
  'setRandomColor()', // C_FN_SET_RANDOM_COLOR
  'setHueColor()', // C_FN_SET_HUE_COLOR
  'enableAutoUpdate()', // C_FN_ENABLE_AUTO_UPDATE
  'disableAutoUpdate()', // C_FN_DISABLE_AUTO_UPDATE
  'isAutoUpdate()', // C_FN_IS_AUTO_UPDATE
  'update()', // C_FN_UPDATE
  'fillScreen()', // C_FN_FILL_SCREEN
  'clearScreen()', // C_FN_CLEAR_SCREEN
  'setPixel()', // C_FN_SET_PIXEL
  'drawPixel()', // C_FN_DRAW_PIXEL
  'clearPixel()', // C_FN_CLEAR_PIXEL
  'readPixel()', // C_FN_READ_PIXEL
  'isPixelSet()', // C_FN_IS_PIXEL_SET
  'fill()', // C_FN_FILL
  'noFill()', // C_FN_NO_FILL
  'drawLine()', // C_FN_DRAW_LINE
  'drawRectangle()', // C_FN_DRAW_RECTANGLE
  'drawTriangle()', // C_FN_DRAW_TRIANGLE
  'drawCircle()', // C_FN_DRAW_CIRCLE
  'drawNumber()', // C_FN_DRAW_NUMBER
  'drawChar()', // C_FN_DRAW_CHAR
  'drawText()', // C_FN_DRAW_TEXT
  'drawImage()', // C_FN_DRAW_IMAGE
  'drawImage32()', // C_FN_DRAW_IMAGE_32
  'setActiveFrameBuffer()', // C_FN_SET_ACTIVE_FRAME_BUFFER
  'copyFrameBufferContent()', // C_FN_COPY_FRAME_BUFFER_CONTENT
  'delay()', // C_FN_DELAY
  'msSinceStart()', // C_FN_MS_SINCE_START
  'useTimeZone()', // C_FN_USE_TIME_ZONE
  'setTime()', // C_FN_SET_TIME
  'getTime()', // C_FN_GET_TIME
  'setDate()', // C_FN_SET_DATE
  'getDate()', // C_FN_GET_DATE
  'getWeather()', // C_FN_GET_WEATHER
  'readWeatherParam()', // C_FN_READ_WEATHER_PARAM
  'drawCityName()', // C_FN_DRAW_CITY_NAME
  'drawForecastIcon()', // C_FN_DRAW_FORECAST_ICON
  'getAcceleration()', // C_FN_GET_ACCELERATION
  'getNormalizedValue()', // C_FN_GET_NORMALIZED_ACCELERATION
  'setAccelerometerCursor()', // C_FN_SET_ACCELEROMETER_CURSOR
  'getAccelerometerCursor()', // C_FN_GET_ACCELEROMETER_CURSOR
  'getOrientation()', // C_FN_GET_ORIENTATION
  'isOrientation()', // C_FN_IS_ORIENTATION
  'setReferenceVector()', // C_FN_SET_REFERENCE_VECTOR
  'getAngle()', // C_FN_GET_ANGLE
  'configureAccelerometer()', // C_FN_CONF_ACCELEROMETER
  'playTone()', // C_FN_PLAY_TONE
  'playToneFor()', // C_FN_PLAY_TONE_FOR
  'noteToMs()', // C_FN_NOTE_TO_MS
  'playNote()', // C_FN_PLAY_NOTE
  'playNoteFor()', // C_FN_PLAY_NOTE_FOR
  'noTone()', // C_FN_NO_TONE
  'setWaveForm()', // C_FN_SET_WAVE_FORM
  'setOctave()', // C_FN_SET_OCTAVE
  'setBPM()', // C_FN_SET_BPM
  'setToneBlocking()', // C_FN_SET_TONE_BLOCKING
  'setVolume()', // C_FN_SET_VOLUME
  'getVolume()', // C_FN_GET_VOLUME
  'volumeUp()', // C_FN_VOLUME_UP
  'volumeDown()', // C_FN_VOLUME_DOWN
  'playSoundEffect()', // C_FN_PLAY_SOUND_EFFECT
  'record()', // C_FN_RECORD
  'playRecorded()', // C_FN_PLAY_RECORDED
  'waitForLoudNoise()', // C_FN_WAIT_FOR_LOUD_NOISE
  'startMicrophone()', // C_FN_MICROPHONE_START
  'readDecibel()', // C_FN_MICROPHONE_READ_NOISE
  'readFrequency()', // C_FN_MICROPHONE_READ_FREQUENCY
  'stopMicrophone()', // C_FN_MICROPHONE_STOP
  'connect()', // C_FN_CONNECT
  'disconnect()', // C_FN_DISCONNECT
  'startAccessPointMode()', // C_FN_START_AP_MODE
  'startPairingMode()', // C_FN_START_PAIRING_MODE
  'hasNewScript()', // C_FN_HAS_NEW_SCRIPT
  'downloadScript()', // C_FN_DOWNLOAD_SCRIPT
  'setPinMode()', // C_FN_SET_PIN_MODE
  'setPin()', // C_FN_SET_PIN
  'readPin()', // C_FN_READ_PIN
  'print()', // C_FN_PRINT
  'printNumber()', // C_FN_PRINT_NUMBER
  'print()', // C_FN_PRINT_CHAR
  'printArray()', // C_FN_PRINT_ARRAY
  'newline()', // C_FN_NEW_LINE
  'i2cWrite()', // C_FN_I2C_WRITE
  'i2cRead()', // C_FN_I2C_READ
  'pushValue()', // C_FN_PUSH_VALUE
  'pushValues()', // C_FN_PUSH_VALUES
  'getValues()', // C_FN_GET_VALUES
  'clearValues()', // C_FN_CLEAR_VALUES
  'removeValues()', // C_FN_REMOVE_VALUES
  'selectCommunicationType()', // C_FN_COMM_SELECT_COMM_TYPE
  'startCommunication()', // C_FN_COMM_START
  'stopCommunication()', // C_FN_COMM_STOP
  'setReceivers()', // C_FN_COMM_SET_RECEIVERS
  'setTextToSend()', // C_FN_COMM_SET_TEXT
  'setVariableToSend()', // C_FN_COMM_SET_VARIABLE
  'sendMessage()', // C_FN_COMM_SEND_MESSAGE
  'hasNewMessage()', // C_FN_COMM_HAS_NEW_MESSAGE
  'isSender()', // C_FN_COMM_IS_SENDER
  'getSender()', // C_FN_COMM_GET_SENDER
  'isReceivedText()', // C_FN_COMM_IS_TEXT
  'getReceivedText()', // C_FN_COMM_GET_TEXT
  'getReceivedVariable()', // C_FN_COMM_GET_VARIABLE
];
