/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  0: `After 'def' you have to define a function name.`,
  1: `There is a missing '('-symbol.`,
  2: `There is a missing ')'-symbol.`,
  3: `You cannot use a global variable name as a parameter name.`,
  4: `Variables need a data type. Choose between 'int', 'char', or 'byte'`,
  5: `There is a missing or unknown statement.`,
  6: `'=', '(' or '+=' expected.`,
  7: `'++' or '--' expected.`,
  8: `Unknown function name. Check the spelling of the name.`,
  9: `There is a missing '('-symbol.`,
  10: `There is a missing ')'-symbol.`,
  11: `Numeric value expected.`,
  12: `The function expects a list variable or a string, but you passed an int/char/byte variable`,
  13: `This function has no parameters, but you tried to pass some values.`,
  14: `You passed a wrong number of parameters.`,
  15: `']' expected.`,
  16: `Return is not value in event procedures. Use 'exit' instead.`,
  17: `There is a missing ')'-symbol.`,
  18: `The variable is not declared. Check the spelling.`,
  19: `] expected but not found.`,
  20: `You have to use a list type here.`,
  21: `There is a missing ')'-symbol.`,
  22: `There was an error in a expression.`,
  23: `There is a missing ')'-symbol.`,
  24: `There is a missing ')'-symbol.`,
  25: `Each statement must be terminated by a new line or a semicolon (';')`,
  26: `You cannot set a value here on global variables. Only allowed in functions.`,
  27: `Place a colon (':') here with indentation.`,
  28: `error in for-statement: for IDENT in list: statements`,
  29: `use a simple variable after 'for', list types are not allowed here.`,
  30: `'in' expected.`,
  31: `indexed access requires a known/declared variable.`,
  32: `After for .. in .. - a list, integer variable or a number is expected.`,
  33: `Array types are not allowed here.`,
  34: `'=' expected.`,
  35: `This variable is already in use.`,
  36: `Global numeric variables can only initialize to 0.`,
  37: `Global list variables can only initialize to [].`,
  38: `Def functions or global variable declarations expected.`,
  39: `Comma expected.`,
  40: `After a colon, the lines must be indented.`,
  41: `break is only usable within a loop.`,
  42: `continue is only usable within a loop.`,
  43: `Invalid number.`,
};
