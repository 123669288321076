import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ui } from 'OxocardCore';

/* Components */

/* Store */
import { pairWithCard, loadCardInfo, clearCard } from '../../store/ducks/card';
import { sendByteCode } from '../../store/ducks/editor';

/* Styles */
import './InfoBar.css';

class InfoBar extends React.Component {
  onLink = () => {
    /* pairHelp popup */
    const onProceed = async () => {
      /* onProceed: pair popup */
      const onProceed = async challenge => {
        this.props
          .pairWithCard(challenge)
          .then(() => {
            /* check if fwVersion is empty, old ("1.x.x") or contains garbage ("//") */
            if (
              this.props.cardFwVersion === '' ||
              this.props.cardFwVersion[0] === '1' ||
              this.props.cardFwVersion.indexOf('//') >= 0
            ) {
              this.props.clearCard();
              const config = {
                message: 'popupDefaultImageSuccessBody',
                mood: 'positive',
                buttons: [
                  {
                    class: 'positive',
                    callback() {},
                  },
                ],
                onProceed: () => {
                  const config = { onProceed: () => null };
                  const popup = new ui.components.FlashPopup(config);
                  popup.show();
                },
              };
              const popup = new ui.components.OldFwVersionPopup(config);
              popup.show();
            }
          })
          .catch(e => console.log(e));
      };
      const config = { onProceed, onCancel: () => null };
      const popup = new ui.components.PairPopup(config);
      popup.show();
    };
    const config = { onProceed, onCancel: () => null };
    const popup = new ui.components.PairHelpPopup(config);
    popup.show();
  };

  onFlashInfo = () => {
    if (this.props.showHelp) {
      const config = { onProceed: () => null };
      const popup = new ui.components.FlashPopup(config);
      popup.show();
    }
    this.props.sendByteCode('test.tc', this.props.byteCode);
  };

  render() {
    const { i18n, isPaired, hasErrors } = this.props;

    let errorLine = '';
    let errorCode = '';

    if (hasErrors) {
      const error = this.props.errorMessage || [];
      errorLine = error[0];
      errorCode = error[2];
    }

    return (
      <>
        {hasErrors && (
          <div className="info_bar error">
            <div className="info_message">{`${i18n.t('info_bar_error')} ${errorLine}: ${i18n.t(
              'errorCode:' + errorCode
            )}`}</div>
          </div>
        )}
        {isPaired && !hasErrors && (
          <div className="info_bar success">
            <div className="info_message">{i18n.t('info_bar_ready')}</div>
            <button
              aria-label="Upload script"
              type="button"
              className="upload_icon clickable"
              onClick={this.onFlashInfo}
            ></button>
          </div>
        )}
        {!isPaired && !hasErrors && (
          <div className="info_bar">
            <div className="info_message">{i18n.t('info_bar_connect')}</div>
            <button
              type="button"
              aria-label="Connect card"
              className="connect_icon clickable"
              onClick={this.onLink}
            ></button>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps({ card, settings, wasm }) {
  return {
    isPaired: card.isPaired,
    codeChanging: wasm.codeChanging,
    hasErrors: wasm.hasErrors,
    errorMessage: wasm.errorMessage,
    byteCode: wasm.byteCode,
    showHelp: settings.showHelp,
    cardFwVersion: card.fwVersion,
  };
}

const mapDispatchToProps = {
  pairWithCard,
  loadCardInfo,
  sendByteCode,
  clearCard,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(InfoBar));
