import React from 'react';

/* Components */

/* Styles */
import './SubCategory.css';

class SubCategory extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.theme === nextProps.theme &&
      this.props.name === nextProps.name &&
      this.props.latestValue === nextProps.latestValue &&
      this.props.labelValue === nextProps.labelValue &&
      this.props.isOpen === nextProps.isOpen &&
      JSON.stringify(this.props.children.props) === JSON.stringify(nextProps.children.props)
    ) {
      return false;
    }

    return true;
  }

  render() {
    const { children, theme, name, isOpen, onClick } = this.props;
    let { latestValue = undefined, labelValue = undefined } = this.props;
    if (labelValue === 0) labelValue = undefined;
    return (
      <div
        className={isOpen ? `sub_category_wrapper ${theme} open` : `sub_category_wrapper ${theme}`}
      >
        <div
          className={isOpen ? `sub_category_header ${theme} open` : `sub_category_header ${theme}`}
          onClick={onClick}
        >
          <div className="sub_category_header_icon"></div>
          <div className="sub_category_header_name">{name}</div>
          {latestValue !== undefined && (
            <div className="sub_category_header_latest">{latestValue}</div>
          )}
          {labelValue && (
            <div className="sub_category_header_label">{labelValue}</div>
          )}
        </div>
        <div className={`sub_category_body ${theme}`}>{children}</div>
      </div>
    );
  }
}

export default SubCategory;
