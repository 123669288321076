import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import functionList from './functionList';

/* PDFs */

/* Components */

/* Store */

/* Styles */
import './Simulator.css';

function Simulator() {
  const { t } = useTranslation();
  const byteCode = useSelector(state => state.wasm.byteCode);
  const [unknownFunctionId, setUnknownFunctionId] = useState(-1);

  const buttonPressed = useRef(() => {});
  const buttonReleased = useRef(() => {});

  useEffect(() => {
    const generateLed = (x, y, color) => {
      const element = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      const alpha = Math.max((color >> 16) % 256, (color >> 8) % 256, color % 256);
      const colorString = '#' + ('00000000' + color.toString(16) + alpha.toString(16)).substr(-8);

      element.setAttribute('x', x);
      element.setAttribute('y', y);
      element.setAttribute('width', '4.36');
      element.setAttribute('height', '4.36');
      element.setAttribute('fill', colorString);

      return element;
    };

    const updateLed = ledArray => {
      const svg = document.getElementById('led');

      Array.from(svg.querySelectorAll('rect')).forEach(part => {
        svg.removeChild(part);
      });

      ledArray.forEach((led, i) => {
        svg.appendChild(generateLed(28.78 + 5.23 * (i % 8), 26.45 + 5.23 * Math.floor(i / 8), led));
      });
    };

    const clearLed = () => {
      const svg = document.getElementById('led');

      Array.from(svg.querySelectorAll('rect')).forEach(part => {
        svg.removeChild(part);
      });
    };

    let myWorker = null;
    setTimeout(() => {
      myWorker = new Worker('./workers/interpreter_worker.js');

      setUnknownFunctionId(-1);

      myWorker.onmessage = function(e) {
        const message = e.data;
        switch (message.type) {
          case 'updateLeds':
            updateLed(message.data);
            break;
          case 'unknownFunction':
            myWorker.terminate();
            clearLed();
            setUnknownFunctionId(message.data);
            break;

          default:
            break;
        }
      };

      const getButtonId = button => {
        switch (button) {
          case 1:
            return 'l1';
          case 2:
            return 'l2';
          case 4:
            return 'l3';
          case 8:
            return 'r1';
          case 16:
            return 'r2';
          case 32:
            return 'r3';

          default:
            return '';
        }
      };

      const highlightButton = button => {
        const btn = document.getElementById(getButtonId(button));
        if (btn) {
          btn.style.fill = '#ae9d8b';
        }
      };

      const resetHighlightButton = button => {
        const btn = document.getElementById(getButtonId(button));
        if (btn) {
          btn.style.fill = '#0000';
        }
      };

      buttonPressed.current = button => {
        myWorker.postMessage({ type: 'buttonPressed', data: button });
        highlightButton(button);
      };

      buttonReleased.current = button => {
        myWorker.postMessage({ type: 'buttonReleased', data: button });
        resetHighlightButton(button);
      };

      myWorker.postMessage({ type: 'interpretCode', data: Uint8Array.from(byteCode) });
    }, 100);

    return () => {
      myWorker.terminate();
    };
  }, [byteCode]);

  return (
    <div className="simulator_wrapper">
      <div className="card">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <g id="oxocard">
            <g id="body">
              <rect fill="#ccb7a1" width="100" height="100" />
              <rect fill="#ddd" x="5.4" y="5.19" width="89.21" height="94.6" />
              <path
                fill="#ccb7a1"
                d="M38.49,88.68V76.27a.54.54,0,0,0-.51-.56H23.56V62.78a.53.53,0,0,0-.51-.56H11a.54.54,0,0,0-.54.54V88.68a.54.54,0,0,0,.54.52H38a.54.54,0,0,0,.54-.52Z"
              />
              <path
                fill="#ccb7a1"
                d="M61.51,88.68V76.27a.53.53,0,0,1,.51-.56H76.44V62.78a.53.53,0,0,1,.51-.56H89a.54.54,0,0,1,.54.54V88.68a.54.54,0,0,1-.54.52h-27a.54.54,0,0,1-.54-.52Z"
              />
            </g>
            <g id="buttons">
              <text x="14px" y="70.7px" fill="#ae9d8b" fontSize="5px" fontFamily="Roboto">
                L1
              </text>
              <text x="14px" y="84.5px" fill="#ae9d8b" fontSize="5px" fontFamily="Roboto">
                L2
              </text>
              <text x="30px" y="84.5px" fill="#ae9d8b" fontSize="5px" fontFamily="Roboto">
                L3
              </text>
              <text x="80.5px" y="70.7px" fill="#ae9d8b" fontSize="5px" fontFamily="Roboto">
                R1
              </text>
              <text x="80.5px" y="84.5px" fill="#ae9d8b" fontSize="5px" fontFamily="Roboto">
                R2
              </text>
              <text x="64.3px" y="84.5px" fill="#ae9d8b" fontSize="5px" fontFamily="Roboto">
                R3
              </text>
              <path
                id="l1"
                onMouseDown={() => buttonPressed.current(1)}
                onMouseUp={() => buttonReleased.current(1)}
                onMouseLeave={() => buttonReleased.current(1)}
                fill="#0000"
                stroke="#ae9d8b"
                strokeWidth="0.3813"
                d="M12.23,64H21a.72.72,0,0,1,.72.72v8.1a.71.71,0,0,1-.72.71H12.23"
              />
              <path
                id="l2"
                onMouseDown={() => buttonPressed.current(2)}
                onMouseUp={() => buttonReleased.current(2)}
                onMouseLeave={() => buttonReleased.current(2)}
                fill="#0000"
                stroke="#ae9d8b"
                strokeWidth="0.3813"
                d="M12.23,77.88H21a.71.71,0,0,1,.72.7V86.7a.71.71,0,0,1-.72.71H12.23"
              />
              <path
                id="l3"
                onMouseDown={() => buttonPressed.current(4)}
                onMouseUp={() => buttonReleased.current(4)}
                onMouseLeave={() => buttonReleased.current(4)}
                fill="#0000"
                stroke="#ae9d8b"
                strokeWidth="0.3813"
                d="M37.23,87.41H28.42a.71.71,0,0,1-.72-.71V78.58a.71.71,0,0,1,.72-.7h8.81"
              />
              <path
                id="r1"
                onMouseDown={() => buttonPressed.current(8)}
                onMouseUp={() => buttonReleased.current(8)}
                onMouseLeave={() => buttonReleased.current(8)}
                fill="#0000"
                stroke="#ae9d8b"
                strokeWidth="0.3813"
                d="M87.77,64H79a.72.72,0,0,0-.72.72v8.1a.71.71,0,0,0,.72.71h8.81"
              />
              <path
                id="r2"
                onMouseDown={() => buttonPressed.current(16)}
                onMouseUp={() => buttonReleased.current(16)}
                onMouseLeave={() => buttonReleased.current(16)}
                fill="#0000"
                stroke="#ae9d8b"
                strokeWidth="0.3813"
                d="M87.77,77.88H79a.71.71,0,0,0-.72.7V86.7a.71.71,0,0,0,.72.71h8.81"
              />
              <path
                id="r3"
                onMouseDown={() => buttonPressed.current(32)}
                onMouseUp={() => buttonReleased.current(32)}
                onMouseLeave={() => buttonReleased.current(32)}
                fill="#0000"
                stroke="#ae9d8b"
                strokeWidth="0.3813"
                d="M62.77,87.41h8.81a.73.73,0,0,0,.72-.71V78.58a.72.72,0,0,0-.72-.7H62.77"
              />
            </g>
            <g id="led"></g>
          </g>
        </svg>
        {unknownFunctionId >= 0 && (
          <div className="unknown_function">
            <p>{functionList[unknownFunctionId] + ' ' + t('menu_simulator_unknown_functions')}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Simulator;
