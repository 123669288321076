/* Actions */
const SET_SETTINGS = 'SET_SETTINGS';

/* Reducer */
const initialState = {
  lang: 'de',
  lastVersion: '',
  showReleaseSteps: 'new', // always | new | never
  editorTheme: 'light', // dark | light
  editorFontSize: 16,
  showHelp: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SETTINGS:
      const { payload } = action;
      Object.keys(payload).forEach(key => {
        if (!initialState.hasOwnProperty(key) || payload[key] === undefined) {
          delete payload[key];
        }
      });
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

/* Action Creators */
export function setSettings(payload) {
  return { type: SET_SETTINGS, payload };
}

export function saveSettings(settings) {
  return (dispatch, getState) => {
    Object.keys(settings).forEach(key => {
      if (!initialState.hasOwnProperty(key) || settings[key] === undefined) {
        delete settings[key];
      }
    });
    save(getState, settings);
    dispatch(setSettings(settings));
  };
}

export function load() {
  return dispatch => {
    let settings = localStorage.getItem('settings');
    if (!settings) {
      return;
    }

    try {
      settings = JSON.parse(settings);
      dispatch(setSettings(settings));
    } catch (e) {
      console.warn('could not parse settings', e);
    }
  };
}

function save(getState, newSettings) {
  const state = getState();
  const settings = { ...state.settings, ...newSettings };
  localStorage.setItem('settings', JSON.stringify(settings));
}
