import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Components */
import SubCategory from '../SubCategory';
import Functions from './Functions';
import Constants from './Constants';
import Syntax from './Syntax';

/* Store */

/* Styles */
import SearchBar from './SearchBar';
import './Docs.css';

class Docs extends React.PureComponent {
  state = {
    activeCategory: -1,
    searchString: '',
  };

  onSearch = value => {
    this.setState({
      searchString: value,
    });
  };

  render() {
    const { activeCategory, searchString } = this.state;
    const { i18n } = this.props;
    return (
      <div className="docs_wrapper">
        <SearchBar onChange={this.onSearch} />
        {/*----------------Syntax--------------------*/}
        <SubCategory
          theme={this.props.theme}
          // name={i18n.t('menu_documentation_constants')}
          name="Syntax"
          isOpen={activeCategory === 1 || searchString}
          onClick={() => {
            this.setState({
              activeCategory: 1 === activeCategory ? -1 : 1,
            });
          }}
        >
          <Syntax isOpen={activeCategory === 1 || searchString} searchString={searchString} />
        </SubCategory>
        {/*---------------------------------------------*/}

        {/*----------------Functions--------------------*/}
        <SubCategory
          theme={this.props.theme}
          name={i18n.t('menu_documentation_functions')}
          isOpen={activeCategory === 2 || searchString}
          onClick={() => {
            this.setState({
              activeCategory: 2 === activeCategory ? -1 : 2,
            });
          }}
        >
          <Functions isOpen={activeCategory === 2 || searchString} searchString={searchString} />
        </SubCategory>
        {/*---------------------------------------------*/}

        {/*----------------Constants--------------------*/}
        <SubCategory
          theme={this.props.theme}
          name={i18n.t('menu_documentation_constants')}
          isOpen={activeCategory === 3 || searchString}
          onClick={() => {
            this.setState({
              activeCategory: 3 === activeCategory ? -1 : 3,
            });
          }}
        >
          <Constants isOpen={activeCategory === 3 || searchString} searchString={searchString} />
        </SubCategory>
        {/*---------------------------------------------*/}
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return { theme: settings.editorTheme };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Docs));
