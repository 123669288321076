import React from 'react';
import { connect } from 'react-redux';

import './Editor.css';
import { saveContent, setContent } from '../../store/ducks/editor';
import { compileScript, wasmCodeChanged } from '../../store/ducks/wasm';
import MonacoEditor from './MonacoEditor';

class EditorContainer extends React.Component {
  editor = null;

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showTerminal !== this.props.showTerminal) {
      this.layoutEditor();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => this.layoutEditor();

  editorDidMount = editor => {
    this.editor = editor;
    this.layoutEditor();
  };

  layoutEditor = () => {
    if (this.editor) {
      this.editor.layout();
    }
  };

  onChange = code => {
    this.props.wasmCodeChanged();
    this.props.setContent(code, false);
  };

  render() {
    return (
      <div className="editor-wrapper">
        <MonacoEditor
          onEditorDidMount={this.editorDidMount}
          value={this.props.content}
          fontSize={this.props.fontSize}
          onChange={this.onChange}
          onChangeDebounced={this.props.saveContent}
          onCompileScript={this.props.compileScript}
          theme={this.props.theme}
          errorMessage={this.props.errorMessage}
        />
      </div>
    );
  }
}

function mapStateToProps({ editor, settings, layout, wasm }) {
  return {
    content: editor.content,
    theme: settings.editorTheme,
    showTerminal: layout.showTerminal,
    errorMessage: wasm.errorMessage,
    fontSize: settings.editorFontSize,
  };
}

const mapDispatchToProps = {
  compileScript,
  wasmCodeChanged,
  saveContent,
  setContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorContainer);
