import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Components */
import SubSubCategory from '../../SubSubCategory';
import Function from './Function';

/* Store */

/* Styles */
import './FunctionList.css';

class FunctionList extends React.PureComponent {
  state = {
    activeFunction: -1,
  };

  componentDidUpdate() {
    if (this.props.isOpen === false) {
      this.setState({ activeFunction: -1 });
    }
  }

  render() {
    const { activeFunction } = this.state;
    const { i18n } = this.props;
    return (
      <div className="function_list_wrapper">
        {this.props.functions.map((func, i) => (
          <SubSubCategory
            theme={this.props.theme}
            name={
              func.parentCategory
                ? i18n.t('function:' + func.parentCategory) + ' - ' + func.name
                : func.name
            }
            key={i}
            isOpen={activeFunction === i}
            searchString={this.props.filterString}
            onClick={() => {
              this.setState({
                activeFunction: i === activeFunction ? -1 : i,
              });
            }}
          >
            <Function functionData={func} key={i} />
          </SubSubCategory>
        ))}
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return { theme: settings.editorTheme };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FunctionList));
