import { batch } from 'react-redux';
import { pair, transferPairing, unpair, getCardInfo, resetCard } from '../../api';

import { resetTerminal } from './terminal';
import { resetVariables } from './variables';
// import { sendByteCode } from './editor';

/* Actions */
const CARD_PAIR = 'card_pair';
const CARD_PAIR_SUCCESS = 'card_pair_success';
const CARD_PAIR_FAIL = 'card_pair_fail';
const CARD_SET_INFO = 'card_set_info';
const CARD_UNPAIR = 'card_unpair';

const defaultCard = {
  name: '',
  hwVersion: '',
  fwVersion: '',
  cardState: '',
  totalSpiffsSize: 0,
  usedSpiffsSize: 0,
};
const initialState = {
  isPaired: false,
  isPairing: false,
  ...defaultCard,
};

/* Reducer */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CARD_PAIR:
      return { ...state, isPairing: true, isPaired: false };

    case CARD_PAIR_SUCCESS:
      return { ...state, isPairing: false, isPaired: true };

    case CARD_PAIR_FAIL:
      return { ...initialState };

    case CARD_SET_INFO: {
      const { payload } = action;
      return { ...state, isPairing: false, isPaired: true, ...payload };
    }

    case CARD_UNPAIR:
      return { ...initialState };

    default:
      return state;
  }
}

/* Action Creators */
export function pairCard() {
  return { type: CARD_PAIR };
}

export function pairCardSuccess() {
  return { type: CARD_PAIR_SUCCESS };
}

export function pairCardFail() {
  return { type: CARD_PAIR_FAIL };
}

export function setCardInfo(card) {
  return { type: CARD_SET_INFO, payload: card };
}

export function unPair() {
  return { type: CARD_UNPAIR };
}

export function pairWithCard(challenge) {
  return async (dispatch, getState) => {
    dispatch(pairCard());
    try {
      const res = await pair(challenge);
      const { name, hwVersion, fwVersion, totalSpiffsSize, usedSpiffsSize, state } = res;
      const card = {
        name,
        hwVersion,
        fwVersion,
        cardState: state,
        totalSpiffsSize,
        usedSpiffsSize,
      };
      batch(() => {
        dispatch(setCardInfo(card));
        dispatch(pairCardSuccess());
      });
      // const wasm = getState().wasm;
      // if (!wasm.hasErrors && wasm.compiledSuccessful) {
      //   setTimeout(() => dispatch(sendByteCode('test.tc', wasm.byteCode)), 500);
      // }
    } catch (e) {
      dispatch(pairCardFail());
      throw new Error(e);
    }
  };
}

export function pairFromBlockly(hmacIdClient) {
  return async (dispatch, getState) => {
    dispatch(pairCard());
    try {
      const res = await transferPairing(hmacIdClient);
      const { name, hwVersion, fwVersion, totalSpiffsSize, usedSpiffsSize, state } = res;
      const card = {
        name,
        hwVersion,
        fwVersion,
        cardState: state,
        totalSpiffsSize,
        usedSpiffsSize,
      };
      batch(() => {
        dispatch(setCardInfo(card));
        dispatch(pairCardSuccess());
      });
      // const wasm = getState().wasm;
      // if (!wasm.hasErrors && wasm.compiledSuccessful) {
      //   setTimeout(() => dispatch(sendByteCode('test.tc', wasm.byteCode)), 500);
      // }
    } catch (e) {
      dispatch(pairCardFail());
      throw new Error(e);
    }
  };
}

export function loadCardInfo() {
  return async dispatch => {
    try {
      const res = await getCardInfo();
      const { name, hwVersion, fwVersion, totalSpiffsSize, usedSpiffsSize } = res;
      const card = {
        name,
        hwVersion,
        fwVersion,
        cardState: res.state,
        totalSpiffsSize,
        usedSpiffsSize,
      };
      dispatch(setCardInfo(card));
    } catch (e) {
      dispatch(unPair());
      throw new Error(e);
    }
  };
}

export function unPairCard() {
  return async dispatch => {
    try {
      await unpair();
      batch(() => {
        dispatch(unPair());
        dispatch(resetTerminal());
        dispatch(resetVariables());
      });
    } catch (e) {
      throw new Error(e);
    }
  };
}

export function clearCard() {
  return dispatch => {
    batch(() => {
      dispatch(unPair());
      dispatch(resetTerminal());
      dispatch(resetVariables());
    });
  };
}

export function resetToInitial() {
  return async dispatch => {
    try {
      await resetCard();
      batch(() => {
        dispatch(unPair());
        dispatch(resetTerminal());
        dispatch(resetVariables());
      });
    } catch (e) {
      throw new Error(e);
    }
  };
}
