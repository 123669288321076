/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* ---------- Boolean ---------- */
  boolean_title: 'Boolean',
  boolean_description: 'Booleans are used to set something to either true or false',
  boolean_const_true: 'Used when something is true',
  boolean_const_false: 'Used when something is false',

  /* ---------- Buttons ---------- */
  button_title: 'Buttons',
  button_description: 'Constants for all the buttons of the Oxocard',
  button_const_btn_l1: 'Button L1 (left side top)',
  button_const_btn_l2: 'Button L2 (left side bottom left)',
  button_const_btn_l3: 'Button L3 (left side bottom right)',
  button_const_btn_r1: 'Button R1 (right side top)',
  button_const_btn_r2: 'Button R2 (right side bottom left)',
  button_const_btn_r3: 'Button R3 (right side bottom right)',
  button_const_btn_any: 'Any button',

  /* ---------- Buttons ---------- */
  wake_up_reasons_title: 'Wake up reasons',
  wake_up_reasons_description: 'Constants for the wake up reason of the Oxocard.',
  wake_up_reasons_const_reset: 'When a reset woke up the card',
  wake_up_reasons_const_timer: 'The card woke uf from sleeping',

  /* ---------- Color ---------- */
  color_title: 'Color',
  color_description: 'Constants for the color channels of the pixels of the matrix',
  color_const_red: 'Red channel',
  color_const_green: 'Green channel',
  color_const_blue: 'Blue channel',

  /* ---------- Time ---------- */
  time_title: 'Time',
  time_description: 'Constants for the time parameter',
  time_const_seconds: 'Seconds parameter',
  time_const_minutes: 'Minutes  parameter',
  time_const_hours: 'Hours parameter',

  /* ---------- Date ---------- */
  date_title: 'Date',
  date_description: 'Constants for the date parameter',
  date_const_weekday: 'Weekday parameter',
  date_const_day: 'Day parameter',
  date_const_month: 'Month parameter',
  date_const_year: 'Year parameter',

  /* ---------- Weather parameter ---------- */
  weather_param_title: 'Weather',
  weather_param_description: 'Constants for the weather parameter',
  weather_param_const_temp: 'Temperature parameter',
  weather_param_const_dew_point: 'Dew point  parameter',
  weather_param_const_humidity: 'Humidity parameter',
  weather_param_const_wind: 'Wind parameter',
  weather_param_const_precipitation: 'Precipitation parameter',

  /* ---------- Communication ---------- */
  communication_title: 'Communication',
  communication_description:
    'Constants for the type of the Communication.\nLOCAL: Direct connection from card to card\nGLOBAL: Connection via the server',
  communication_const_local: 'Local Communication card to card',
  communication_const_global: 'Global  Communication via server',

  /* ---------- Axis ---------- */
  axis_title: 'Axis',
  axis_description: 'Constants for the axis of the accelerometer',
  axis_const_x: 'X axis',
  axis_const_y: 'Y axis',
  axis_const_z: 'Z axis',

  /* ---------- Orientations ---------- */
  orientations_title: 'Orientations',
  orientations_description: 'Constants for orientation of the accelerometer',
  orientations_const_unknown: 'Unknown orientation',
  orientations_const_flat: 'Card flat on table',
  orientations_const_flipped: 'Face down on table',
  orientations_const_up: 'Card standing with buttons down',
  orientations_const_half_up: 'Half way standing with buttons down',
  orientations_const_down: 'Card standing with buttons up',
  orientations_const_half_down: 'Half way standing with buttons up',
  orientations_const_left: 'Card standing on left side',
  orientations_const_half_left: 'Half way standing on left side',
  orientations_const_right: 'Card standing on right side',
  orientations_const_half_right: 'Half way standing on right side',

  /* ---------- Data Rates ---------- */
  data_rate_title: 'Data rates',
  data_rate_description: 'Constants for the different data rates of the accelerometer',
  data_rate_const_data_rate_const_power_down: 'Power down mode',
  data_rate_const_data_rate_const_1hz: 'Data rate of 1 Hz',
  data_rate_const_data_rate_const_10hz: 'Data rate of 10 Hz',
  data_rate_const_data_rate_const_25hz: 'Data rate of 25 Hz',
  data_rate_const_data_rate_const_50hz: 'Data rate of 50 Hz',
  data_rate_const_data_rate_const_100hz: 'Data rate of 100 Hz',
  data_rate_const_data_rate_const_200hz: 'Data rate of 200 Hz',
  data_rate_const_data_rate_const_400hz: 'Data rate of 400 Hz',
  data_rate_const_data_rate_const_1334hz: 'Data rate of 1334 Hz',

  /* ---------- Accelerometer ranges ---------- */
  accelerometer_range_title: 'Acceleration sensitivities ',
  accelerometer_range_description:
    'Constants for the different sensitivity ranges of the accelerometer',
  accelerometer_range_const_2g: '+- 2G',
  accelerometer_range_const_4g: '+- 4G',
  accelerometer_range_const_8g: '+- 8G',
  accelerometer_range_const_16g: '+- 16G',

  /* ---------- Notes ---------- */
  notes_title: 'Notes',
  notes_description: 'Constants for the notes',
  notes_const_note_rest: 'Rest tone (pause)',
  notes_const_note_c: 'Tone C',
  notes_const_note_cs: 'Tone CS',
  notes_const_note_d: 'Tone D',
  notes_const_note_ds: 'Tone DS',
  notes_const_note_e: 'Tone E',
  notes_const_note_f: 'Tone F',
  notes_const_note_fs: 'Tone FS',
  notes_const_note_g: 'Tone G',
  notes_const_note_gs: 'Tone GS',
  notes_const_note_a: 'Tone A',
  notes_const_note_as: 'Tone AS',
  notes_const_note_b: 'Tone B',

  /* ---------- Note durations ---------- */
  note_durations_title: 'Note durations',
  note_durations_description: 'Constants for the note durations',
  note_durations_const_whole: '1/1 beat',
  note_durations_const_half: '1/2 beat',
  note_durations_const_quarter: '1/4 beat',
  note_durations_const_eight: '1/8 beat',
  note_durations_const_sixteenth: '1/16 beat',
  note_durations_const_thirty_second: '1/32 beat',

  /* ---------- Octaves ---------- */
  octaves_title: 'Octaves',
  octaves_description: 'Constants for the octave of the note',
  octaves_const_c3: 'Octave C3',
  octaves_const_c4: 'Octave C4',
  octaves_const_c5: 'Octave C5',
  octaves_const_c6: 'Octave C6',
  octaves_const_c7: 'Octave C7',
  octaves_const_c8: 'Octave C8',

  /* ---------- Waveforms ---------- */
  waveforms_title: 'Waveforms',
  waveforms_description: 'Constants for the octave of the note',
  waveforms_const_sine: 'Sine waveform',
  waveforms_const_square: 'Square waveform',
  waveforms_const_sawtooth: 'Sawtooth waveform',
  waveforms_const_triangle: 'Triangle waveform',

  /* ---------- Sound effects ---------- */
  sound_effects_title: 'Sound effects',
  sound_effects_description: 'Constants for the available sound effects to play',
  sound_effects_const_description: 'Play me with playSoundEffect()',

  /* ---------- Connection interfaces ---------- */
  connection_interface_title: 'Connection interfaces',
  connection_interface_description:
    'Constants for the connection interface types (only WIFI at the moment)',
  connection_interface_const_wifi: 'WIFI interface',

  /* ---------- GPIO ---------- */
  gpio_title: 'GPIO',
  gpio_description: 'Constants for the GPIO Pins on the card',
  gpio_const_io5: 'GPIO 5',
  gpio_const_io16: 'GPIO 16',
  gpio_const_io17: 'GPIO 17',
  gpio_const_io18: 'GPIO 18',
  gpio_const_io19: 'GPIO 19',
  gpio_const_io23: 'GPIO 23',

  /* ---------- GPIO modes ---------- */
  gpio_mode_title: 'GPIO modes',
  gpio_mode_description: 'Constants for the GPIO modes',
  gpio_mode_const_input: 'GPIO as input',
  gpio_mode_const_input_pullup: 'GPIO as input with a pull up resistor',
  gpio_mode_const_input_pulldown: 'GPIO as input with a pull down resistor',
  gpio_mode_const_output: 'GPIO as output',

  /* ---------- GPIO states ---------- */
  gpio_state_title: 'GPIO states',
  gpio_state_description: 'Constants for the GPIO states',
  gpio_state_const_high: 'GPIO high - 3.3V',
  gpio_state_const_low: 'GPIO low - 0V',
};
