import { createStore, applyMiddleware, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
// import logger from 'redux-logger';

import card from './ducks/card';
import client from './ducks/client';
import editor from './ducks/editor';
import layout from './ducks/layout';
import settings from './ducks/settings';
import terminal from './ducks/terminal';
import variables from './ducks/variables';
import wasm from './ducks/wasm';

const reducer = combineReducers({
  card,
  client,
  editor,
  layout,
  settings,
  terminal,
  variables,
  wasm,
});

const configureStore = initialState => {
  const middleware = [ReduxThunk];

  const store = createStore(reducer, initialState, applyMiddleware(...middleware));

  return store;
};

export default configureStore;
